export function capitaliseFirstLetterOfWords(
  wordsString: string | undefined,
  separator = " "
): string {
  if (!wordsString) return "";
  let result = "";
  wordsString.split(separator).forEach((val: string) => {
    result += val.charAt(0).toUpperCase() + val.slice(1).toLowerCase() + " ";
  });
  return result.trim();
}
