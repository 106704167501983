import { lazily } from 'react-lazily'

import { wrapSuspense } from '../../atoms/placeholders/Suspense'

const { InfiniteScrollTable: RawInfinitScrollTable } = lazily(() => import('./InfiniteScrollTable'))
const InfiniteScrollTable = wrapSuspense<typeof RawInfinitScrollTable>(RawInfinitScrollTable)
export { InfiniteScrollTable }

export * from './InfiniteScrollTable.styles'
export * from './InfiniteScrollTable.types'
