import { ProfileAttributesProps } from '@app/components/Form/ProfileAttributes/ProfileAttributes.types'
import React, { PropsWithChildren } from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { v4 as uuid } from 'uuid'

const Presentation: React.FC<ProfileAttributesProps> = (props: PropsWithChildren<ProfileAttributesProps>) => {
  const { fieldArrayName } = props
  return (
    <FieldArray name={fieldArrayName}>
      {({ fields }) => {
        const keys = Object.keys(fields.value)
        if (keys.length < 1)
          return <span className="profile__message">(No {fieldArrayName.replace(/_/g, ' ')} available)</span>

        return keys.map((key: string) => {
          const label = key
          const fieldId = label
          return (
            <Field name={fieldId} key={fieldId}>
              {({ input, meta }) => {
                const value = input.value.value
                const inputId = uuid()
                return (
                  <div className="profile-attributes">
                    <div className={'input text profile-inputs' + (meta.error ? ' input-error' : '')}>
                      <label htmlFor={inputId}>{label}</label>

                      <input
                        type="text"
                        value={value}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                          input.onChange({
                            key,
                            value: ev.target.value,
                          })
                        }}
                      />

                      <span className="error-message">{meta.error}</span>
                    </div>
                  </div>
                )
              }}
            </Field>
          )
        })
      }}
    </FieldArray>
  )
}

const ProfileAttributes = Presentation

export default ProfileAttributes
