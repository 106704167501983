import React from 'react'

import { IconProps } from '../types'

const AlertSuccessIcon = (props: IconProps) => (
  <span className="alert-success-icon" {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3514 9.84855L10.7999 14.4L9.24843 12.8486M11.9999 2.40002C6.69797 2.40002 2.3999 6.69809 2.3999 12C2.3999 17.302 6.69797 21.6 11.9999 21.6C17.3018 21.6 21.5999 17.302 21.5999 12C21.5999 6.69809 17.3018 2.40002 11.9999 2.40002Z"
        stroke="#039855"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
)

export default AlertSuccessIcon
