import React from 'react'
import styled from 'styled-components'

import i18n from '../../i18n'
import { FilterModel } from './useFilter'

type Props = {
  field: string
  filterModel: FilterModel
}

const generateId = (props: Props, index?: number) =>
  index !== undefined ? `filter-${props.field}-${index}` : `filter-${props.field}-all`

const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  animation: fadeIn 0.2s linear;
  padding: 16px;
  border-radius: 3px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
  z-index: 1;
  min-width: 100%;

  .list-filter-checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .list-filter-checkbox-container input {
    margin-right: 16px;
    cursor: pointer;
  }

  .list-filter-checkbox-container label {
    margin: 0;
  }

  .select-all {
    margin-bottom: 24px;
  }
`

const swallowClick = (event: any) => {
  event.stopPropagation()
}

const Presentation: React.FC<Props> = (props) => {
  return (
    <Container className="list-filter" onClick={swallowClick}>
      <div className="checkbox list-filter-checkbox-container select-all">
        <input
          id={generateId(props)}
          type="checkbox"
          checked={props.filterModel.allSelected}
          onChange={() => {
            if (props.filterModel.allSelected) {
              props.filterModel.unselectAll()
            } else {
              props.filterModel.selectAll()
            }
          }}
        />
        <label htmlFor={generateId(props)}>{i18n.t('Select All')}</label>
      </div>

      {props.filterModel.values.map((value: string, i: number) => {
        const filterChecked = props.filterModel.filters[value]

        return (
          <div key={i}>
            <div className="checkbox list-filter-checkbox-container">
              <input
                id={generateId(props, i)}
                onChange={() => {
                  props.filterModel.setFilter(value, !filterChecked)
                }}
                type="checkbox"
                checked={filterChecked}
              />
              <label htmlFor={generateId(props, i)}>{props.filterModel.labels[i]}</label>
            </div>
          </div>
        )
      })}
    </Container>
  )
}

const ListFilter = Presentation

export default ListFilter
