import isEmpty from 'lodash/isEmpty'

const buildParams = (params: { [k: string]: string }, filters: { [k: string]: string[] }) => {
  let builtParams = Object.entries(params)
    .map(([key, value], index) => {
      const pValue = value?.trim()
      // Always url encode
      return `${key}=${encodeURIComponent(pValue)}`
    })
    .join('&')

  Object.keys(filters).forEach((filterKey, index) => {
    const multiPick = filters[filterKey]
    if (!multiPick.length) return

    if (!filters[filterKey].length) return

    const filterValue = multiPick.map((pick) => pick?.trim()).join(' OR ')
    if (!filterValue) return

    if (builtParams) {
      builtParams = builtParams + '&'
    }
    builtParams += `filters=${filterValue}`
  })

  return builtParams
}

export class FilterQuery {
  private params: { [key: string]: string }
  private filters: { [key: string]: string[] } = { OR: [] }

  constructor(initialParams?: { [key: string]: string }) {
    this.params = initialParams || {}
  }

  public put(key: string, value: string | string[], type: 'OR' | 'AND' = 'AND') {
    this.addValue(key, value)
  }

  /**
   * @param apiKeys api key for the filter. e.g. asset.display_name
   * @param value value to be compared with the apir key. e.g "hovercraft mk1" in asset.display_name="hovercrafet mk1"
   *            in case to be an array it will be added in a multipick filter retrieving
   *            all the instance that matches any of the provided values
   * @param relation ['OR' | 'AND'] this is the reltion that this filter will have with other api keys when in
   *            case to be a multipick. If it is not a multipcik then this will be always AND. If it is, then
   *            setting it to OR will return any value that matches this condition OR other conditions
   *            provided in other apiKeys. Id it is AND then wil be any matched provided for the current
   *            condition that also matches whatever is been contrained by other apiKeys
   */
  private addValue(apiKeys: string, value: string | string[], type: 'OR' | 'AND' = 'AND') {
    if (Array.isArray(value)) {
      if (value.length > 1) {
        value.forEach((value) => {
          let conditionKey = apiKeys
          if (type === 'OR') {
            conditionKey = 'OR'
          }
          this.filters[conditionKey] = this.filters[conditionKey] || []
          const escapedValue = encodeURIComponent((value || '').replace(new RegExp("'", 'g'), "\\'"))
          this.filters[conditionKey].push(`${apiKeys}='${escapedValue}'`)
        })
      } else {
        this.params[`${apiKeys}`] = value[0]
      }
    } else {
      this.params[`${apiKeys}`] = value
    }
  }

  public putAttribute(key: string, value: string | string[], type: 'OR' | 'AND' = 'AND') {
    this.addValue(`attributes.${key}`, value)
  }

  public putEventAttribute(key: string, value: string | string[], type: 'OR' | 'AND' = 'AND') {
    this.addValue(`event_attributes.${key}`, value)
  }

  public putEventAssetAttribute(key: string, value: string | string[], type: 'OR' | 'AND' = 'AND') {
    this.addValue(`asset_attributes.${key}`, value)
  }

  public putAssetAttribute(key: string, value: string | string[], type: 'OR' | 'AND' = 'AND') {
    this.addValue(`asset.attributes.${key}`, value)
  }

  public build() {
    // if no params, there is no query string
    if (isEmpty(this.params)) {
      return ''
    }
    const rawParams = { ...this.params }
    const filters = { ...this.filters }
    if (rawParams['filters']) {
      filters['OR'] = filters['OR'] || []
      filters['OR'].concat(rawParams['filters'])
    }

    if (filters['OR'].length) {
      rawParams['filters'] = filters['OR'].join(' OR ')
    }

    const params = buildParams(rawParams, filters)
    return '?' + params
  }
}
