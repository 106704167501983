import { Reducer } from 'redux'

import { apiAction } from '../actionCreators'
import initialState, { ReduxState } from '../initialState'

const reducer: Reducer<ReduxState['identityProviders']> = (state = initialState().identityProviders, action: any) => {
  switch (action.type) {
    case apiAction.fulfilled('api/identityproviders/get'): {
      return {
        ...state,
        list: action.payload.identity_providers,
      }
    }
  }
  return state
}

export default reducer
