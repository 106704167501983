import React from 'react'
import { FieldRenderProps } from 'react-final-form'

import showError from './showError'

type Props = {
  id: string
  label?: string
  helpText?: string
  placeholder?: string
  disabled?: boolean
  className?: string
} & FieldRenderProps<string, HTMLTextAreaElement>

const Presentation: React.FC<Props> = (props) => (
  <div className={`input text` + (props.meta.error ? ' input-error' : '')}>
    {props.label && <label htmlFor={props.id}>{props.label}</label>}
    {props.helpText ? <span className="helpText">{props.helpText}</span> : null}
    <textarea
      id={props.id}
      data-test={`${props.id}`}
      disabled={props.disabled}
      placeholder={props.placeholder}
      className={props.className}
      {...props.input}
    />
    {showError(props.meta) && <span className="error-message">{props.meta.error}</span>}
  </div>
)

const InputText = Presentation

export default InputText
