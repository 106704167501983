import { RkvstResourceCaps } from '@ui/types'

import { RkvstLocation, RkvstLocationPermissions } from '../../state/initialState'
import api from '../core'
import { FilterQuery } from '../FilterQuery'

const group = null
const resource = 'locations'
const version = '2'
const capsUrl = api.parseCapsUrl(resource)
const resourceUrl = api.parseResourceUrl(group, resource, version)

export default {
  getList(params?: any) {
    return api.get(resourceUrl, params)
  },

  getListFiltered(nextPageToken?: string) {
    const filterQuery = new FilterQuery({
      page_size: '50',
      order_by: 'DEFAULT',
    })

    if (nextPageToken) {
      filterQuery.put('page_token', nextPageToken)
    }

    const query = filterQuery.build()
    return api.get(resourceUrl + query)
  },

  getPermissions(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)
    return api.get(`${resourceUrl}/${strippedId}/permissions`)
  },

  getOne(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)
    return api.get<RkvstLocation>(`${resourceUrl}/${strippedId}`)
  },

  create(location: RkvstLocation) {
    return api.post(resourceUrl, location)
  },

  delete(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)
    return api.delete(`${resourceUrl}/${strippedId}`)
  },

  update(id: string, location: RkvstLocation) {
    const strippedId = api.stripResourceFromId(resource, id)
    return api.patch(`${resourceUrl}/${strippedId}`, location)
  },

  updatePermissions(id: string, permissions: RkvstLocationPermissions) {
    const strippedId = api.stripResourceFromId(resource, id)
    const permissionsPayload = JSON.stringify({
      subject_identities: permissions,
    })
    return api.patch(`${resourceUrl}/${strippedId}/permissions`, permissionsPayload)
  },

  getCaps(): Promise<RkvstResourceCaps> {
    return api.get(`${capsUrl}`)
  },
}
