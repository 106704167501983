import api from '@app/api'
import { AssetsFilterParams } from '@app/api/resources/assets'
import { useQuery } from '@tanstack/react-query'
import { RkvstAsset } from '@ui/types'
import { useEffect, useState } from 'react'

import { Polling } from './usePolling'

export interface AssetsFilter extends AssetsFilterParams {
  identity?: string
}

// useAssetFromRoute works within Asset routes only.
export const useAssetList = (filter?: AssetsFilter, nextToken?: string, timestampForce?: any) => {
  const result = useQuery<{ assets: RkvstAsset[]; next_page_token?: string }>({
    queryKey: ['assetList', timestampForce?.toString(), JSON.stringify(filter), nextToken],
    queryFn: async () => {
      if (filter?.identity) {
        const asset = await api.assets.get(filter?.identity)
        return asset ? { assets: [asset] } : { assets: [] }
      } else {
        return await api.assets.getListFiltered(filter, nextToken, { timeout: 5000 })
      }
    },
    keepPreviousData: true,
  })

  const loading = result.isLoading || result.isFetching || result.isRefetching
  const errorStatus = (result?.error as any)?.request?.status
  const isAllowedError = [400, 404].indexOf(errorStatus) !== -1
  const isCorrectResult = !result.isError || isAllowedError

  return {
    ...result,
    loading,
    data: !isCorrectResult ? [] : result.data?.assets,
    nextToken: !isCorrectResult ? undefined : result.data?.next_page_token,
    error: isCorrectResult ? null : result.error,
    isError: result.isError && !isAllowedError,
  }
}

export const useHasAssets = (onError?: (err: string) => any, polling?: Polling, filter: AssetsFilterParams = {}) => {
  const [hasAnyAssets, setHasAnyAssets] = useState(false)
  const [loading, isLoading] = useState(true)

  useEffect(() => {
    isLoading(true)
    const retrieve = async () => {
      try {
        const results = await api.assets.getListFiltered({ ...filter, page_size: 1 }, undefined, { timeout: 5000 })
        isLoading(false)
        setHasAnyAssets(!!results?.assets?.length)
      } catch (err) {
        onError && onError(err.toString())
        isLoading(false)
      }
    }
    retrieve()
  }, [polling?.pollingId, JSON.stringify(filter)])

  return {
    hasAnyAssets,
    isPolling: polling?.isPolling,
    loading: polling?.isPolling ? false : loading,
  }
}
