import { pickColor } from '@ui/themes'
import styled, { css } from 'styled-components'

import { HeaderCard } from '../Cards'

export const Container = styled(HeaderCard)`
  ${({ theme }) => `
  gap: 8px;

  .heading-font {
    font-family: ${theme._v1.typography.button.family};
  }

  .clickable {
    cursor: pointer;
  }

  .filter-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  .filter-selector {
    background-color: #fff;
    width: 320px;
    height: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    box-sizing: border-box;
    flex-grow: 0;
    text-align: left;
    .selection {
      width: 100%;
    }
  }

  .remove-filter {
    flex-grow: 0;
    height: 40px;
    width: 40px;
    padding: 0px;
    box-sizing: border-box;
  }

  .filter-list {
    list-style: none;
    padding-left: 0px;
    li {
      display: flex;
      align-items: strech;
      justify-content: strech;
      gap: 8px;
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .add-filter-footer {
    display: flex;
    align-items: center;
    width: fit-content;
    color: ${pickColor(theme?._v1, 'information', 'i500')};
  }
  `}
`
