import { Api } from '../core'
import { FilterQuery } from '../FilterQuery'

/**
 * Handles the indexed suggestions. Mainly used by typeaheads like components
 */
class Suggestions {
  private api = new Api({ baseURL: '/session/auto' })

  /**
   * suggestAssetAttributes(search = '', top = 10): Promise<string[]> | string[]
   * Returns suggestions for assets attributes
   *
   * @param search search parameter from where suggestions will be display
   * @param top number of suggestions been returned
   *
   * @returns Promise which resolves to the list of suggestions
   */
  public suggestAssetAttributes(search = '', top = 10): Promise<string[]> | string[] {
    if (search) {
      return this.get<string[]>('asset', search, false, 'attribute', top, 'k')
    } else {
      return []
    }
  }

  /**
   * suggestEventsAttributes(search = '', top = 10): Promise<string[]> | string[]
   * Returns suggestions for events attributes
   *
   * @param search search parameter from where suggestions will be display
   * @param top number of suggestions been returned
   *
   * @returns Promise which resolves to the list of suggestions
   */
  public suggestEventsAttributes(search = '', top = 10): Promise<string[]> | string[] {
    if (search) {
      return this.get<string[]>('event', search, false, 'attribute', top, 'k')
    } else {
      return []
    }
  }

  /**
   * get<T = any>(resource: string, prefix: string, value: boolean, entryType: string, top: number, searchFields: string, key?: string)
   * Generic functions for retrieving suggestions. Is a directy wrapper of the api
   * implementation for doing so and it is closer to the backend domain
   *
   * @param resource the resource type (asset | event | location)
   * @param prefix searchable string passed

   * @param value choice between the key or the value for a suggestion. true for value, false for key.
   *              eg. attribute{"foo": "bar"}, if true would get "bar", if false would get "foo".
   * @param entryType the type of entry, e.g. attribute.
   * @param top the number of suggestions to return, between 1 and 20, default 10.
   * @param searchFields pass 'v' for the searching on the value or 'k' to search on the key
   * @param key the key to search on for example 'arc_display_name'
   *
   * @returns a list of suggestion strings.
   */
  public get<T = any>(
    resource: string,
    prefix: string,
    value: boolean,
    entryType: string,
    top: number,
    searchFields: string,
    key?: string
  ) {
    if (!prefix) {
      return []
    }
    const filterQuery = new FilterQuery({})

    filterQuery.put('resource', resource)
    filterQuery.put('search', prefix)
    filterQuery.put('type', entryType)
    filterQuery.put('top', String(top))
    filterQuery.put('searchFields', searchFields)

    // only add key query if we need to.
    if (key != null) {
      filterQuery.put('key', key)
    }

    const query = filterQuery.build()

    // choose the correct index
    let index = 'keys'
    if (value) {
      index = 'values'
    }
    return this.api.get<T>(`/indexes/` + index + query)
  }
}

export default new Suggestions()
