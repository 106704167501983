import api from '../core'

const group = null
const resource = 'tenancies/root_principals'
const version = '1'
const resourceUrl = api.parseResourceUrl(group, resource, version)

export default {
  getList(): any {
    return api.get(resourceUrl)
  },
  create(data: any) {
    return api.patch(`${resourceUrl}`, data)
  },
  delete(data: any) {
    return api.patch(`${resourceUrl}`, data)
  },
}
