import styled from 'styled-components'

import { theme } from '../../Css'

export const PrimaryFigure = styled.figure`
  position: relative;
  margin: 0px;
`

export const QRContainer = styled.span`
  max-width: 20%;
  margin-left: auto;
  flex-shrink: 0;

  img {
    width: 28px;
    height: 28px;
    opacity: 1;
  }

  img:hover {
    opacity: 0.75;
  }
`

export const PrimaryFigureCaption = styled.figcaption`
  position: absolute;
  bottom: 0;
  left: 0;

  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  background: linear-gradient(180deg, #231f20, #333232);
  color: #fff;
  opacity: 0.8;
`

export const PrimaryFigureCaptionText = styled.span`
  width: 100%;
  flex-basis: content;
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Image = styled.img`
  min-width: 70px;
  min-height: 70px;

  height: auto;
  max-width: 100%;
  max-height: 400px;

  display: block;
  margin: 0 auto;
`
