import {
  BaseCardMixin,
  BodyText,
  Button,
  DropboxIcon,
  FeaturedIcon,
  H,
  PackageSearchIcon,
  PlusIcon,
} from '@ui/components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { SubsectionWrapper } from './AssetList.styles'
import { AddAssetModal } from './AssetSubsection'
import { AddDocumentModal } from './DocumentSubsection'

const Container = styled.section`
  ${BaseCardMixin}
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    max-width: 650px;
  }
  button {
    height: 40px;
  }
  .buttons-group {
    display: flex;
    gap: 8px;
  }
  .dropbox-btn {
    padding-left: 0px;
    svg {
      transform: scale(0.4);
    }
  }
`

export const AssetListEmptyState = (props: { onUpdate?: () => void }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showAssetModal, setShowAssetModal] = useState(false)
  const [showDocumentModal, setShowDocumentModal] = useState(false)

  return (
    <Container>
      <FeaturedIcon size="md" icon={<PackageSearchIcon />} />
      <H weight="semibold" sz="md">
        {t('No assets found')}
      </H>
      <H sz="sm">{t('Start by adding your first asset to get started')}</H>
      <p>
        <BodyText family="secondary" weight="regular">
          {t(
            `Click 'Register asset' or ‘Register document’ to manually input details and create immediate audit trails. Or, choose 'Connect Dropbox' to link your account and automatically generate verifiable records for your Dropbox files.`
          )}
        </BodyText>
      </p>
      <p className="buttons-group">
        <Button
          variant="secondary-grey"
          icon={<PlusIcon />}
          onClick={() => setShowAssetModal(true)}
          testTag="add_asset_button"
        >
          {t('Register Asset')}
        </Button>
        <Button
          variant="secondary-grey"
          icon={<PlusIcon />}
          onClick={() => setShowDocumentModal(true)}
          testTag="add_document_button"
        >
          {t('Register Document')}
        </Button>
      </p>
      <p>
        <Button
          variant="secondary-grey"
          testTag="add_integration_button"
          icon={<DropboxIcon />}
          className="dropbox-btn"
          onClick={() => navigate('/archivist/integrations')}
        >
          {t('Connect Dropbox')}
        </Button>
      </p>

      <AddAssetModal
        show={showAssetModal}
        setShow={setShowAssetModal}
        onSubmit={() => props.onUpdate && props.onUpdate()}
      />
      <AddDocumentModal
        show={showDocumentModal}
        setShow={setShowDocumentModal}
        onSubmit={() => props.onUpdate && props.onUpdate()}
      />
    </Container>
  )
}
