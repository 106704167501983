import React from 'react'
import { FieldRenderProps } from 'react-final-form'

import i18n from '../../i18n'
import Img from '../Img'
import showError from './showError'

type InputSelectProps = {
  id: string
  options: Array<{ key: string; value: string; disabled?: boolean }>
  label?: string
  helpText?: string
  placeholder?: string
  disabled?: boolean
  onSelect?: (value: any) => void
  className?: string
  selectText?: string
  defaultValue?: string
} & FieldRenderProps<string, HTMLInputElement>

const Presentation: React.FC<InputSelectProps> = (props) => {
  React.useEffect(() => {
    if (props.onSelect && props.defaultValue) {
      props.onSelect(props.defaultValue)
    }
  }, [])
  return (
    <div
      className={
        (props.label ? 'input select' : '') +
        (props.meta.error ? ' input-error' : '') +
        (props.className ? ` ${props.className}` : '')
      }
    >
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}

      {props.helpText ? <span className="helpText">{props.helpText}</span> : null}

      <div className="select-wrapper" id="select_wrapper">
        <select
          id={props.id}
          data-test={`${props.id}`}
          onChange={(event: any) => {
            props.input.onChange(event)

            if (props.onSelect) {
              props.onSelect(event.target.value)
            }
          }}
          value={props.input.value || (props.defaultValue ? props.defaultValue : '')}
        >
          {!props.defaultValue ? (
            <option value="" disabled>
              {props.selectText || i18n.t('globals:form.select.please_select')}
            </option>
          ) : null}

          {props.options.map((option, i) => {
            return (
              <option key={`option-${i}`} value={option.key} id={option.key} disabled={option.disabled}>
                {option.value}
              </option>
            )
          })}
        </select>

        <Img name="arrow-down" alt="event" className="arrow" />
      </div>

      {showError(props.meta) && <span className="error-message">{props.meta.error}</span>}
    </div>
  )
}

const InputSelect = Presentation

export default InputSelect
