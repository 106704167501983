import styled from 'styled-components'

export const CapReachedOverlayContainer = styled.div<{ fade?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: rgba(0, 0, 0, ${(p) => p.fade || 0.75});

  .centered-content {
    text-align: center;
  }
`
