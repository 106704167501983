import { stripAnyAssetResourceFromId } from '@ui/utils'
import get from 'lodash/get'
import includes from 'lodash/includes'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { withAlertsContext } from '../../../common/Alerts'
import i18n from '../../../i18n'
import { mapArrayById } from '../../../mapArrayById'
import { RkvstAsset } from '../../../state/initialState'
import { capitaliseFirstLetterOfWords } from '../../../utils/capitaliseWords'
import AssetConfirmationStatusIcon from '../../AssetConfirmationStatusIcon'
import Img from '../../Img'
import { withLoadingContext } from '../../Loading'
import AssetForm from '../AssetForm'
import AssetFormModal from '../AssetFormModal'
import DeleteAssetModal from '../DeleteAssetModal'
import ListFilter from '../ListFilter'
import useFilter, { EMPTY_FILTER_VALUE, FilterModel } from '../useFilter'
import { ListContainer } from './AssetList.style'
import { AssetListProps } from './AssetList.types'

const getLocationText = (locationId: string, locationsById: any) => {
  if (locationId === '') {
    return 'No location'
  } else {
    const location = locationsById[locationId]

    if (location) {
      return location.display_name
    } else {
      return 'Location Unresolved'
    }
  }
}

type FilterableColumns = 'type' | 'location'

const getDisplayTypes = (props: AssetListProps): string[] =>
  sortBy(
    uniq(
      props.assets
        .map((asset: RkvstAsset) => asset.attributes.arc_display_type as string)
        .filter((displayType: string) => displayType !== undefined)
    )
  )

const getUniqueLocationsForFilter = (assets: Array<RkvstAsset>, locationsById: any) => {
  const added: any = {}
  const uniqueLocationsForFilter: Array<any> = []

  assets.forEach(({ attributes }) => {
    if (attributes.arc_home_location_identity) {
      const location = locationsById[attributes.arc_home_location_identity as string] || {
        identity: attributes.arc_home_location_identity,
        display_name: 'Location Unresolved',
      }

      if (!added[location.identity]) {
        uniqueLocationsForFilter.push(location)
        added[location.identity] = true
      }
    }
  })

  const sorted = sortBy(uniqueLocationsForFilter, 'display_name')

  return sorted
}

const applyFilter = (values: Array<any>, field: string, filterModel: FilterModel) => {
  const filterValues = filterModel.values
    .filter((value: any) => filterModel.filters[value])
    .map((value: string) => value.trim())

  const includeEmptyValues = filterValues[0] === EMPTY_FILTER_VALUE

  const filteredValues = values.filter((value) => {
    const candidate = get(value, field)

    if (candidate === undefined || candidate === null) {
      return includeEmptyValues
    }

    return includes(filterValues, candidate.trim())
  })

  return filteredValues
}

const applyFilters = (
  values: Array<any>,
  filters: Array<{
    field: string
    filterModel: FilterModel
  }>
) => {
  filters.forEach((filter) => {
    values = applyFilter(values, filter.field, filter.filterModel)
  })

  return values
}

const ColumnWithFilter: React.FC<{
  column: FilterableColumns
  showFilter: FilterableColumns | null
  setShowFilter: (value: FilterableColumns | null) => void
  filterModel: FilterModel
  children?: React.ReactNode
}> = (props) => {
  const showFilter = props.showFilter === props.column

  return (
    <th
      className={`${props.column} with-filter` + (showFilter ? ' clicked' : '')}
      onClick={() => {
        props.setShowFilter(showFilter ? null : props.column)
      }}
    >
      {showFilter ? (
        <>
          <span className="close-button">X</span>
          <ListFilter field={props.column} filterModel={props.filterModel} />
        </>
      ) : (
        <Img name="search-icon" alt="Filter" />
      )}
      {props.children}
    </th>
  )
}

const SHOW_EMPTY_OPTION = true

const Presentation: React.FC<AssetListProps> = (props) => {
  const locationsById = mapArrayById(props.locations || [], 'identity')
  const [showEditAsset, setShowEditAsset] = useState<RkvstAsset | null>(null)
  const [showDeleteAsset, setShowDeleteAsset] = useState<RkvstAsset | null>(null)
  const [showFilter, setShowFilter] = useState<FilterableColumns | null>(null)
  const [filteredAssets, setFilteredAssets] = useState<Array<any>>([])
  const [hasMore, setHasMore] = useState<boolean>(false)

  const displayTypes: string[] = getDisplayTypes(props)
  const locations = getUniqueLocationsForFilter(props.assets, locationsById)
  const locationLabels = locations.map(({ display_name }) => display_name)
  const locationValues = locations.map(({ identity }) => identity)

  const filterData = () => {
    let filteredAssetsArray: Array<any> = []
    filteredAssetsArray = applyFilters(props.assets, [
      {
        field: 'attributes.arc_display_type',
        filterModel: filterModels.type,
      },
      {
        field: 'attributes.arc_home_location_identity',
        filterModel: filterModels.location,
      },
    ])

    if (props.limit) {
      setFilteredAssets(filteredAssetsArray.slice(0, props.limit))
      setHasMore(filteredAssetsArray.length > props.limit)
    } else {
      setFilteredAssets(filteredAssetsArray)
    }

    // sets the number of client side filtered assets to display on the page above
    if (props.setNumberOfClientSideFilteredResults) {
      props.setNumberOfClientSideFilteredResults(filteredAssetsArray.length)
    }
  }

  const filterModels = {
    type: useFilter(displayTypes, displayTypes, SHOW_EMPTY_OPTION, [], filterData),
    location: useFilter(locationLabels, locationValues, SHOW_EMPTY_OPTION, [], filterData),
  }

  // update filter models when new assets are loaded in
  useEffect(() => {
    filterModels.type.update(displayTypes, displayTypes)
    filterModels.location.update(locationLabels, locationValues)
  }, [props.assets])

  useEffect(() => {
    filterData()
  }, [props.assets, props.locations])

  return (
    <ListContainer className="asset-list col-12">
      <table className="clickablerows legacy-table" data-test="asset-list">
        <thead>
          <tr>
            <th className="status">{i18n.t('assets:status')}</th>
            <th className="id">{i18n.t('assets:asset_id')}</th>
            <th className="name">{i18n.t('assets:asset_name')}</th>

            <ColumnWithFilter
              column="type"
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterModel={filterModels.type}
            >
              {i18n.t('assets:asset_type')}
            </ColumnWithFilter>

            <ColumnWithFilter
              column="location"
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterModel={filterModels.location}
            >
              {i18n.t('assets:location')}
            </ColumnWithFilter>
            <th className="storage">{i18n.t('assets:proof_mechanism')}</th>
            <th className="edit delete" colSpan={3}>
              &nbsp;
            </th>
          </tr>
        </thead>

        <tbody>
          {filteredAssets.map((asset, i) => (
            <tr
              key={uuid()}
              data-test={`asset_row_${i}`}
              onClick={(ev: any) => {
                if (ev.button == 0) {
                  if (ev.ctrlKey || ev.metaKey) {
                    window.open(`/${asset.identity}`, '_blank')
                  } else {
                    window.open(`/${asset.identity}`, '_self')
                  }
                } else if (ev.button == 1) {
                  window.open(`/${asset.identity}`, '_blank')
                }
                ev.stopPropagation()
              }}
            >
              <td className="icon">
                <AssetConfirmationStatusIcon
                  confirmedIcon="Asset-Icon-On-White"
                  confirmedAlt="Asset"
                  status={asset.confirmation_status}
                  tracked={asset.tracked === 'TRACKED'}
                />
              </td>
              <td className="id">
                <span title={asset.identity}>{stripAnyAssetResourceFromId(asset.identity)}</span>
              </td>
              <td className="name">
                {asset.attributes.arc_display_name
                  ? asset.attributes.arc_display_name
                  : stripAnyAssetResourceFromId(asset.identity)}
              </td>
              <td className="type">{asset.attributes.arc_display_type}</td>
              <td className="location">
                {getLocationText(asset.attributes.arc_home_location_identity, locationsById)}
              </td>
              <td className="type">{capitaliseFirstLetterOfWords(asset.proof_mechanism, '_')}</td>
            </tr>
          ))}

          {filteredAssets.length === 0 ? (
            <tr>
              <td colSpan={8}>
                {props.loading
                  ? i18n.t('Loading assets...')
                  : props.errors
                  ? i18n.t('Unable to load assets')
                  : i18n.t('assets:no_assets_found')}
              </td>
            </tr>
          ) : hasMore ? (
            <tr>
              <td colSpan={8}>{i18n.t('And more...')}</td>
            </tr>
          ) : null}
        </tbody>
      </table>

      {showEditAsset ? (
        <AssetFormModal
          onClose={() => {
            setShowEditAsset(null)
          }}
          title="Edit Asset"
          Form={
            <AssetForm
              locations={props.locations}
              asset={showEditAsset}
              onClose={() => {
                setShowEditAsset(null)
              }}
            />
          }
        />
      ) : showDeleteAsset ? (
        <DeleteAssetModal
          asset={showDeleteAsset}
          onClose={() => {
            setShowDeleteAsset(null)
          }}
        />
      ) : null}
    </ListContainer>
  )
}

const AssetList = withAlertsContext(withLoadingContext(Presentation))

export default AssetList
