import styled from 'styled-components'

import { theme } from '../../Css'

export const Container = styled.div<{ css?: any }>`
  &.modal {
    display: none;
    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;

      .modal-container {
        .modal-container {
          width: 80%;
          max-width: 850px;
        }
        position: relative;
        margin: 0 auto;
        padding: 30px;
        padding-bottom: 0;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 3px 60px 0 rgba(0, 0, 0, 0.3);
        overflow: hidden;

        @media (max-width: ${theme.layout.grid.max.md}px) {
          padding-left: 15px;
          padding-right: 15px;
        }

        button.close {
          position: absolute;
          top: 20px;
          right: 24px;
          font-size: 20px;
          margin-top: unset;
        }

        .modal-body {
          margin-top: 15px;
          padding-bottom: 45px;
          max-height: 80vh;
          overflow-x: hidden;
          overflow-y: auto;
          text-align: left;

          h3 {
            display: inline-block;

            @media (max-width: ${theme.layout.grid.max.md}px) {
              margin-top: 0;
            }

            img {
              vertical-align: middle;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  &.modal.active {
    display: table;

    .modal-container {
      top: -2000px;
    }
  }

  &.modal.open {
    background-color: rgba(0, 0, 0, 0.5);
    .modal-container {
      top: 0;
    }
  }

  &.modal.modal-x-large .modal-container {
    max-width: 1000px;

    .modal-body.modal-body {
      min-height: 75vh;
      max-height: inherit;
    }

    @media (max-width: 990px) {
      width: calc(100% - 40px);
    }
  }

  &.modal.modal-large .modal-container {
    max-width: 1000px;
    @media (max-width: 990px) {
      width: calc(100% - 40px);
    }
  }

  &.modal.modal-medium .modal-container {
    max-width: 750px;
    @media (max-width: 790px) {
      width: calc(100% - 40px);
    }
  }

  &.modal.modal-small .modal-container {
    max-width: 500px;
    @media (max-width: 540px) {
      width: calc(100% - 40px);
    }
  }

  ${(p) => p.css || ''}
`
