import { ChevronDownIcon, ChevronRightIcon, DataTrailsFavIconSvg, H } from '@ui/components'
import { SearchPlaceHolder, SectionWrapper } from '@ui/components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOpen } from '../../../configuration.slice'
import { FilterTypeaheadCategories, SectionTitle } from './AssetList.styles'
import { Filter } from './AssetList.types'
import { AssetSubsection } from './AssetSubsection'
import { DocumentSubsection } from './DocumentSubsection'
import { useCategories } from './useCategories'

interface AssetSectionProps {
  startClosed?: boolean
}

export const AssetSection = (props: AssetSectionProps) => {
  const { open, setOpen } = useOpen('asset:list:section:asset')
  const [filter, setFilter] = useState<Filter>({})
  const { t } = useTranslation()

  /*
   * comented as this has been put in and back some times during the design
   * and it might be potentially needed to put it back after feedback
  const { categories, renderCategory } = useCategories(
    [
      { name: 'displayName', title: t('Name') },
      { name: 'type', title: t('Type') },
      { name: 'assetId', title: t('Asset ID') },
      { name: 'version', title: t('Version') },
      { name: 'location', title: t('Location') },
      { name: 'attestation' },
      //{name: 'file', title: t('Attachment file').toString()},
    ],
    filter,
    setFilter
  )
  */

  return (
    <SectionWrapper
      title={
        <SectionTitle onClick={() => setOpen(!open)} className="section-title" data-test="asset-section">
          {open ? <ChevronDownIcon className="chevron" /> : <ChevronRightIcon className="chevron" />}
          <img src={DataTrailsFavIconSvg} className="asset-icon" />
          <H sz="md" weight="semibold" className="title datatrails">
            {t('DataTrails Native')}
          </H>
        </SectionTitle>
      }
      actions={
        <>
          {/*
          <form action="#" autoComplete="off">
            <FilterTypeaheadCategories
              className="typeahead"
              initialCateogry={0}
              categories={categories}
              testTag="filter-assets"
              sizeCategory="150px"
              placeholder={t('Select filter')}
              placeholderContent={<SearchPlaceHolder />}
              renderCategory={renderCategory}
              onSelect={() => {
                setFilter({})
              }}
            />
          </form>
          */}
        </>
      }
    >
      {open && (
        <>
          <DocumentSubsection filter={filter} setFilter={setFilter} />
          <AssetSubsection filter={filter} setFilter={setFilter} />
        </>
      )}
    </SectionWrapper>
  )
}
