import api from '@app/api'
import { AlertData } from '@app/common/Alerts/Alerts.types'
import { regenerateAppRegistrationSecret } from '@app/state/actions/appRegistrations'
import { DropboxConnection } from '@app/state/initialState'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface SecretGenerationArgs {
  clientId: string
  errorAlert: (props: AlertData) => void
}

interface FolderConfigArgs {
  folders: string[]
  public?: boolean
}

interface ConnectionPatchData {
  rkvst_client_secret?: string
  dropbox_folders?: string[]
}

export const getDropboxConnection = createAsyncThunk('dropboxConnection/getDropboxConnection', async () => {
  const response = await api.dropboxConnection.getConnection()
  return response
})

const updateDropboxSecretsAndFolders = createAsyncThunk(
  'dropboxConnection/patchDropboxConnection',
  async (patch: ConnectionPatchData) => {
    const response = await api.dropboxConnection.patchConnection(patch)
    return response
  }
)

const forceDropboxFetch = createAsyncThunk('dropboxConnection/forceFetch', async () => {
  await api.dropboxConnection.fetchdata()
})

export const setFoldersForDropboxIntegration = createAsyncThunk(
  'dropboxConnection/foldersConfigured',
  async (args: FolderConfigArgs, { dispatch }) => {
    dispatch(updateDropboxSecretsAndFolders({ dropbox_folders: args.folders })).then(() => {
      dispatch(forceDropboxFetch())
    })
  }
)

export const generateNewClientSecretForDropboxIntegration = createAsyncThunk(
  'dropboxConnection/newSecret',
  async (args: SecretGenerationArgs, { dispatch }) => {
    dispatch(regenerateAppRegistrationSecret(args.clientId, args.errorAlert)).then((val) => {
      if (val) dispatch(updateDropboxSecretsAndFolders({ rkvst_client_secret: val.credentials[0]?.secret }))
    })
  }
)

export const disconnectDropboxIntegration = createAsyncThunk('dropboxConnection/deleteDropboxConnection', async () => {
  const response = await api.dropboxConnection.deleteConnection()
  return response
})

const dropoxIntegrationSlice = createSlice({
  name: 'dropboxConnection',
  initialState: { loading: true } as DropboxConnection,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDropboxConnection.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getDropboxConnection.rejected, (state, action) => {
        state.error = true
        state.loading = false
      })
      .addCase(getDropboxConnection.fulfilled, (state, action) => {
        state.connection = action.payload.connection
        state.connected = action.payload.connected
        state.loading = false
      })
      .addCase(disconnectDropboxIntegration.pending, (state, action) => {
        state.loading = true
      })
      .addCase(disconnectDropboxIntegration.rejected, (state, action) => {
        state.error = true
        state.loading = false
      })
      .addCase(disconnectDropboxIntegration.fulfilled, (state, action) => {
        state.connection = undefined
        state.connected = undefined
        state.loading = false
      })
      .addCase(updateDropboxSecretsAndFolders.pending, (state, action) => {
        state.loading = true
      })
      .addCase(updateDropboxSecretsAndFolders.rejected, (state, action) => {
        state.error = true
        state.loading = false
      })
      .addCase(updateDropboxSecretsAndFolders.fulfilled, (state, action) => {
        state.connection = action.payload.connection
        state.connected = action.payload.connected
        state.loading = false
      })
  },
})

export default dropoxIntegrationSlice.reducer
