import styled from 'styled-components'

export const ProgressContainer = styled.div`
  & .button-action-cancel {
    margin-left: 10px;
  }

  display: flex;

  .file-type {
    background-image: url('/dist/assets/large-file-bg.png');
    width: 40px;
    height: 49px;
    min-width: 40px;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 4px;
    font-size: 0.75rem;
    span {
      align-self: flex-end;
    }
  }

  .container {
    .file-name {
      margin: 0;
      margin-bottom: 2px;
      text-transform: none;
    }

    .progress-container {
      .progress-totals {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        .file-count {
        }
        .progress-total {
        }
      }
      .progress {
        height: 0.75rem;
        line-height: 0;
        font-size: 0.75rem;
        background-color: #e9ecef;
        border-radius: 0.25rem;

        display: flex;
        overflow: hidden;
        .progress-bar {
          flex-direction: column;
          justify-content: center;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          background-color: #007bff;
          transition: width 0.6s ease;
        }
      }
    }
  }
`
