import api from '@app/api'
import accessPoliciesResource, { wildcard } from '@app/api/resources/policy'
import { OnboardingState, RkvstEvent } from '@app/state/initialState'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RkvstAccessPolicy, RkvstAppRegistration, RkvstAsset, RkvstCreateAccessPolicy } from '@ui/types'

import { OnboardingSample } from '../../pages/OnboardingSuccess/OnboardingSuccess.types'

const AppRegistrationDisplayName = 'Onboarding Samples App Reg'
const AccessPolicyDisplayName = 'Onboarding Samples Policy'

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * RequestSample submits a request to the samples service
 */
export const RequestSample = createAsyncThunk('onboarding/requestSample', async (sampleType: OnboardingSample) => {
  const response = await api.sampleSets.create({
    dataset: sampleType,
    proof_mechanism: 'MERKLE_LOG',
  })

  return response
})

/**
 * WatchSample polls until it finds at least one asset with the matching sample ID.
 * It retries every 500ms a maximum of 10 times.
 */
export const WatchSample = createAsyncThunk(
  'onboarding/watchSample',
  async (sampleType: OnboardingSample): Promise<RkvstAsset | undefined> => {
    const interval = 500
    for (let i = 0; i < 10; i++) {
      const response = await api.assets.getListFiltered({
        sampleTag: sampleType,
      })

      const assets = response.assets as RkvstAsset[]
      if (assets.length > 0) {
        return assets[0]
      }

      await delay(interval)
    }

    return undefined
  }
)

/**
 * GetOnboardingAppReg gets the onboarding app registration
 *
 * If the app registration does NOT exist, create then return it.
 */
export const GetOnboardingAppReg = createAsyncThunk('onboarding/getOnboardingAppReg', async () => {
  // first check if we already have the onboarding app registration
  const getResponse = await api.appRegistrations.getListFiltered({
    displayName: AppRegistrationDisplayName,
  })

  const applications = getResponse.applications as RkvstAppRegistration[]
  if (applications.length > 0) {
    // TODO: regenerate the secret here
    return applications[0]
  }

  // if we get here we need to create the onboarding app registration
  const response = await api.appRegistrations.create({
    display_name: AppRegistrationDisplayName,
  })

  return response
})

/**
 * RegenOnboardingSecret regenerates the onboarding app registration secret
 */
export const RegenOnboardingSecret = createAsyncThunk('onboarding/regenOnboardingSecret', async () => {
  // first check if we already have the onboarding app registration
  const getResponse = await api.appRegistrations.getListFiltered({
    displayName: AppRegistrationDisplayName,
  })

  const applications = getResponse.applications as RkvstAppRegistration[]

  if (applications.length === 0) {
    // TODO: error condition
    return undefined
  }

  let application = applications[0]

  application = await api.appRegistrations.regenerateSecret(application.client_id)

  return application
})

/**
 * GetOnboardingAccessPolicy gets the onboarding access policy
 *
 * If the access policy does NOT exist, create then return it.
 */
export const GetOnboardingAccessPolicy = createAsyncThunk('onboarding/getOnboardingAccessPolicy', async () => {
  // first check if we already have the onboarding access policy
  const getResponse = await accessPoliciesResource.getListFiltered({
    displayName: AccessPolicyDisplayName,
  })

  const accessPolicies = getResponse.access_policies as RkvstAccessPolicy[]
  if (accessPolicies.length > 0) {
    return accessPolicies[0]
  }

  // create the access policy based on a well known custom claim
  const accessPolicy: RkvstCreateAccessPolicy = {
    display_name: AccessPolicyDisplayName,
    description:
      'Onboarding Sample policy allowing the onboarding app registration permission to view/edit onboarding assets/events',
    filters: [
      {
        or: [
          'attributes.OnboardingSampleID=DocumentLineage',
          'attributes.OnboardingSampleID=SBOM',
          'attributes.OnboardingSampleID=C2PA',
          'attributes.OnboardingSampleID=TrackAndTrace',
        ],
      },
    ],
    access_permissions: [
      {
        subjects: [],
        behaviours: wildcard,
        asset_attributes_write: wildcard,
        asset_attributes_read: wildcard,
        event_arc_display_type_read: wildcard,
        event_arc_display_type_write: wildcard,
        user_attributes: [
          {
            or: [
              // inject the app reg claim here, it gives abac permisisons to the app registration.
              // NOTE: the `jwt_` prefix is needed for custom claims on jwt matchers
              //       for abac.
              `jwt_name=${AppRegistrationDisplayName}`,
            ],
          },
        ],
      },
    ],
  }

  const response = await accessPoliciesResource.create(accessPolicy)

  return response
})

export interface WatchSampleEventsArgs {
  sampleType: OnboardingSample
  assetId: string
}

export const WatchSampleEvents = createAsyncThunk(
  'onboarding/watchSampleEvents',
  async (args: WatchSampleEventsArgs, { dispatch }) => {
    const { sampleType, assetId } = args
    const intervalId = setInterval(async () => {
      const response = await api.assets.getEventList(assetId)
      const events = response.events as RkvstEvent[]

      // The default server ordering is most recent first, which isn't what we want here.
      dispatch(SetSampleEvents(sampleType, events.reverse()))

      if (events.some((e) => 'OnboardingFinalEventMarker' in e.event_attributes)) {
        dispatch(SetSampleReady(sampleType))
        clearInterval(intervalId)
      }
    }, 1000)
  }
)

export const SetSampleReady = (sampleType: OnboardingSample) => ({
  type: 'onboarding/setSampleReady',
  payload: sampleType,
})

export const SetSampleEvents = (sampleType: OnboardingSample, events: RkvstEvent[]) => {
  return {
    type: 'onboarding/setSampleEvents',
    payload: {
      sampleType: sampleType,
      events: events,
    },
  }
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    application: undefined,
    applicationError: undefined,
    awaitingApplication: true,
    awaitingAccessPolicy: true,
    accessPolicy: undefined,
    samples: {
      DocumentLineage: {
        sampleType: 'DocumentLineage',
        isAssetLoading: true,
        sampleReady: false,
        events: [],
      },
      SBOM: {
        sampleType: 'SBOM',
        isAssetLoading: true,
        sampleReady: false,
        events: [],
      },
      C2PA: {
        sampleType: 'C2PA',
        isAssetLoading: true,
        sampleReady: false,
        events: [],
      },
      TrackAndTrace: {
        sampleType: 'TrackAndTrace',
        isAssetLoading: true,
        sampleReady: false,
        events: [],
      },
    },
  } as OnboardingState,
  reducers: {
    setSampleEvents(state, action) {
      const { sampleType, events } = action.payload
      state.samples[sampleType].events = events as RkvstEvent[]
    },
    setSampleReady(state, action) {
      const sampleType = action.payload
      state.samples[sampleType].sampleReady = true
    },
  },
  extraReducers: (builder) => {
    // TODO: Handle rejected actions
    builder.addCase(WatchSample.pending, (state, action) => {
      const sampleType = action.meta.arg
      state.samples[sampleType].isAssetLoading = true
    })

    // Once found, we're no longer loading and we can set the asset.
    builder.addCase(WatchSample.fulfilled, (state, action) => {
      const sampleType = action.meta.arg
      state.samples[sampleType].isAssetLoading = false
      if (action.payload) {
        state.samples[sampleType].asset = action.payload
      }
    })

    builder.addCase(GetOnboardingAppReg.pending, (state, action) => {
      state.awaitingApplication = true
    })

    builder.addCase(GetOnboardingAppReg.fulfilled, (state, action) => {
      state.awaitingApplication = false
      if (action.payload) {
        state.application = action.payload as RkvstAppRegistration
      }
    })

    builder.addCase(GetOnboardingAppReg.rejected, (state, action) => {
      state.awaitingApplication = false
      console.log(action.error)
      if (action.error) {
        state.applicationError = action.error
        console.log(action.error.code)
      }
    })

    builder.addCase(RegenOnboardingSecret.pending, (state, action) => {
      state.awaitingApplication = true
    })

    builder.addCase(RegenOnboardingSecret.fulfilled, (state, action) => {
      state.awaitingApplication = false
      if (action.payload) {
        state.application = action.payload as RkvstAppRegistration
      }
    })

    builder.addCase(RegenOnboardingSecret.rejected, (state, action) => {
      state.awaitingApplication = false
    })

    builder.addCase(GetOnboardingAccessPolicy.pending, (state, action) => {
      state.awaitingAccessPolicy = true
    })

    builder.addCase(GetOnboardingAccessPolicy.fulfilled, (state, action) => {
      state.awaitingAccessPolicy = false
      if (action.payload) {
        state.accessPolicy = action.payload as RkvstAccessPolicy
      }
    })

    builder.addCase(GetOnboardingAccessPolicy.rejected, (state, action) => {
      state.awaitingAccessPolicy = false
    })
  },
})

export default onboardingSlice.reducer
