import { QueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
      refetchOnWindowFocus: false,
      retry: (failureCount, error: AxiosError) => {
        // If the resource doesn't exist or the request isn't valid, retrying isn't helpful.
        if ([404, 403, 401, 400].includes(error?.request?.status)) {
          return false
        }

        if (failureCount >= 3) {
          return false
        }

        return true
      },
    },
  },
})
