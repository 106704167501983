import { RkvstAsset } from "@ui/types";

export const getAssetName = (asset: RkvstAsset) => {
  return (
    asset &&
    (asset.attributes.arc_display_name
      ? (asset.attributes.arc_display_name as string)
      : stripAnyAssetResourceFromId(asset.identity))
  );
};

// makeAssetId creates the asset identity from a uuid, depending on the public status
export const makeAssetId = (uuid: string, isPublic: boolean) => {
  if (isPublic) {
    return `publicassets/${uuid}`;
  }

  return `assets/${uuid}`;
};

// makeEventId creates the asset identity from a uuid, depending on the public status
export const makeEventId = (
  assetUuid: string,
  eventUuid: string,
  isPublic: boolean
) => {
  if (isPublic) {
    return `publicassets/${assetUuid}/events/${eventUuid}`;
  }

  return `assets/${assetUuid}/events/${eventUuid}`;
};

/**
 * ensurePublicIdentity is a temporary workaround that converts permissioned identities into their
 * supposed public counterpart. This works given the assumption that the same UUID is used, which is
 * true at time of writing. This function exists to prevent breakages once the backend fix goes in.
 *
 * We can remove this once the backened work for #7598 is merged.
 */
export const ensurePublicIdentity = (identity: string): string => {
  if (identity.startsWith("assets")) {
    return `public${identity}`;
  }

  return identity;
};

export const stripAnyAssetResourceFromId = (identity: string): string => {
  if (identity.startsWith("publicassets")) {
    return identity.replace("publicassets/", "");
  }

  return identity.replace("assets/", "");
};
