import styled from 'styled-components'

import { theme } from '../../../Css'
import { combobox, comboboxSearch } from '../../../styles/FormInputs'
import { headlessCombobox } from '../../../styles/ThirdPartyCss'

export const Container = styled.div`
  ${combobox}
  ${comboboxSearch}
  ${headlessCombobox}

  .highlighted {
    background-color: inherit;
    font-weight: ${theme.font.bold};
  }
`
