import { lazily } from 'react-lazily'

import { wrapVisibleSuspense } from '../../atoms/placeholders/Suspense'

const {
  SelectableTree: RawSelectableTree,
  LazyTree: RawLazyTree,
  LazyTreeCheckBoxNode: RawLazyTreeCheckBoxNode,
} = lazily(() => import('./LazyTree'))
const SelectableTree = wrapVisibleSuspense<typeof RawSelectableTree>(RawSelectableTree)
const LazyTree = wrapVisibleSuspense<typeof RawLazyTree>(RawLazyTree)
const LazyTreeCheckBoxNode = wrapVisibleSuspense<typeof RawLazyTreeCheckBoxNode>(RawLazyTreeCheckBoxNode)
export { LazyTree, LazyTreeCheckBoxNode, SelectableTree }
