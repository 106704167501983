import { useEffect, useState } from 'react'

export interface Polling {
  pollingId?: number
  isPolling: boolean
  cancel(): void
}

export interface UsePollingOpts {
  // polling frequency
  frequency?: number
  // maximum polling attempts
  attempts?: number
  // unique number for triggering refetching
  refetch?: number
}

export const usePolling = (opts: UsePollingOpts = {}, debug?: string): Polling => {
  const [pollingId, setPollingId] = useState<number | undefined>(opts?.refetch)
  const [isPolling, setIsPolling] = useState(false)

  const cancel = () => {
    if (pollingId) {
      clearTimeout(pollingId)
    }
  }

  useEffect(() => {
    cancel()

    // default attemps
    let attempts = opts?.attempts || 1

    const pollingStopped = () => attempts < 0

    const stopPoll = (timeId?: number) => {
      clearTimeout(timeId)
      setIsPolling(false)
      setPollingId(timeId)
    }

    let timeId = 0

    const poll = () => {
      attempts -= 1

      timeId = window.setTimeout(() => {
        if (timeId) {
          setIsPolling(true)

          if (pollingStopped()) {
            stopPoll(timeId)
          } else {
            poll()
            setPollingId(timeId)
          }
        }
      }, opts?.frequency || 1000)
    }

    if (!pollingStopped()) {
      poll()
    } else {
      stopPoll(opts?.refetch)
    }

    return () => {
      cancel()
      timeId = 0
    }
  }, [opts?.frequency, opts?.attempts, opts?.refetch, debug])

  return { pollingId, isPolling, cancel: () => cancel() }
}
