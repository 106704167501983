import { rkvstTheme } from '@ui/themes'
import { useMemo } from 'react'

export interface TimeTrendProps {
  width: number
  height: number
  data: number[]
  fill?: string
  stroke?: string
  padding?: number
}

export const TimeTrend = (props: TimeTrendProps) => {
  const path = useMemo(() => {
    const maxPoint = Math.max.apply(null, props.data)
    const minPoint = Math.min.apply(null, props.data)
    const offset = minPoint < 0 ? -minPoint : 0
    const top = props.height * (props.padding || 0.9)
    const bottom = props.height * (props.padding || 0.1)
    const height = top - bottom
    const ratio = (maxPoint - minPoint) / height

    const dt = props.width / (props.data.length - 1)
    let t = 0
    const path = props.data.map((datum) => {
      const point = t + ' ' + (props.height - ((datum + offset) / ratio + bottom))
      t += dt
      return point
    })
    return 'M ' + path.join(' L') + ` L ${props.width} ${props.height} L 0 ${props.height} Z`
  }, [props.width, props.height, props.padding, props.data])

  return (
    <svg width={props.width} height={props.height} xmlns="http://www.w3.org/2000/svg">
      <path stroke={props.stroke || rkvstTheme.colors.success.i500} d={path} stroke-width="3" />

      <defs>
        <clipPath id="cut-path">
          <path d={path} />
        </clipPath>
      </defs>
      <rect
        x="0"
        y="0"
        width={props.width}
        height={props.height}
        fill={props.fill || rkvstTheme.colors.success.i100}
        clip-path="url(#cut-path)"
      />
    </svg>
  )
}
