import styled from 'styled-components'

import Badge from './LegacyBadge'

// inheriting styles from parent "Badge" component
const VerifiedBadge = styled(Badge as any)`
  color: white;
  border-radius: 12px;
  font-weight: 500;
  background: rgb(0, 174, 239);
  padding: 12px;
  white-space: nowrap;
  cursor: default;
  .identity-icon {
    display: flex;
    align-items: center;
    vertical-align: middle;
    padding: 1px;
    padding-right: 5px;
  }
`

export default VerifiedBadge
