import React from 'react'
import { connect } from 'react-redux'
import { css } from 'styled-components'

import { Modal } from '../../common/Modal'
import actionCreators from '../../state/actionCreators'
import { RkvstAsset } from '../../state/initialState'
import Img from '../Img'
import { WithLoadingContext, withLoadingContext } from '../Loading'

type Props = {
  asset: RkvstAsset
  onClose: () => void
} & typeof mapDispatch &
  WithLoadingContext

const modalStyles = css`
  &.delete-asset {
    h3 {
      margin-bottom: 90px;
    }
    p {
      height: 55px;
      margin-bottom: 50px;
    }
    .button-action-delete {
      background: linear-gradient(180deg, #eb0000, #c80000);
      margin-right: 10px;
    }
  }
`

const Presentation: React.FC<Props> = (props) => (
  <Modal onClose={props.onClose} className="delete-asset" css={modalStyles} size="small">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h3>
            <Img name="Delete-Icon" alt="Trash" />
            <span>Stop Tracking {props.asset.identity}</span>
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <p>The selected asset will no longer be tracked by DataTrails.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button
            className="button-action button-action-delete"
            onClick={async () => {
              props.setLoading(true)
              await props.deleteAsset(props.asset.identity)
              props.setLoading(false)
            }}
          >
            <Img name="Delete-Empty-Icon-White" alt="Trash" />
            <span className="text-vertical-middle">Stop Tracking</span>
          </button>
          <button className="button-action button-action-cancel" onClick={props.onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  </Modal>
)

const mapDispatch = {
  deleteAsset: actionCreators.assets.stopTracking,
}

const DeleteAssetModal = connect(null, mapDispatch)(withLoadingContext(Presentation))

export default DeleteAssetModal
