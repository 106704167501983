import styled from 'styled-components'

import { layout, theme } from '../../../Css'

export const AdvancedDetailsTabLayout = styled.div`
  display: grid;

  @media (min-width: ${layout.grid.min.sm}px) {
    grid:
      '. .'
      'attributes2 attributes2'
      'attributes attributes';
  }

  @media (max-width: ${layout.grid.max.sm}px) {
    grid:
      '.'
      '.'
      'attributes'
      'attributes2';
  }

  grid-gap: 5px 20px;

  .attributes {
    grid-area: attributes;
  }

  .attributes2 {
    grid-area: attributes2;
  }
`

export const DocumentDetailsTabLayout = styled.div`
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  grid-gap: 5px 20px;

  .basic {
    margin-top: 19px;
  }

  .file {
    h3 {
      margin-bottom: 5px;
    }

    .dropdown-text {
      max-width: 125px;
    }

    span.long {
      white-space: unset;
    }

    @media (min-width: ${layout.grid.min.sm}px) {
      border-left: 1px solid ${theme.colors.soft_highlight};
      padding-left: 25px;
    }

    div > section {
      height: 220px;
    }

    h4.lined {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h4 span {
      margin: 0 15px;
      color: ${theme.typography.helpText.color};
    }
    h4:before,
    h4:after {
      background: ${theme.colors.soft_highlight};
      height: 1px;
      flex: 1;
      content: '';
    }
  }
`
