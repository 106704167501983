import { RkvstAppRegistration } from '@app/state/initialState'
import { RkvstCreateAppRegistration } from '@ui/types'
import { Dispatch } from 'react'

import appRegApi from '../../api/resources/appRegistrations'
import errAsMessage from '../utils/errAsMessage'

const appRegistrationFetchPending = () => ({
  type: 'api/applications/getList_PENDING',
})
const appRegistrationFetchSuccess = (data: RkvstAppRegistration[]) => ({
  type: 'api/applications/getList_FULFILLED',
  payload: data,
})
const appRegistrationFetchFail = (error: any) => ({
  type: 'api/applications/getList_FAILED',
  error,
})

const appRegistrationDeletePending = () => ({
  type: 'api/applications/delete_PENDING',
})
const appRegistrationDeleteSuccess = (id: any) => ({
  type: 'api/applications/delete_FULFILLED',
  payload: id,
})
const appRegistrationDeleteFail = (error: any) => ({
  type: 'api/applications/delete_FAILED',
  error,
})

const appRegistrationSecretRegenerationPending = () => ({
  type: 'api/applications/regenerateSecret_PENDING',
})
const appRegistrationSecretRegenerationSuccess = (id: any) => ({
  type: 'api/applications/regenerateSecret_FULFILLED',
  payload: id,
})
const appRegistrationSecretRegenerationFail = (error: any) => ({
  type: 'api/applications/regenerateSecret_FAILED',
  error,
})

const appRegistrationCreatePending = () => ({
  type: 'api/applications/create_PENDING',
})
const appRegistrationCreateSuccess = (data: RkvstCreateAppRegistration) => ({
  type: 'api/applications/create_FULFILLED',
  payload: data,
})
const appRegistrationCreateFail = (error: any) => ({
  type: 'api/applications/create_FAILED',
  error,
})

const appRegistrationUpdatePending = () => ({
  type: 'api/applications/update_PENDING',
})
const appRegistrationUpdateSuccess = (data: RkvstAppRegistration) => ({
  type: 'api/applications/update_FULFILLED',
  payload: data,
})
const appRegistrationUpdateFail = (error: any) => ({
  type: 'api/applications/update_FAILED',
  error,
})

export const fetchAppRegistrations = (setAlert: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(appRegistrationFetchPending())
    const result = await appRegApi.getList()
    dispatch(appRegistrationFetchSuccess(result))
  } catch (err) {
    const errorMessage = errAsMessage(err)
    setAlert({ type: 'error', message: errorMessage })
    dispatch(appRegistrationFetchFail(errorMessage))
  }
}

export const deleteAppRegistration = (id: string, setAlert: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(appRegistrationDeletePending())
    await appRegApi.delete(id)
    dispatch(appRegistrationDeleteSuccess(id))
  } catch (err) {
    const errorMessage = errAsMessage(err)
    setAlert({ type: 'error', message: errorMessage })
    dispatch(appRegistrationDeleteFail(errorMessage))
  }
}
export const createAppRegistration =
  (data: RkvstCreateAppRegistration, setAlert: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(appRegistrationCreatePending())
      const result = await appRegApi.create(data)
      dispatch(appRegistrationCreateSuccess(result))
    } catch (err) {
      const errorMessage = errAsMessage(err)
      setAlert({ type: 'error', message: errorMessage })
      dispatch(appRegistrationCreateFail(errorMessage))
    }
  }
export const updateAppRegistration =
  (id: string | undefined, data: RkvstAppRegistration, setAlert: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(appRegistrationUpdatePending())
      // we are assuming here that id is a string, if it is not
      //   we will get a runtime error and fall into the catch
      id = id as string
      const result = await appRegApi.update(id, data)
      dispatch(appRegistrationUpdateSuccess(result))
    } catch (err) {
      const errorMessage = errAsMessage(err)
      setAlert({ type: 'error', message: errorMessage })
      dispatch(appRegistrationUpdateFail(errorMessage))
    }
  }

export const regenerateAppRegistrationSecret = (clientId: string, setAlert: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(appRegistrationSecretRegenerationPending())
    const result = await appRegApi.regenerateSecret(clientId)
    dispatch(appRegistrationSecretRegenerationSuccess(result))
    return result
  } catch (err) {
    const errorMessage = errAsMessage(err)
    setAlert({ type: 'error', message: errorMessage })
    dispatch(appRegistrationSecretRegenerationFail(errorMessage))
  }
}
