import React from 'react'
import { propTypes } from 'react-bootstrap/esm/Image'

const Img: React.FC<{
  name: string
  alt?: string
  path?: string
  className?: string
  title?: string
  testTag?: string
  extension?: string
}> = ({ name, alt, path = '/icons', className, title, testTag, extension }) => {
  const ext = extension || 'png'
  const base = `/dist/assets${path}/`

  const img1x = `${base}${name}.${ext}`
  const img2x = `${base}${name}@2x.${ext}`

  return <img className={className} src={img1x} srcSet={`${img2x} 2x`} title={title} data-test={testTag} alt={alt} />
}

export default Img
