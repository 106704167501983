import { Whoami } from '@app/state/initialState'
import { identifyUser } from '@app/utils/userStats'
import axios from 'axios'

import { getFeatureFlag } from '../../utils/getFeatureFlag'

const baseURL = '/session/whoami'

export default {
  get() {
    return new Promise<Whoami>((resolve, reject) => {
      axios
        .request({
          baseURL,
        })
        .then((response) => {
          resolve(response.data)

          identifyUser(
            response.data.tenantid,
            `${response.data.principal.issuer}::${response.data.principal.subject}`,
            response.data.principal.email,
            response.data.principal.display_name,
            response.data.roles ? response.data.roles[0] : ''
          )
        })
        .catch((err) => {
          // Nothing to do here - identify() automatically copes with anonymous users
          reject(err)
        })
    })
  },
}
