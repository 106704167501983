import { Reducer } from 'redux'

import { apiAction } from '../actionCreators'
import initialState, { ReduxState, RkvstLocation } from '../initialState'

const replace = (locations: Array<RkvstLocation>, location: RkvstLocation) =>
  locations.map((candidate) => (candidate.identity === location.identity ? location : candidate))

const remove = (locations: Array<RkvstLocation>, idToRemove: string) =>
  locations.filter((candidate) => candidate.identity !== idToRemove)

const reducer: Reducer<ReduxState['locations']> = (state = initialState().locations, action: any) => {
  switch (action.type) {
    case apiAction.fulfilled('api/locations/getList'): {
      return {
        ...state,
        list: action.payload.locations,
      }
    }
    case apiAction.fulfilled('api/locations/getListFiltered'): {
      const newLocations = action.payload.locations
      return {
        ...state,
        filtered: {
          ...state.filtered,
          // if this is a new page, append, otherwise if first page replace
          list: action.meta.nextPageToken ? [...state.filtered.list, ...newLocations] : newLocations,
          nextPageToken: action.payload.next_page_token || null,
        },
      }
    }
    case apiAction.fulfilled('api/locations/create'): {
      return {
        ...state,
        list: [action.payload, ...state.list],
      }
    }
    case apiAction.fulfilled('api/locations/update'): {
      return {
        ...state,
        list: replace(state.list, action.payload),
      }
    }
    case apiAction.fulfilled('api/locations/delete'): {
      return {
        ...state,
        list: remove(state.list, action.meta.id),
      }
    }

    case apiAction.fulfilled('api/locations/getCaps'): {
      const count = action.payload.caps.find(
        (element: { resource_type: string }) => element.resource_type == 'Locations'
      ).resource_remaining

      return {
        ...state,
        capReached: count == '0',
      }
    }

    case apiAction.failed('api/locations/getCaps'): {
      return {
        ...state,
        capReached: false,
      }
    }
  }

  return state
}

export default reducer
