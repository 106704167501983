import { Reducer } from 'redux'

import { apiAction } from '../actionCreators'
import initialState, { ReduxState, RkvstUser } from '../initialState'
import { getLoadingStates } from '../utils/getLoadingStates'

const remove = (data: Array<RkvstUser>, idToRemove: string) => data.filter((item) => item.identity !== idToRemove)

const reducer: Reducer<ReduxState['users']> = (state = initialState().users, action: any) => {
  switch (action.type) {
    /* getList app registrations reducers */
    case apiAction.fulfilled('api/users/getList'): {
      return {
        ...state,
        list: action.payload,
        ...getLoadingStates('fulfilled'),
      }
    }
    case apiAction.pending('api/users/getList'): {
      return {
        ...state,
        ...getLoadingStates('pending'),
      }
    }
    case apiAction.failed('api/users/getList'): {
      return {
        ...state,
        ...getLoadingStates('failed', action.error),
      }
    }

    /* delete app registrations reducers */
    case apiAction.fulfilled('api/users/delete'): {
      return {
        ...state,
        list: remove(state.list, action.payload),
        ...getLoadingStates('fulfilled'),
      }
    }
    case apiAction.pending('api/users/delete'): {
      return {
        ...state,
        ...getLoadingStates('pending'),
      }
    }
    case apiAction.failed('api/users/delete'): {
      return {
        ...state,
        ...getLoadingStates('failed', action.error),
      }
    }

    /* create app registration reducers */
    case apiAction.fulfilled('api/users/create'): {
      return {
        ...state,
        list: [action.payload, ...state.list],
        ...getLoadingStates('fulfilled'),
      }
    }
    case apiAction.pending('api/users/create'): {
      return {
        ...state,
        ...getLoadingStates('pending'),
      }
    }
    case apiAction.failed('api/users/create'): {
      return {
        ...state,
        ...getLoadingStates('failed', action.error),
      }
    }

    /* update app registration reducers */
    case apiAction.fulfilled('api/users/update'): {
      const payloadClientId = action.payload.client_id
      return {
        ...state,
        list: state.list.map((item: RkvstUser) => {
          if (item.client_id !== payloadClientId) return item
          return { ...action.payload }
        }),
        ...getLoadingStates('fulfilled'),
      }
    }
    case apiAction.pending('api/users/update'): {
      return {
        ...state,
        ...getLoadingStates('pending', state),
      }
    }
    case apiAction.failed('api/users/update'): {
      return {
        ...state,
        ...getLoadingStates('failed', action.error),
      }
    }
  }

  return state
}

export default reducer
