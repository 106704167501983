import { Status, Wrapper } from '@googlemaps/react-wrapper'
import debounce from 'lodash/debounce'
import { useEffect, useMemo, useRef, useState } from 'react'
import React from 'react'

import i18n from '../../../i18n'
import { LoadingImage } from '../../Loading'
import { Container, LoadingAssetLabelContainer } from './Map.styles'
import { MapProps, ShapeCoordinates } from './Map.types'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const key: string = process.env.GOOGLE_MAPS_API_KEY

const DEFAULT_CENTER = {
  lat: 0,
  lng: 0,
}

const DEFAULT_ZOOM = 2

export const LoadingMaps = (status: Status) => {
  if (status == Status.FAILURE) {
    return (
      <LoadingAssetLabelContainer>
        <div className="label-text">{i18n.t('globals:error_loading_map')}</div>
        <LoadingImage />
      </LoadingAssetLabelContainer>
    )
  }
  return (
    <LoadingAssetLabelContainer>
      <div className="label-text">{i18n.t('globals:loading')}</div>
      <LoadingImage />
    </LoadingAssetLabelContainer>
  )
}

const getInitialCenter = () => {
  if (sessionStorage.location_map_center) {
    try {
      const center = JSON.parse(sessionStorage.location_map_center)

      return center
    } catch (err) {
      // do nothing
    }
  }
  return DEFAULT_CENTER
}

const getInitialZoom = () => {
  if (sessionStorage.location_map_zoom) {
    try {
      const zoom = JSON.parse(sessionStorage.location_map_zoom)

      return zoom
    } catch (err) {
      // do nothing
    }
  }
  return DEFAULT_ZOOM
}

const Map: React.FC<MapProps> = (props) => {
  const mapPinRef = React.createRef<HTMLDivElement>()
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const [mapElement, setMapElement] = useState<google.maps.Map | undefined>()
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | undefined>()

  const RKVST_MAP_ID = 'rkvstmap'
  const mapStyle = new google.maps.StyledMapType(
    [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#6b6b6b',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd',
          },
        ],
      },
      {
        featureType: 'administrative.neighborhood',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#6b6b6b',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#c0c0c0',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'road',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#f7f7f9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
    ],
    { name: 'Map' }
  )

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center: getInitialCenter(),
      zoom: getInitialZoom(),
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      controlSize: 22,
      clickableIcons: false,
      mapTypeControlOptions: {
        mapTypeIds: [RKVST_MAP_ID, 'satellite', 'terrain'],
      },
    })

    map.mapTypes.set(RKVST_MAP_ID, mapStyle)
    map.setMapTypeId(RKVST_MAP_ID)

    props.menuItems &&
      props.menuItems.forEach((item) => {
        const itemElement = document.getElementById(item.itemId) as HTMLElement
        map.controls[google.maps.ControlPosition[item.position]].setAt(0, itemElement)
      })

    map.addListener(
      'center_changed',
      debounce(() => {
        sessionStorage.location_map_center = JSON.stringify(map.getCenter())
      }, 1000)
    )

    map.addListener(
      'zoom_changed',
      debounce(() => {
        sessionStorage.location_map_zoom = JSON.stringify(map.getZoom())
      }, 1000)
    )

    const info = new google.maps.InfoWindow()
    setInfoWindow(info)

    info.addListener('closeclick', () => {
      setPin(null)
      if (props.onCloseMarker) {
        props.onCloseMarker()
      }
    })

    setMapElement(map)
  }, [])

  const [pin, setPin] = useState<google.maps.Marker | null>(null)

  // Use memot for performance reasons
  useMemo(() => {
    if (mapElement) {
      const mapBounds = new google.maps.LatLngBounds()
      const paths: ShapeCoordinates[] = []

      props.locations.forEach((location) => {
        const pos = { lat: location.latitude, lng: location.longitude }
        const marker = new google.maps.Marker({
          position: pos,
          map: mapElement,
          title: location.display_name,
          icon: '/dist/assets/icons/map-pin.png',
          clickable: true,
        })

        if (props.connectLocations) {
          paths.push({ lat: location.latitude, lng: location.longitude })
        }

        if (props.fitToLocations) {
          mapBounds.extend({
            lat: location.latitude,
            lng: location.longitude,
          })
        }

        if (props.popupCallback && infoWindow) {
          marker.addListener('click', (ev: any) => {
            if (!props.popupCallback) {
              return
            }

            props.popupCallback(location)
            setPin(marker)
          })
        }
      })

      if (props.connectLocations) {
        const movePath = new google.maps.Polyline({
          path: paths,
          geodesic: true,
          strokeColor: '#FF0000',
          strokeOpacity: 1,
          strokeWeight: 2,
        })
        movePath.setMap(mapElement)
      }

      if (props.fitToLocations) {
        mapElement.fitBounds(mapBounds)
      }
    }
  }, [props.locations, mapElement, mapPinRef.current])

  useEffect(() => {
    if (!infoWindow || !mapElement) {
      return
    }

    if (!pin || !props.showPin) {
      infoWindow.close()
      return
    }

    infoWindow.open(mapElement, pin)
    infoWindow.setContent(mapPinRef?.current?.innerHTML)
  }, [infoWindow, pin, props.showPin])

  return (
    <>
      <div ref={mapPinRef} style={{ display: 'none' }}>
        {props.showPin && props.children}
      </div>
      <Container className={props.className} ref={ref} style={props.style}></Container>
    </>
  )
}

const MapComponent: React.FC<MapProps> = (props) => {
  return (
    <Wrapper apiKey={key || ''} render={LoadingMaps} libraries={['places']}>
      <Map center={DEFAULT_CENTER} zoom={DEFAULT_ZOOM} {...props}></Map>
    </Wrapper>
  )
}

export default MapComponent
