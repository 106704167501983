import React from 'react'

import { Modal } from '../../../../common/Modal'
import Img from '../../../../components/Img'
import i18n from '../../../../i18n'
import { modalStyles } from '../../VirusCheckLink.styles'

export type VirusCheckNotScannedModalProps = {
  onClose: any
  onDownload: () => void
}

const VirusCheckNotScannedModalComponent: React.FC<VirusCheckNotScannedModalProps> = ({ ...props }) => (
  <Modal onClose={props.onClose} className="virusScan-modal" css={modalStyles} size="medium">
    <div className="container" data-test="modal-container-virus-check">
      <div className="row">
        <div className="col-12 text-center">
          <h2>{i18n.t('globals:modal.not_scanned_heading')}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <p>{i18n.t('globals:modal.not_scanned_text')}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-right button-action-container">
          <button
            id="download_button"
            className="button-action button-action-download"
            data-test="download_button"
            onClick={props.onDownload}
          >
            <Img name="download-icon" alt="Download" />
            <span className="text-vertical-middle">{i18n.t('globals:modal.download_attachment')}</span>
          </button>
          <button
            id="cancel_download_button"
            data-test="cancel_download_button"
            className="button-action button-action-cancel"
            onClick={props.onClose}
          >
            <span className="text-vertical-middle">{i18n.t('globals:modal.cancel')}</span>
          </button>
        </div>
      </div>
    </div>
  </Modal>
)

const VirusCheckNotScannedModal = VirusCheckNotScannedModalComponent
export default VirusCheckNotScannedModal
