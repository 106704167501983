import { Reducer } from 'redux'

import { apiAction } from '../actionCreators'
import initialState, { ReduxState } from '../initialState'

const reducer: Reducer<ReduxState['currentAssetEvents']> = (state = initialState().currentAssetEvents, action: any) => {
  switch (action.type) {
    case apiAction.fulfilled('api/assets/get'):
    case apiAction.fulfilled('api/publicassets/get'): {
      return {
        ...state,
        asset: action.payload,
      }
    }

    case apiAction.fulfilled('api/assets/getEventList'):
    case apiAction.fulfilled('api/publicassets/getEventList'): {
      // if this is a new page, append, otherwise if first page replace
      const events = action.meta.nextPageToken ? [...state.events, ...action.payload.events] : action.payload.events

      return {
        ...state,
        events,
        nextPageToken: action.payload.next_page_token || null,
      }
    }

    // on create event, if the event is for the current asset,
    // add it to the current asset events list cache so that
    // it appears immediately
    case apiAction.fulfilled('api/assets/createEvent'): {
      if (state.asset && action.meta.assetId !== state.asset.identity) {
        return state
      }

      return {
        ...state,
        events: [action.payload, ...state.events],
      }
    }
  }

  return state
}

export default reducer
