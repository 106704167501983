import 'external-svg-loader'

import { getFeatureFlag } from '@app/utils/getFeatureFlag'
import { ImageCard } from '@ui/components'
import React, { useEffect, useState } from 'react'

import attachments from '../../api/resources/attachments'
import { ScannedStatus } from '../../api/resources/attachments.types'
import { Image, PrimaryFigure, PrimaryFigureCaption, PrimaryFigureCaptionText } from './PrimaryImage.styles'
import { PrimaryImageProps } from './PrimaryImage.types'

export const primaryImageStyle = async (props: PrimaryImageProps) => {
  const noImageUrl = '/dist/assets/no-image.png'
  let imageUrl = ''
  let infoContextPath = null

  // We give priority to event arc_primary_image if both props.asset and props.event have one.
  const { imageIdentity, identity } = attachments.getPrimaryImageIdentity(
    props.event,
    props.asset,
    props.eventFromDocumentAsset
  )
  if (imageIdentity) {
    imageUrl = attachments.getAttachmentDownloadUrl(imageIdentity, identity)
    infoContextPath = attachments.getAttachmentDownloadResourcePath(imageIdentity, identity)[1]
  }

  if (infoContextPath) {
    try {
      const info: any = await attachments.getOneInfo(infoContextPath)

      if (attachments.imageMimeType(info?.mime_type)) {
        // only hide image when scanned status is SCANNED_BAD
        return {
          backgroundImage: info.scanned_status === ScannedStatus.SCANNED_BAD ? noImageUrl : imageUrl,
          isSvg: info.mime_type.includes('svg'),
        }
      }
    } catch (e) {
      props.setAlert && props.setAlert({ type: 'error', message: e.toString() })
    }
  }

  return {
    backgroundImage: noImageUrl,
    isSvg: false,
  }
}

const PrimaryImageComponent: React.FC<PrimaryImageProps> = (props) => {
  const [primaryImage, SetPrimaryImage] = useState<{
    backgroundImage: string
    isSvg: boolean
  }>({ backgroundImage: `/dist/assets/no-image.png`, isSvg: false })
  const [assetInfo, SetAssetInfo] = useState<string>('')

  useEffect(() => {
    primaryImageStyle(props).then((ps) => {
      SetPrimaryImage(ps)
    })
    SetAssetInfo(
      (() => {
        if (props.asset) {
          const displayName = props.asset?.attributes?.arc_display_name as string
          return displayName?.length > 0 ? displayName : props.asset.identity.split('assets/')[1]
        }
        return ''
      })()
    )
  }, [props.asset, props.event])

  // TODO: The new design system opts for a simple carded design without captions. Once this is
  // merged,  this component can be further simplified. It also makes API calls without any kind of
  // caching or proper state management - a further area for improvement.
  if (!getFeatureFlag('LEGACY_ASSETS')) {
    // XXX: Workaround to get desired behaviour for event sidebar until we can refactor this component.
    if (
      props.disablePlaceholder &&
      (!primaryImage.backgroundImage || primaryImage.backgroundImage === '/dist/assets/no-image.png')
    ) {
      return <></>
    }

    return (
      <ImageCard height="300px" image={primaryImage.backgroundImage} className="primary-img" data-test="asset-img" />
    )
  }

  return (
    <PrimaryFigure>
      <Image src={primaryImage.backgroundImage} className="primary-img" data-test="asset-img" />

      {(props.qrcallback || props.showCaption) && (
        <PrimaryFigureCaption>
          <strong>{props.asset?.attributes?.arc_display_type}:</strong>
          <PrimaryFigureCaptionText title={assetInfo}>{assetInfo}</PrimaryFigureCaptionText>
        </PrimaryFigureCaption>
      )}
    </PrimaryFigure>
  )
}

const PrimaryImage = PrimaryImageComponent

export default PrimaryImage
