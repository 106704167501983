import { darken, lighten } from '../../utils/lighten-darken-color'
import { themeTypes } from './Theme.types'

const primary = 'rgba(33,44,66,1)'
const secondary = 'rgba(136,204,20,1)'
const grey = 'rgba(199,199,199,1)'
const danger = 'rgba(162,6,6,1)'

export const theme: themeTypes = {
  colors: {
    primary: {
      main: primary,
      dark: darken(primary, 0.3),
      light: lighten(primary, 0.2),
      lighter: lighten(primary, 0.9),
    },
    secondary: {
      main: secondary,
      dark: darken(secondary, 0.2),
      light: lighten(secondary, 0.2),
      lighter: lighten(secondary, 0.9),
    },
    border: {
      main: lighten(grey, 0.2),
      light: lighten(grey, 0),
    },
    text: {
      main: darken(grey, 0.8),
      light: darken(grey, 0.4),
      highlight: 'rgba(255, 255, 255, 1)',
    },
    success: secondary,
    error: {
      main: lighten(danger, 0.8),
      light: lighten(danger, 0.98),
      dark: lighten(danger, 0.3),
    },
    background: {
      light: lighten(grey, 0.8),
      dark: darken(primary, 0.4),
    },
    black: 'rgba(11,11,11,1)',
    white: 'rgba(255,255,255,1)',
    grey: {
      main: grey,
      100: lighten(grey, 0.8),
      200: lighten(grey, 0.6),
      300: lighten(grey, 0.4),
      400: lighten(grey, 0.2),
      500: darken(grey, 0.1),
      600: darken(grey, 0.2),
      700: darken(grey, 0.4),
      800: darken(grey, 0.6),
      900: darken(grey, 0.8),
    },
  },
  fonts: {
    family: ['Ubuntu', 'sans-serif'],
    sizes: {
      reset: '10px',
      title: '4rem',
      subTitle: '3.2rem',
      default: '1.6rem',
      small: '1.4rem',
    },
  },
  gutter: {
    small: '1.5rem',
    big: '3rem',
  },
  radius: {
    small: '.4rem',
    big: '1rem',
  },

  sizes: {
    sm: '320px',
    md: '800px',
    lg: '1024px',
    xl: '1500px',
    xxl: '2300px',
  },
  boxShadow: `0 3px 7px 1px ${lighten(primary, 0.7)}`,
}
