export const escapeValue = (value?: string | OpenInterface | null) => {
  if (value) {
    if (typeof value === "string" || value instanceof String) {
      return value;
    } else {
      return JSON.stringify(value);
    }
  }

  return "";
};

export const escapeRegExp = (text: string) => {
  // https://esdiscuss.org/topic/regexp-escape
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};
