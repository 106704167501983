import { useState } from 'react'

import { default as api } from '../../../../../../api'
import { useAppDispatch } from '../../../../../../hooks'
import actionCreators from '../../../../../../state/actionCreators'

const useGetAttachments = () => {
  const [totalFileCount, setTotalFileCount] = useState<number>(0)
  const [currentFileCount, setCurrentFileCount] = useState<number>(0)
  const [fileTypeName, setFileTypeName] = useState<string>('')
  const [filename, setFilename] = useState<string>('')
  const [progress, setProgress] = useState<number>(0)

  const dispatch = useAppDispatch()
  const controllers: any[] = []

  const getAttachments = async (attachments: { type?: string; key: string; file: any }[]) => {
    if (!attachments.length) {
      return []
    }
    const result = []
    let count = 0
    setCurrentFileCount(0)
    setTotalFileCount(attachments.length)

    let errorFound = false
    loop: for (const attachment of attachments) {
      if (attachment) {
        count += 1
        setCurrentFileCount(count)
        setFileTypeName(attachment.file?.type)
        setFilename(attachment.file.name)

        // loop through uploading each file. showing progress
        if (attachment.file?.size > 0) {
          const data = new FormData()
          data.append('file', attachment.file)

          // updates the progress of the current attachment upload
          const progressEvent = (progressEvent: any) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setProgress(progress)
          }

          // setup controllers and its signals to cancel
          // upload if needed,
          controllers.push(new AbortController())
          const config = {
            signal: controllers[controllers.length - 1].signal,
            onUploadProgress: progressEvent,
          }

          let attachmentResponse: any = null
          try {
            attachmentResponse = await api.blobs.upload(data, config)

            result.push({
              attachment_key: attachment.key.toLowerCase() || attachment.file.name.toLowerCase(),
              type: attachment.type,
              arc_blob_identity: attachmentResponse.identity,
              arc_blob_hash_alg: attachmentResponse.hash.alg,
              arc_blob_hash_value: attachmentResponse.hash.value,
              arc_file_name: attachment.file.name,
              mime_type: attachmentResponse.mime_type,
            })
          } catch (error) {
            errorFound = true
            // break out of the loop if error found
            break loop
          }
        }
      }
    }

    // set caps in local state for uploaded attachments (blobs)
    dispatch(actionCreators.blobs.getCaps())

    return {
      errorFound,
      result,
      controllers: controllers,
    }
  }

  // once the getAttachments is executed in the component
  // all other passed back internal states will be updated
  // with the process of uploading each attachment
  return {
    getAttachments,
    totalFileCount,
    currentFileCount,
    fileTypeName,
    filename,
    progress,
  }
}

export default useGetAttachments
