import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'

import attachments from '../../api/resources/attachments'
import { ScannedInfo, ScannedStatus } from '../../api/resources/attachments.types'
import i18n from '../../i18n'
import { VirusCheckBadScanModal } from './components/VirusCheckBadScanModal'
import { VirusCheckNotScannedModal } from './components/VirusCheckNotScannedModal'
import { DownloadBadge, DownloadBadgeText, DownloadLink } from './VirusCheckLink.styles'
import { AttachmentDownloadDetailType, VirusCheckLinkComponentProps } from './VirusCheckLink.types'

const attachmentBlobID = (attachment: any) => {
  return attachment.arc_attachment_identity ? attachment.arc_attachment_identity : attachment.arc_blob_identity
}

const VirusCheckLinkComponent: React.FC<VirusCheckLinkComponentProps> = (props: VirusCheckLinkComponentProps) => {
  const [scannedModal, setScannedModal] = useState<ScannedStatus | null>(null)
  const [scannedInfo, setScannedInfo] = useState<ScannedInfo | null>(null)
  const [downloadDetail, setDownloadDetail] = useState<AttachmentDownloadDetailType | null>(null)
  const { attachment, owningResourceIdentity, name } = props
  const isTextLink = !!owningResourceIdentity
  const urlLink = attachments.getAttachmentDownloadUrl(attachmentBlobID(attachment), owningResourceIdentity)

  const getAttachmentDownloadDetail = (info: ScannedInfo): AttachmentDownloadDetailType => {
    let imageUrl,
      titleText = ''

    // if it's a virus we always show the virus thumb
    // and tooltip indicating it's infected
    if (info.scannedStatus === ScannedStatus.SCANNED_BAD) {
      imageUrl = '/dist/assets/image-virus.png'
      titleText = i18n.t('globals:title.scanned_file_infected')
    } else {
      // if it's not a virus we show appropriate icon
      // and then the click handler potentially pops prompt for not scanned items
      if (attachment.mime_type === 'application/pdf') {
        imageUrl = '/dist/assets/icons/attachment-icon-pdf.svg'
        titleText = i18n.t('globals:title.click_to_download_pdf_file')
      } else if (attachment.mime_type?.includes('image/')) {
        imageUrl = attachments.getAttachmentDownloadUrl(attachmentBlobID(attachment))
        titleText = i18n.t('globals:title.click_to_download_file')
      } else {
        imageUrl = '/dist/assets/icons/attachment-icon-default.svg'
        titleText = i18n.t('globals:title.click_to_download_file')
      }
    }

    return {
      href: urlLink,
      backgroundImage: `url(${imageUrl})`,
      titleText,
    }
  }

  const downloadStatusCheckHandler = (event: any) => {
    event.persist()
    if (scannedInfo?.scannedStatus !== ScannedStatus.SCANNED_OK) {
      if (scannedInfo?.scannedStatus === ScannedStatus.SCANNED_BAD) {
        setScannedModal(ScannedStatus.SCANNED_BAD)
      }
      if (scannedInfo?.scannedStatus === ScannedStatus.NOT_SCANNED) {
        setScannedModal(ScannedStatus.NOT_SCANNED)
      }
    } else {
      window.open(`${downloadDetail?.href}`, '_blank')
    }
  }

  const onCloseHandler = () => setScannedModal(null)

  const onDownloadHandler = () => {
    setScannedModal(null)
    const fileUrl =
      scannedInfo?.scannedStatus === ScannedStatus.SCANNED_BAD
        ? `${downloadDetail?.href}?allow_insecure=true`
        : `${downloadDetail?.href}`
    window.open(fileUrl, '_blank')
  }

  useEffect(() => {
    ;(async () => {
      const info = await attachments.getAttachmentDownloadStatus(urlLink)
      setDownloadDetail(getAttachmentDownloadDetail(info))
      setScannedInfo(info)
    })()
  }, [attachment])

  if (downloadDetail) {
    return (
      <>
        {isTextLink ? (
          <DownloadLink
            title={attachment.arc_file_name}
            data-test={`attachmentlink_${props.index}`}
            className={'clean-link ' + props.className}
            onClick={downloadStatusCheckHandler}
          >
            <DownloadBadge>
              <ReactSVG src={`/dist/assets/icons/download-icon.svg`} />
              {!props.withoutFileName && <DownloadBadgeText>{name || attachment.arc_file_name}</DownloadBadgeText>}
            </DownloadBadge>
          </DownloadLink>
        ) : (
          <DownloadLink
            data-test={`attachmentlink_${props.index}`}
            onClick={downloadStatusCheckHandler}
            className={props.className}
          >
            <div
              title={downloadDetail.titleText}
              className="item image"
              style={{ backgroundImage: downloadDetail.backgroundImage }}
            />
          </DownloadLink>
        )}
        {scannedModal === ScannedStatus.NOT_SCANNED && (
          <VirusCheckNotScannedModal onClose={onCloseHandler} onDownload={onDownloadHandler} />
        )}
        {scannedModal === ScannedStatus.SCANNED_BAD && (
          <VirusCheckBadScanModal onClose={onCloseHandler} onDownload={onDownloadHandler} />
        )}
      </>
    )
  } else {
    return null
  }
}

const VirusCheckLink = VirusCheckLinkComponent
export default VirusCheckLink
