import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './translations/en.json'

// prettier-ignore
i18n
  .use(initReactI18next)
  .init({
    returnNull: false,
    // we init with resources
    resources: {
      // where are the translations?
      // The translations has been moved to en.json. However you shouldn't have to mantain them
      // as en.json is automatically generated. You just need to run "npm run i18n:en" and this
      // will analyze the source base of this repo and automaitcally generate the en.json file
      // with them.
      // For transition purposes and also to give the option as a last resort, there is also a
      // src/transaltions/base.js with the pervious translations.
      // The autogeneration script will merge whatever is in base.js with what it will finnd in the
      // source code
      //
      // Please fo not that to run this utilities you need to build @ui/cli first. You can do this
      // by running `npm ci` inside the ui folder
      en,
      ger: {
        translations: {}
      },
      fre: {
        translations: {}
      }
    },
    lng: localStorage.datatrails_display_lang ? localStorage.datatrails_display_lang : "en",
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations", "compliance"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },

    react: {
      wait: true
    }
  })

export default i18n
