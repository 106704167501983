import { ReduxState } from '@app/state/initialState'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import pagesConfiguration from './configuration.json'
import { ActionConfig, JsonConfig } from './configuration.types'

export const initialState = pagesConfiguration

/**
 * This slice is for keeping small state key - val related to the visualization
 * of the page that needs to be kept globally. For isntance if we want to keep in memory
 * the state of particular panels, tabs, etc... if they are open, minimized, etc...
 *
 * The state is configuret from configuration.json used in this page. Adding a key there
 * it will make it appear in the ts definition and in the store. The value for the key in the
 * .json file is the default value that it will have.
 *
 * This json file is separated by category because ensuring that the correct type is
 * handled in every operation, e.g. selected tab should be a number
 *
 * If you want to add a new category you will have to add the reducer and potentically
 * another hook for it, otherwise adding a new variable in an exiting category won't
 * require to add extra things
 **/
const assetListSlice = createSlice({
  name: 'pagesConfiguration',
  initialState,
  // Adding a new category requires to add a new reducer here. The reducers might be quite
  // simple and will just require to add the new catehory key and the type been used
  // see exiting reducers to have an example
  reducers: {
    // For keeping track of open state of panels, modals, etc...
    open: (state, action: PayloadAction<ActionConfig<JsonConfig['open'], boolean>>) => {
      state.open[action.payload.key] = action.payload.val
    },
    // For keeping track of tabs selection
    tab: (state, action: PayloadAction<ActionConfig<JsonConfig['tab'], number>>) => {
      state.open[action.payload.key] = action.payload.val
    },
  },
})

export const selector = (state: ReduxState) => state.pagesConfiguration
export const actions = assetListSlice.actions
export default assetListSlice.reducer

import { useAppDispatch } from '@app/hooks'
import { useSelector } from 'react-redux'

// HOOKS
/**
 * This are hooks to help on using this configuration
 *
 * @usage
 *
 * import { useOpen } from '@app/pages/configuration.slice'
 *
 * const MyComponent = () => {
 *  const { open, setOpen } = useOpen('my:config:key')
 *
 *  return (
 *    <section>
 *      <button onClick={() => setOpen(!open)}>toggle secret</button>
 *      {open && <p>Is a secret! don't read it!!</p>}
 *    </section>
 *  )
 * }
 *
 * and also the configuration.json needs to have
 *
 * {
 *  "open": { "my:config:key": true }
 * }
 **/
export const useOpen = (key: keyof JsonConfig['open']) => {
  const open = useSelector((state: ReduxState) => state.pagesConfiguration.open[key])
  const dispatch = useAppDispatch()
  const setOpen = (val: boolean) => {
    dispatch(actions.open({ key, val }))
  }

  return { setOpen, open }
}

export const useTab = (key: keyof JsonConfig['tab']) => {
  const tab = useSelector((state: ReduxState) => state.pagesConfiguration.open[key])
  const dispatch = useAppDispatch()
  const setTab = (val: number) => {
    dispatch(actions.tab({ key, val }))
  }

  return { setTab, tab }
}
