import styled, { css } from 'styled-components'

export const Container = styled.div<{ css?: any }>``

export const DownloadLink = styled.span`
  &.clean-link {
    svg {
      width: 16pt;
      height: 16pt;
      margin-right: 12px;
    }
  }
`

export const DownloadBadge = styled.span`
  color: rgb(11, 74, 111);
  font-weight: 500;
  border-radius: 12px;
  background-color: rgb(224, 242, 254);
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  vertical-align: middle;
  border: medium none;
  text-align: center;
  height: 36px;
  padding: 12px;
  max-width: 250px;
  cursor: pointer;
`

export const DownloadBadgeText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const modalStyles = css`
  &.virusScan-modal {
    h3 {
      margin-bottom: 40px;
    }
    p {
      height: 55px;
      margin-bottom: 50px;
      word-break: break-word;
    }
    .button-action-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .button-action-download {
      background: linear-gradient(rgb(6, 169, 232), rgb(0, 137, 200));
      margin-right: 10px;
    }
  }
`
