import AssetForm from '@app/components/Assets/AssetForm'
import AssetFormModal from '@app/components/Assets/AssetFormModal'
import { useAppSelector } from '@app/hooks'
import useAlerts from '@app/hooks/useAlerts'
import { useLocations } from '@app/hooks/useLocation'
import { mapArrayById } from '@app/mapArrayById'
import { queryClient } from '@app/state/queryClient'
import { trackEvent } from '@app/utils/userStats'
import {
  CopyIcon,
  EllipsedLabel,
  PackageIcon,
  PlusIcon,
  SearchPlaceHolder,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from '@ui/components'
import { SectionBodyWrapper } from '@ui/components'
import { RkvstAsset } from '@ui/types'
import { copyToClipboard, getAssetName, stripAnyAssetResourceFromId } from '@ui/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOpen } from '../../../configuration.slice'
import { useAssetList } from '../../hooks/useAssetList'
import { CreationButton, FilterTypeaheadCategories } from './AssetList.styles'
import { InfiniteScroll, SectionTableStyle, StyledForm } from './AssetList.styles'
import { Filter, FilteredContainer } from './AssetList.types'
import { useCategories } from './useCategories'

export const AssetSubsection = (props: FilteredContainer) => {
  const { open, setOpen } = useOpen('asset:list:subsection:asset')

  const { t } = useTranslation()
  const [filter, setFilter] = useState<Filter>({})
  const { categories, renderCategory } = useCategories(
    [
      { name: 'displayName', title: t('Name') },
      { name: 'type', title: t('Type') },
      { name: 'assetId', title: t('Asset ID') },
      { name: 'location', title: t('Location') },
      { name: 'attestation' },
    ],
    filter,
    setFilter
  )

  const [showAddAsset, setShowAddAsset] = useState(false)
  const [refresh, setRefresh] = useState(new Date().getTime())

  useEffect(() => {
    setRefresh(new Date().getTime())
  }, [filter])

  return (
    <>
      <SectionBodyWrapper
        title={
          <>
            <PackageIcon />
            <span className="assets-header subsection-title" data-test="assets-subsection">
              {t('Custom Assets')}
            </span>
          </>
        }
        actions={
          <>
            <StyledForm action="#" autoComplete="off">
              <FilterTypeaheadCategories
                className="typeahead"
                initialCateogry={0}
                categories={categories}
                testTag="filter-assets"
                sizeCategory="150px"
                placeholder={t('Select filter')}
                placeholderContent={<SearchPlaceHolder />}
                renderCategory={renderCategory}
                onSelect={() => {
                  setFilter({})
                }}
              />
            </StyledForm>
            {/*
          <ButtonGroup>
            <Button variant="secondary-grey" title={t('grid view')}>
              <LayoutGridIcon />
            </Button>
            <Button variant="secondary-grey" title={t('layout view')}>
              <DotListIcon />
            </Button>
          </ButtonGroup>
            */}
            <CreationButton
              className="creation-btn-primary"
              testTag="add_asset_button"
              onClick={() => {
                trackEvent('RegisterAsset', { SourcePage: 'Assets' })
                setShowAddAsset(true)
              }}
              variant="primary"
              title={t('register asset')}
            >
              <PlusIcon />
              {t('Register asset')}
            </CreationButton>
          </>
        }
        open={open}
        setOpen={setOpen}
      >
        <AssetTable filter={filter} setFilter={setFilter} refresh={refresh} />
      </SectionBodyWrapper>
      <AddAssetModal
        show={showAddAsset}
        setShow={(open: boolean) => {
          setShowAddAsset(open)
          if (!open) {
            setRefresh(new Date().getTime())
          }
        }}
      />
    </>
  )
}

interface AddAssetModalProps {
  show: boolean
  setShow(visible: boolean): void
  onSubmit?(): void
}

export const AddAssetModal = (props: AddAssetModalProps) => {
  const { t } = useTranslation()
  const { data: locations } = useLocations()

  if (props.show) {
    return (
      <AssetFormModal
        onClose={() => {
          props.setShow(false)
        }}
        cappable={true}
        title={t('assets:modal.create_asset')}
        Form={
          <AssetForm
            testTag="asset-reg-form"
            locations={locations}
            onSubmit={() => {
              props.onSubmit && props.onSubmit()

              queryClient.clear()
            }}
            onCreateError={(err: Error) => console.log(err)}
            onClose={() => {
              props.setShow(false)
            }}
          />
        }
      />
    )
  }

  return null
}

// FIXME: i18n scripts seems to not work well with i18n: namespace
const getLocationText = (locationId?: string, locationsById?: any) => {
  if (!locationId) {
    return 'No location'
  } else if (!locationsById) {
    return 'Unknown'
  } else {
    const location = locationsById[locationId]

    if (location) {
      return location.display_name
    } else {
      return 'Location Unresolved'
    }
  }
}

const AssetTable = (props: FilteredContainer & { refresh?: string | number }) => {
  const [token, setToken] = useState<string | undefined>()
  const [filter, setFilter] = useState<Filter>({
    ...props.filter,
    page: 1,
    page_size: 50,
    document: false,
    dropbox: false,
  })
  const { loading, refetch, isError, error, data: assets, nextToken } = useAssetList(filter, token, props.refresh)
  const { t } = useTranslation()

  const { setAlert } = useAlerts()
  const copy = (asset: RkvstAsset) => {
    copyToClipboard(asset?.identity || '').then(() => {
      setAlert({ type: 'success', message: t('share:Copied', { subject: asset.identity }).toString(), timeout: 2000 })
    })
  }

  const locations = useAppSelector((state) => state.locations.list)
  const locationsById = mapArrayById(locations || [], 'identity')

  useEffect(() => {
    setToken(undefined)
    setFilter({
      ...props.filter,
      page: 1,
      page_size: 50,
      document: false,
      dropbox: false,
    })
  }, [props.filter])

  useEffect(() => {
    setToken(undefined)
  }, [props.refresh])

  return (
    <InfiniteScroll<RkvstAsset>
      rows={assets || []}
      filter={filter}
      loading={loading}
      isError={isError}
      error={error}
      refetch={refetch}
      refresh={props.refresh}
      nextToken={nextToken || ''}
      increasePage={(token: string) => setToken(token || '')}
      table={({ rows }) => (
        <SectionTableStyle data-test="asset-table">
          <thead>
            <StyledTableRow className="portrait-header">
              <StyledTableHeader size="25%">{t('Asset Name')}</StyledTableHeader>
              <StyledTableHeader>{t('Type')}</StyledTableHeader>
              <StyledTableHeader>{t('Asset ID')}</StyledTableHeader>
              <StyledTableHeader>{t('Locations')}</StyledTableHeader>
              <StyledTableHeader>{t('Visibility')}</StyledTableHeader>
            </StyledTableRow>
          </thead>

          {!isError && !!rows?.length && (
            <tbody>
              {rows.map((asset, id) => (
                <StyledTableRow
                  key={id + ':' + asset.identity}
                  title={t('view asset {{name}}', { name: getAssetName(asset) })}
                  onClick={(ev: any) => {
                    if (ev.button == 0) {
                      if (ev.ctrlKey || ev.metaKey) {
                        window.open(`/${asset.identity}`, '_blank')
                      } else {
                        window.open(`/${asset.identity}`, '_self')
                      }
                    } else if (ev.button == 1) {
                      window.open(`/${asset.identity}`, '_blank')
                    }
                    ev.stopPropagation()
                  }}
                >
                  <StyledTableCell size="25%" data-content={t('Asset Name')}>
                    {getAssetName(asset)}
                  </StyledTableCell>
                  <StyledTableCell data-content={t('Type')}>
                    {asset.attributes?.arc_display_type?.toString()}
                  </StyledTableCell>

                  <StyledTableCell data-content={t('Asset ID')} onClick={(ev) => ev.stopPropagation()}>
                    <div className="cell-action">
                      <EllipsedLabel size="8em">{stripAnyAssetResourceFromId(asset.identity)}</EllipsedLabel>
                      <CopyIcon
                        className="clickable"
                        title={t('Copy Asset ID to clipboad')}
                        onClick={() => copy(asset)}
                      />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell data-content={t('Locations')}>
                    {t(getLocationText(asset.attributes?.arc_home_location_identity?.toString(), locationsById))}
                  </StyledTableCell>
                  <StyledTableCell data-content={t('Visibility')}>
                    {asset.public ? t('Public') : t('Private')}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </tbody>
          )}
        </SectionTableStyle>
      )}
    />
  )
}
