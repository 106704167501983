import styled from 'styled-components'

export const ScrollWrapper = styled.div`
  overflow-y: scroll;
  position: relative;
  display: block;

  .no-results,
  .load-more {
    width: 100%;
    padding: 16px;
    text-align: center;
    button {
      width: min-content;
      margin: auto;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }

  table {
    width: 100%;
  }
  tbody {
    opacity: 1;
  }
  &.loading table tbody {
    animation: fadeIn 1.5s infinite alternate;
  }
`

// Setction tables has some specialy negative padding. this is
// for wrapping an alternative content to be placed inside
// the containe of the table, instead of the table itself.
// e.g. error handling, etc...
export const SectionNonTableBody = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  .alert {
    margin-top: 16px;
    a {
      color: inherit;
    }
  }
`
