import { Reducer } from 'redux'

import { apiAction } from '../actionCreators'
import initialState, { ReduxState, RkvstAppRegistration } from '../initialState'
import { getLoadingStates } from '../utils/getLoadingStates'

const remove = (data: Array<RkvstAppRegistration>, idToRemove: string) =>
  data.filter((item) => item.identity !== idToRemove)

const reducer: Reducer<ReduxState['appRegistrations']> = (state = initialState().appRegistrations, action: any) => {
  switch (action.type) {
    /* getList app registrations reducers */
    case apiAction.fulfilled('api/applications/getList'): {
      return {
        ...state,
        list: action.payload.applications,
        ...getLoadingStates('fulfilled'),
      }
    }
    case apiAction.pending('api/applications/getList'): {
      return {
        ...state,
        ...getLoadingStates('pending'),
      }
    }
    case apiAction.failed('api/applications/getList'): {
      return {
        ...state,
        ...getLoadingStates('failed', action.error),
      }
    }

    /* delete app registrations reducers */
    case apiAction.fulfilled('api/applications/delete'): {
      return {
        ...state,
        list: remove(state.list, action.payload),
        ...getLoadingStates('fulfilled'),
      }
    }
    case apiAction.pending('api/applications/delete'): {
      return {
        ...state,
        ...getLoadingStates('pending'),
      }
    }
    case apiAction.failed('api/applications/delete'): {
      return {
        ...state,
        ...getLoadingStates('failed', action.error),
      }
    }

    /* create app registration reducers */
    case apiAction.fulfilled('api/applications/create'): {
      return {
        ...state,
        list: [action.payload as RkvstAppRegistration, ...state.list],
        ...getLoadingStates('fulfilled'),
      }
    }
    case apiAction.pending('api/applications/create'): {
      return {
        ...state,
        ...getLoadingStates('pending'),
      }
    }
    case apiAction.failed('api/applications/create'): {
      return {
        ...state,
        ...getLoadingStates('failed', action.error),
      }
    }

    /* update app registration reducers */
    case apiAction.fulfilled('api/applications/update'): {
      const payloadClientId = action.payload.client_id
      return {
        ...state,
        list: state.list.map((item: RkvstAppRegistration) => {
          if (item.client_id !== payloadClientId) return item
          return { ...(action.payload as RkvstAppRegistration) }
        }),
        ...getLoadingStates('fulfilled'),
      }
    }
    case apiAction.pending('api/applications/update'): {
      return {
        ...state,
        ...getLoadingStates('pending', state),
      }
    }
    case apiAction.failed('api/applications/update'): {
      return {
        ...state,
        ...getLoadingStates('failed', action.error),
      }
    }

    /* get secret registration reducers */
    case apiAction.fulfilled('api/applications/regenerateSecret'): {
      const payloadClientId = action.payload.client_id
      return {
        ...state,
        list: state.list.map((item: RkvstAppRegistration) => {
          if (item.client_id !== payloadClientId) return item
          return { ...action.payload }
        }),
        ...getLoadingStates('fulfilled'),
      }
    }
    case apiAction.pending('api/applications/regenerateSecret'): {
      return {
        ...state,
        ...getLoadingStates('pending'),
      }
    }
    case apiAction.failed('api/applications/regenerateSecret'): {
      return {
        ...state,
        ...getLoadingStates('failed', action.error),
      }
    }
  }

  return state
}

export default reducer
