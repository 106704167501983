export class Model<T = any> {
  public data: T | null = null
  public setData(data: T) {
    this.data = data
    return this
  }
  public getData(): T | null {
    return this.data
  }
  public cloneData(): T | null {
    if (this.data !== null) {
      return JSON.parse(JSON.stringify(this.data))
    } else {
      return null
    }
  }

  /**
   * estandard dictionaries like attributes do not accept keys with certain characters
   * e.g. a "." on it
   * this method clears this character from a key
   */
  public escapeKey(key: string) {
    return key.replace(/\./g, '_')
  }
}
