import api from '@app/api'
import { EventsFilterParams } from '@app/api/resources/events'
import { useQuery } from '@tanstack/react-query'
import { RkvstEvent } from '@ui/types'
const PQueue = require('p-queue')

const queue = new PQueue.default({ concurrency: 7 })

export interface EventsFilter extends EventsFilterParams {}

export const useBatchEventsListCount = (filters: EventsFilter[], timestampForce?: any) => {
  const queryKey: string[] = timestampForce
    ? ['batchEventsListCount', timestampForce?.toString() || '']
    : ['batchEventsListCount', JSON.stringify(filters || '{}')]

  const result = useQuery<number[]>({
    queryKey,
    queryFn: async () => {
      const config: any = { timeout: 5000, headers: { 'x-request-total-count': 'true' } }
      const timeLine = new Array(filters.length).fill(0)

      for (let i = 0; i < filters.length; i += 1) {
        queue.add(async () => {
          const data = await api.events.getListFiltered({ ...filters[i], ...{ pageSize: '1' } }, '', undefined, config)

          const totalCount = api.events.getTotalCount()
          timeLine[i] = totalCount
        })
      }

      await queue.onIdle()

      return timeLine
    },
    // cacheTime: 0,
    keepPreviousData: true,
  } as any)

  const loading = result.isLoading || result.isFetching || result.isRefetching
  const errorStatus = (result?.error as any)?.request?.status
  const isAllowedError = [400, 404].indexOf(errorStatus) !== -1
  const isCorrectResult = !result.isError || isAllowedError

  return {
    ...result,
    loading,
    data: !isCorrectResult ? 0 : result.data,
    nextToken: undefined,
    error: isCorrectResult ? null : result.error,
    isError: result.isError && !isAllowedError,
  }
}

export const useEventsListCount = (filter: EventsFilter, timestampForce?: any) => {
  const queryKey: string[] = timestampForce
    ? ['eventsListCount', timestampForce?.toString() || '']
    : ['eventsListCount', JSON.stringify(filter || '{}')]
  const result = useQuery<number>({
    queryKey,
    queryFn: async () => {
      const config: any = { timeout: 5000, headers: { 'x-request-total-count': 'true' } }
      const data = await api.events.getListFiltered({ ...filter, ...{ pageSize: '1' } }, '', undefined, config)

      const totalCount = api.events.getTotalCount()
      return totalCount
    },
    // cacheTime: 0,
    keepPreviousData: true,
  } as any)

  const loading = result.isLoading || result.isFetching || result.isRefetching
  const errorStatus = (result?.error as any)?.request?.status
  const isAllowedError = [400, 404].indexOf(errorStatus) !== -1
  const isCorrectResult = !result.isError || isAllowedError

  return {
    ...result,
    loading,
    data: !isCorrectResult ? 0 : result.data,
    nextToken: undefined,
    error: isCorrectResult ? null : result.error,
    isError: result.isError && !isAllowedError,
  }
}

export const useEventsList = (filter: EventsFilter, nextToken?: string, timestampForce?: any) => {
  const result = useQuery<{ events: RkvstEvent[]; total: number; next_page_token?: string }>({
    queryKey: timestampForce
      ? ['eventsList', timestampForce?.toString(), nextToken]
      : ['eventsList', JSON.stringify(filter), nextToken],
    queryFn: async () => {
      const config: any = { timeout: 5000 }
      return await api.events.getListFiltered(filter, nextToken, undefined, config)
    },
    // cacheTime: 0,
    keepPreviousData: true,
  } as any)

  const loading = result.isLoading || result.isFetching || result.isRefetching
  const errorStatus = (result?.error as any)?.request?.status
  const isAllowedError = [400, 404].indexOf(errorStatus) !== -1
  const isCorrectResult = !result.isError || isAllowedError

  return {
    ...result,
    loading,
    data: !isCorrectResult ? [] : result.data?.events,
    nextToken: !isCorrectResult ? undefined : result.data?.next_page_token,
    error: isCorrectResult ? null : result.error,
    isError: result.isError && !isAllowedError,
  }
}
