import { VirusCheckLink } from '@app/common/VirusCheckLink'
import DocumentForm from '@app/components/Assets/AssetForm/DocumentForm'
import AssetFormModal from '@app/components/Assets/AssetFormModal'
import useAlerts from '@app/hooks/useAlerts'
import { useAssetList } from '@app/pages/Asset/hooks/useAssetList'
import { queryClient } from '@app/state/queryClient'
import { trackEvent } from '@app/utils/userStats'
import {
  CopyIcon,
  DocumentFileIcon,
  EllipsedLabel,
  PlusIcon,
  SearchPlaceHolder,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from '@ui/components'
import { SectionBodyWrapper } from '@ui/components'
import { RkvstAsset } from '@ui/types'
import { copyToClipboard, getAssetName, stripAnyAssetResourceFromId } from '@ui/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOpen } from '../../../configuration.slice'
import { CreationButton, FilterTypeaheadCategories } from './AssetList.styles'
import { InfiniteScroll, SectionTableStyle, StyledForm } from './AssetList.styles'
import { Filter, FilteredContainer } from './AssetList.types'
import { useCategories } from './useCategories'

export const DocumentSubsection = (props: FilteredContainer) => {
  const { open, setOpen } = useOpen('asset:list:subsection:document')

  const { t } = useTranslation()
  const [showAddDocument, setShowAddDocument] = useState(false)
  const [filter, setFilter] = useState<Filter>({})
  const { categories, renderCategory } = useCategories(
    [
      { name: 'displayName', title: t('Name') },
      { name: 'type', title: t('Type') },
      { name: 'assetId', title: t('Asset ID') },
      { name: 'version', title: t('Version') },
      { name: 'attestation' },
      //{name: 'file', title: t('Attachment file').toString()},
    ],
    filter,
    setFilter
  )

  const [refresh, setRefresh] = useState(new Date().getTime())

  useEffect(() => {
    setRefresh(new Date().getTime())
  }, [JSON.stringify(filter)])

  return (
    <>
      <SectionBodyWrapper
        title={
          <>
            <DocumentFileIcon />
            <span className="subsection-title" data-test="documents-subsection">
              {t('Documents')}
            </span>
          </>
        }
        actions={
          <>
            <StyledForm action="#" autoComplete="off">
              <FilterTypeaheadCategories
                className="typeahead"
                initialCateogry={0}
                categories={categories}
                testTag="filter-document-assets"
                sizeCategory="150px"
                placeholder={t('Select filter')}
                placeholderContent={<SearchPlaceHolder />}
                renderCategory={renderCategory}
                onSelect={() => {
                  setFilter({})
                }}
              />
            </StyledForm>
            <CreationButton
              className="creation-btn-primary"
              testTag="add_document_button"
              onClick={() => {
                trackEvent('RegisterDocument', { SourcePage: 'Assets' })
                setShowAddDocument(true)
              }}
              variant="primary"
              title={t('register document')}
            >
              <PlusIcon />
              {t('Register document')}
            </CreationButton>
          </>
        }
        open={open}
        setOpen={setOpen}
      >
        <DocumentTable filter={filter} setFilter={setFilter} refresh={refresh} />
      </SectionBodyWrapper>
      <AddDocumentModal
        show={showAddDocument}
        setShow={(open: boolean) => {
          setShowAddDocument(open)
          if (!open) {
            setRefresh(new Date().getTime())
          }
        }}
      />
    </>
  )
}

export const AddDocumentModal = (props: { show: boolean; setShow(visible: boolean): void; onSubmit?(): void }) => {
  const { t } = useTranslation()
  if (props.show) {
    return (
      <AssetFormModal
        onClose={() => {
          props.setShow(false)
        }}
        cappable={true}
        title={t('document:publish:register_document')}
        Form={
          <DocumentForm
            onSubmit={() => queryClient.clear()}
            onClose={() => {
              props.setShow(false)
            }}
          />
        }
      />
    )
  }

  return null
}

const DocumentTable = (props: FilteredContainer & { refresh?: string | number }) => {
  const [token, setToken] = useState<string | undefined>()
  const [filter, setFilter] = useState<Filter>({
    ...props.filter,
    page: 1,
    page_size: 50,
    document: true,
    dropbox: false,
  })
  const { isError, refetch, loading, error, data: assets, nextToken } = useAssetList(filter, token, props.refresh)
  const { t } = useTranslation()

  const { setAlert } = useAlerts()
  const copy = (asset: RkvstAsset) => {
    copyToClipboard(stripAnyAssetResourceFromId(asset?.identity || '')).then(() => {
      setAlert({ type: 'success', message: t('share:Copied', { subject: asset.identity }).toString(), timeout: 2000 })
    })
  }

  useEffect(() => {
    setToken(undefined)
    setFilter({
      ...props.filter,
      page: 1,
      page_size: 50,
      document: true,
      dropbox: false,
    })
  }, [props.filter])

  useEffect(() => {
    setToken(undefined)
  }, [props.refresh])

  return (
    <InfiniteScroll<RkvstAsset>
      rows={assets || []}
      filter={filter}
      loading={loading}
      isError={isError}
      error={error}
      refetch={refetch}
      refresh={props.refresh}
      nextToken={nextToken || ''}
      increasePage={(token: string) => setToken(token || '')}
      table={({ rows }) => (
        <SectionTableStyle data-test="document-table">
          <thead>
            <StyledTableRow className="portrait-header">
              <StyledTableHeader size="25%">{t('Document Name')}</StyledTableHeader>
              <StyledTableHeader>{t('Type')}</StyledTableHeader>
              <StyledTableHeader>{t('Version')}</StyledTableHeader>
              <StyledTableHeader>{t('Document ID')}</StyledTableHeader>
              <StyledTableHeader>{t('Visibility')}</StyledTableHeader>
            </StyledTableRow>
          </thead>

          {!isError && !!rows?.length && (
            <tbody>
              {rows?.map((asset) => (
                <StyledTableRow
                  key={asset.identity}
                  title={t('view document {{name}}', { name: getAssetName(asset) })}
                  onClick={(ev: any) => {
                    if (ev.button == 0) {
                      if (ev.ctrlKey || ev.metaKey) {
                        window.open(`/${asset.identity}`, '_blank')
                      } else {
                        window.open(`/${asset.identity}`, '_self')
                      }
                    } else if (ev.button == 1) {
                      window.open(`/${asset.identity}`, '_blank')
                    }
                    ev.stopPropagation()
                  }}
                >
                  <StyledTableCell size="25%" data-content={t('Document Name')}>
                    <div className="cell-action">
                      {getAssetName(asset) || asset.attributes?.document_document?.arc_file_name?.toString()}
                      <div onClick={(ev) => ev.stopPropagation()}>
                        {asset?.attributes?.document_document ? (
                          <VirusCheckLink
                            className="download-link"
                            index={'document_document'}
                            attachment={asset.attributes.document_document}
                            owningResourceIdentity={asset.identity}
                            withoutFileName={true}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell data-content={t('Type')}>
                    {asset.attributes?.arc_display_type?.toString()}
                  </StyledTableCell>
                  <StyledTableCell data-content={t('Version')}>
                    {asset.attributes?.document_version?.toString()}
                  </StyledTableCell>

                  <StyledTableCell data-content={t('Document ID')} onClick={(ev) => ev.stopPropagation()}>
                    <div className="cell-action">
                      <EllipsedLabel size="8em">{stripAnyAssetResourceFromId(asset.identity)}</EllipsedLabel>
                      <CopyIcon
                        className="clickable"
                        title={t('Copy Document ID to clipboad')}
                        onClick={() => copy(asset)}
                      />
                    </div>
                  </StyledTableCell>
                  <StyledTableCell data-content={t('Visibility')}>
                    {asset.public ? t('Public') : t('Private')}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </tbody>
          )}
        </SectionTableStyle>
      )}
    />
  )
}
