import { useTenanciesDataContext } from '@app/context/TenanciesDataContext'
import { AlertTriangleIcon, BodyText, Button, FeaturedIcon, Modal } from '@ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CapReachedOverlayContainer } from './CapReachedOverlay.style'

const getMessage = () => {
  const { t } = useTranslation()
  const { whoAmI } = useTenanciesDataContext()

  if (whoAmI?.expired) {
    return {
      title: t('globals:subscription_expired_caps_blocking_title'),
      msg: t('globals:subscription_expired_caps_description_msg'),
    }
  } else if (whoAmI?.tier === 'READONLY') {
    return {
      title: t('globals:readonly_caps_blocking_title'),
      msg: t('globals:readonly_caps_description_msg'),
    }
  } else {
    return {
      title: t('globals:cap_reached_blocking_title'),
      msg: t('globals:cap_reached_description_msg'),
    }
  }
}

export const CapReachedOverlay: React.FC<{ onClose: () => void; fade?: number }> = (props) => {
  const { t } = useTranslation()
  const supportLink = 'https://support.datatrails.ai/hc/en-gb/requests/new'

  const { title, msg } = getMessage()

  return (
    <CapReachedOverlayContainer id="loading_overlay" fade={props.fade}>
      <Modal
        onClose={props.onClose}
        icon={<FeaturedIcon variant="error" icon={<AlertTriangleIcon />} />}
        variant="center"
        title={title.toString()}
        buttons={[
          <Button
            buttonFunction="normal"
            variant="secondary-grey"
            onClick={props.onClose}
            data-test="caps-overlay-close-button"
          >
            {t('Close')}
          </Button>,
          <Button
            buttonFunction="normal"
            variant="primary"
            onClick={() => window.open(supportLink, '_blank', 'noreferrer')}
            data-test="caps-overlay-contact-button"
          >
            {t('globals:cap_reached_contact_us')}
          </Button>,
        ]}
        size="475px"
      >
        <section className="centered-content">
          <BodyText sz="lg" testTag="cap-reached-msg">
            {msg.toString()}
          </BodyText>
          <br />
          <br />
          <BodyText sz="md" testTag="cap-reached-cta">
            {t('globals:cap_reached_upgrade_msg')}
          </BodyText>
        </section>
      </Modal>
    </CapReachedOverlayContainer>
  )
}

export default CapReachedOverlay
