import { lazily } from 'react-lazily'

import { wrapSuspense } from '../../atoms/placeholders/Suspense'

const { Modal: RawModal } = lazily(() => import('./Modal'))
const Modal = wrapSuspense<typeof RawModal>(RawModal)
export { Modal }

const {
  ButtonsWrapper: RawModalButtonsWrapper,
  HeaderWrapper: RawModalHeaderWrapper,
  ModalWrapper: RawModalModalWrapper,
  TextWrapper: RawModalTextWrapper,
} = lazily(() => import('./Modal.styles'))
const ModalButtonsWrapper = wrapSuspense<typeof RawModalButtonsWrapper>(RawModalButtonsWrapper)
const ModalHeaderWrapper = wrapSuspense<typeof RawModalHeaderWrapper>(RawModalHeaderWrapper)
const ModalModalWrapper = wrapSuspense<typeof RawModalModalWrapper>(RawModalModalWrapper)
const ModalTextWrapper = wrapSuspense<typeof RawModalTextWrapper>(RawModalTextWrapper)
export { ModalButtonsWrapper, ModalHeaderWrapper, ModalModalWrapper, ModalTextWrapper }

export * from './Modal.styles'
export * from './Modal.types'
