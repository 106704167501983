import React, { RefObject, useEffect, useRef } from "react";

/**
 * To dtec click outside a component
 *
 * @usage
 *
 * import { useClickOutside } from '@ui/components'
 *
 * const MyComponent = (props: any) => {
 *    const myRef = useRef(null)
 *    const clickOutsideHandle = useCallback(() => {console.log('clicked outside!')}, [])
 *    useClickOutside(myRef, () => clickOutsideHandle)
 *
 *    return (<section ref={myRef}>click outside</section>)
 * }
 */
export const useClickOutside = (ref: RefObject<any>, onClick: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClick]);
};
