export function mapArrayById(arr: Array<any>, id: string): any {
  const ret: any = {}

  arr.forEach((item) => {
    let innerarray: Array<any>

    if (id in item && item[id]) {
      if (!Array.isArray(item[id])) {
        innerarray = [item[id]]
      } else {
        innerarray = item[id]
      }

      innerarray.forEach((inneritem) => {
        ret[inneritem] = item
      })
    }
  })
  return ret
}
