import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseIcon, PlusIcon, RemoveIcon } from '../../../svgs'
import { Button } from '../../atoms/Button'
import { BodyText, H } from '../../atoms/Typography'
import { Combobox, ComboboxProps, TypeaheadCategoriesType } from '../../compounds/Typeahead'
import { FilterCardItem, FilterCardRenderItem } from './Cards.types'
import { Container } from './FilterCard.styles'
import { MULTI_OPTION_SEPARATOR } from './FilterCards.constants'

export interface FilterCardProps<T = any> extends RkvstBaseProps {
  title?: string
  size?: string
  renderFilter: FilterCardRenderItem
  filterOptions: TypeaheadCategoriesType[]
  open?: boolean
  onClose?: () => void
  onRemove?: (optionName: TypeaheadCategoriesType | null) => void
  placeholder?: string
  categories?: TypeaheadCategoriesType[]
  items: FilterCardItem[]
  setItems: (items: FilterCardItem[]) => void
  footer?: (clear: () => void) => React.ReactNode
  // Option that allows multiple instance and generate an array like
  // with the key [name]:[index number]. e.g. eels:0, eels:1 ,etc...
  // this indicates which are this [name] to be treated like this
  multiOption?: string[]
}

export const FilterCard = (props: FilterCardProps) => {
  const { t } = useTranslation()

  const clearOptions = useCallback(() => props.setItems([{ id: new Date().getTime(), data: null }]), [props.setItems])
  const multiOptionsHash = useState<{ [k: string]: number[] }>({})

  const filterOptionsNames = useMemo(() => {
    return props.filterOptions.map((opt) => ({ name: opt?.name, title: opt?.title }))
  }, [props.filterOptions])

  const [itemsNames, multiOptionIndexes] = useMemo(() => {
    const itemsNamesTmp = props.items.map((opt) => opt.data?.name || '')
    const multiOptionIndexes: Array<undefined | number> = new Array(itemsNamesTmp.length).fill(undefined)
    const index = 0
    if (props.multiOption) {
      const lastIndex: { [k: string]: number } = {}
      for (let i = 0; i < itemsNamesTmp.length; i += 1) {
        const name = itemsNamesTmp[i]
        if (name) {
          if (!props.multiOption) {
            continue
          }

          if (props.multiOption.indexOf(name) === -1) {
            continue
          }

          const [parsedName, _] = name.split(MULTI_OPTION_SEPARATOR)

          if (props.multiOption.indexOf(parsedName) === -1) {
            continue
          }

          lastIndex[name] = lastIndex[name] || 0
          itemsNamesTmp[i] = name + MULTI_OPTION_SEPARATOR + lastIndex[name]
          multiOptionIndexes[i] = lastIndex[name]
          lastIndex[name] += 1
        }
      }
    }
    return [itemsNamesTmp, multiOptionIndexes]
  }, [props.items, props.multiOption])

  const options = useMemo(() => {
    return filterOptionsNames.filter(({ name }) => itemsNames.indexOf(name) === -1)
  }, [filterOptionsNames, itemsNames])

  const optionsHash = useMemo(() => {
    const hash: { [k: string]: string } = {}
    props.filterOptions.forEach((opt) => {
      hash[opt.name] = opt.title || opt.name
    })
    return hash
  }, [props.filterOptions])

  const addOption = () => {
    props.setItems([...props.items, { id: new Date().getTime(), data: null }])
  }

  const removeItem = (id: number) => {
    const newItems = [...props.items]
    const removed = newItems.splice(id, 1)
    props.setItems(newItems)
    props.onRemove && props.onRemove(removed[0] && removed[0].data)
  }
  const selectFilter = (id: number, selected: string) => {
    const newItems = [...props.items]
    newItems[id] = {
      id: (newItems[id] && newItems[id].id) || new Date().getTime(),
      data: props.filterOptions.find((option) => option.name === selected) || null,
    }
    props.setItems(newItems)
  }

  const replaceItem = (id: number, item: FilterCardItem) => {
    const newItems = [...props.items.map((item) => ({ ...item }))]
    newItems[id] = item
    newItems[id].id = id
    props.setItems(newItems)
  }

  return (
    <Container
      className={props.className}
      data-test={props.testTag}
      data-test-id={props.testTag}
      tabIndex={props.tabIndex}
      leftItems={[
        props.title ? (
          <H sz="xs" weight="semibold" key="header">
            {props.title}
          </H>
        ) : null,
      ]}
      rightItems={[
        props.onClose ? (
          <CloseIcon
            key="close"
            className="clickable"
            onClick={() => {
              props.onClose!()
            }}
          />
        ) : null,
      ]}
    >
      <section>
        <ul className="filter-list" data-test="filter-items" data-test-id="filter-items">
          {props.items.map((item, id) => (
            <FilterItem
              key={`item${item.id}`}
              testTag={`filter-item-${id}`}
              onSelect={(selected) => selectFilter(id, selected)}
              internalId={id}
              multiOptionIndexes={multiOptionIndexes[id]}
              options={options}
              item={item}
              setItem={(newItem: FilterCardItem) => replaceItem(id, newItem)}
              optionsHash={optionsHash}
              onRemove={() => removeItem(id)}
              renderFilter={props.renderFilter}
              placeholder={props.placeholder}
            />
          ))}
        </ul>
        {props.filterOptions.length - props.items.length ? (
          <div
            className="clickable add-filter-footer"
            data-test="add-filter"
            data-test-id="add-filter"
            onClick={() => addOption()}
          >
            <PlusIcon />
            <BodyText className="heading-font" weight="bold">
              {t('Add filter')}
            </BodyText>
          </div>
        ) : null}
      </section>
      {props.footer ? props.footer(clearOptions) : null}
    </Container>
  )
}

interface FilterItemProps<T = any> extends RkvstBaseProps {
  onSelect: (name: string) => void
  internalId: number
  options: Array<{ name: string; title?: string }>
  item: FilterCardItem
  setItem: (item: FilterCardItem) => void
  onRemove: () => void
  optionsHash: { [k: string]: string }
  renderFilter: FilterCardRenderItem
  multiOptionIndexes?: number
  placeholder?: string
}

const FilterItem = (props: FilterItemProps) => {
  const { t } = useTranslation()
  const [isRemoving, setIsRemoving] = useState(false)
  const [previousOption, setPreviousOption] = useState('')

  const selectFilter = (ind: number) => {
    props.onSelect(props.options[ind]?.name)
  }

  const options = props.options.map((opt) => opt.name)
  const selected = props.item.data?.name

  // Please ignore the console warning that in development mode it launch about:
  // rning: You provided a `value` prop to a form field without an `onChange` handler. This will render a read-only field. If the field should be mutable use `defaultValue`. Otherwise, set either `onChange` or `readOnly`.
  // Is a false positive. Although this is manipulating the value of the combobox it do it in the correct
  // way and is bind to the props.selected which is properly handled
  let selectedInd = selected === undefined ? undefined : options.indexOf(selected)
  selectedInd = selectedInd === -1 ? undefined : selectedInd

  return (
    <li data-test={props.testTag} data-test-id={props.testTag} className={props.className}>
      <Combobox<string>
        className="filter-selector"
        placeholder={selected ? props.optionsHash[selected] || selected : props.placeholder || t('Select a filter')}
        renderOption={(opt: string) => props.optionsHash[opt] || opt}
        disabled={!props.options.length}
        selectedOption={selectedInd}
        onSelect={selectFilter}
        options={options || []}
      />

      {!selected || !props.item.data
        ? null
        : props.renderFilter({
            props: props.item.data,
            item: props.item,
            setItem: props.setItem,
            isRemoving,
            multiOptionIndexes: props.multiOptionIndexes,
          })}

      <Button
        className="remove-filter"
        variant="secondary-grey"
        onClick={() => {
          setIsRemoving(true)
          Promise.resolve().then(() => {
            props.onRemove()
          })
        }}
      >
        <RemoveIcon />
      </Button>
    </li>
  )
}
