export const getLoadingStates = (type: string, error?: any) => {
  switch (type) {
    case 'fulfilled': {
      return { loading: false, error: false }
    }
    case 'pending': {
      return { loading: true, error: false }
    }
    case 'failed': {
      return { loading: false, error }
    }
  }
  return {}
}
