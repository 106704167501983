import { RkvstResourceCaps, UserTenant } from '@ui/types'

import api from '../core'

const group = ''
const resource = 'users'
const capsUrl = api.parseCapsUrl('tenancies/users')
const version = '1' // note missing V as parseResourceUrl appends the v...
const resourceUrl = api.parseResourceUrl(group, resource, version)

export type UserTenants = { tenants: UserTenant[] }

export default {
  /**
   * get list of tenants and their respective display_name
   */
  getTenants(): Promise<UserTenants> {
    const result = api.get<UserTenants>(resourceUrl + '/tenants')

    // XXX: This typing assertion only works incidentally because it handles strings.
    return result as Promise<UserTenants>
  },

  getCaps(): Promise<RkvstResourceCaps> {
    return api.get(`${capsUrl}`)
  },
}
