import { pickGap } from '@ui/themes'
import styled from 'styled-components'

import { StyledAlertProps } from './Alert.types'

// If there's no supporting text, everything is flex on a single line.

// If there is supporting text, then the icon, message and close go on the first line, and then
// there's a supporting text line, and then any buttons are flowed horizontally
export const AlertContainer = styled.div<StyledAlertProps>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  text-align: left;
  margin-bottom: 10px;

  .alert-icon {
    display: inline-flex;
  }

  .alert-header,
  .alert-support {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    word-break: break-word;
  }

  .alert-title {
    width: 100%;
  }

  .inline-btn {
    flex-grow: 0;
    display: inline-flex;
    &:hover {
      cursor: pointer;
    }
  }

  .support-text {
    width: 100%;
  }

  ${({ theme }) => `
    box-shadow: 0px 1px 3px -3px ${theme?._v1?.colors.grey.i900};
    padding: ${pickGap(theme?._v1, 'size4').px};
    .alert-icon {
      width: ${pickGap(theme?._v1, 'size6').px};
    }
  `}

  ${({ theme, variant }) => {
    switch (variant) {
      case 'info': {
        return `
          border: 1px solid ${theme._v1.colors.information.i200};
          background-color: ${theme._v1.colors.information.i50};
          color: ${theme._v1.colors.information.i800};
          label, h3, h4 {
            color: ${theme._v1.colors.information.i800};
          }
        `
      }
      case 'error': {
        return `
          border: 1px solid ${theme._v1.colors.error.i300};
          background-color: ${theme._v1.colors.error.i50};
          color: ${theme._v1.colors.error.i600};
          label, h3, h4 {
            color: ${theme._v1.colors.error.i600};
          }
        `
      }
    }
  }}
`
