/**
 * ensurePublicIdentity is a temporary workaround that converts permissioned identities into their
 * supposed public counterpart. This works given the assumption that the same UUID is used, which is
 * true at time of writing. This function exists to prevent breakages once the backend fix goes in.
 *
 * We can remove this once the backened work for #7598 is merged.
 */
export const ensurePublicIdentity = (identity: string): string => {
  if (identity.startsWith('assets')) {
    return `public${identity}`
  }

  return identity
}

export const stripAnyAssetResourceFromId = (identity: string): string => {
  if (identity.startsWith('publicassets')) {
    return identity.replace('publicassets/', '')
  }

  return identity.replace('assets/', '')
}
