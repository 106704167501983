import { pickColor, pickGap, pickShadow } from '@ui/themes'
import styled, { css } from 'styled-components'

import { ButtonGroup } from '../ButtonGroup/ButtonGroup'

/**
 * BaseCard defines card visuals and sensible default padding. This is designed to be overridden
 * by more specialised cards that can define alternative spacing, flex layouts and media queries. It
 * follows a flex column layout by default.
 */
export const BaseCardMixin = css`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;

    border-radius: ${pickGap(theme?._v1, 'size2').px};
    border: 1px solid;
    border-color: ${pickColor(theme?._v1, 'grey', 'i200')};
    background-color: ${theme?._v1?.colors.white};
    ${pickShadow(theme?._v1, 'lg')}

    margin: 0;
    padding: ${pickGap(theme?._v1, 'size4').px};
    gap: ${pickGap(theme?._v1, 'size4').px};

    text-align: left;

  `}
`
export const BaseCard = styled.section`
  ${BaseCardMixin}
`

export const LoadingCard = styled(BaseCard)<{ height?: string; margin?: string }>`
  height: ${({ height }) => height || '300px'};
  margin: ${({ margin }) => margin || '0px'};

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: linear-gradient(to right, #eee 0%, #e6e6e6 25%, #eee 50%);
  background-size: 2000px; // Animation Area

  @keyframes placeholderAnimate {
    0% {
      background-position: -1000px 0;
    }
    50% {
      background-position: 1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`

/**
 * TopLevelCard is the primary card style, with additional spacing and margin to help it stand out
 * on the page. Content on the page flows downwards with a sensible default gap. This extra padding
 * is unhelpful on smaller screens, so media queries are used to reduce it.
 */
export const TopLevelCard = styled(BaseCard)`
  ${({ theme }) => `
    margin: ${pickGap(theme?._v1, 'size4').px};
    padding: ${pickGap(theme?._v1, 'size8').px};

    @media (max-width: 768px) {
      margin: ${pickGap(theme?._v1, 'size2').px};
      padding: ${pickGap(theme?._v1, 'size4').px};
    }

    @media (max-width: 480px) {
      margin: 0px;
      padding: ${pickGap(theme?._v1, 'size3').px};
    }
  `}
`

/**
 * BorderlessCard lets its content fill right out to the edges, being useful for borderless image
 * cards etc.
 */
export const BorderlessCard = styled(BaseCard)`
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  gap: 0px;
`

/**
 * Standard half image, half text content component - in column layout
 */
export const SectionCard = styled(BorderlessCard)`
  ${({ theme }) => `
    .section-content {
      display: flex;
      flex-direction: column;
      gap: ${pickGap(theme?._v1, 'size4').px};

      height: 100%;
      margin: ${pickGap(theme?._v1, 'size4').px};

      button:last-child {
        margin-top: auto;
      }
    }
  `}
`

export const CustomImg = styled.img<{ height?: string }>`
  width: 100%;
  object-fit: cover;
  ${({ height }) => (height && `height: ${height}`) || ''};
`

export const HeaderLayout = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => pickGap(theme?._v1, 'size2').px};

  &.reflow-mobile {
    @media (max-width: 768px) {
      flex-direction: column;
      gap: ${({ theme }) => pickGap(theme?._v1, 'size4').px};

      // Requested by UX
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .leftHeaderContent {
    max-width: 100%;
    flex-wrap: wrap;

    & > :is(h1, h2, h3, h4) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.reflow-mobile > .rightHeaderContent {
    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin-left: 0;

      ${ButtonGroup} {
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }

  .rightHeaderContent {
    margin-left: auto;
    flex-wrap: wrap;
  }
`

// TODO: This feels quite standard, should move it to a layouts section
export const WidgetBar = styled.section<{ gap?: string }>`
  ${({ theme, gap }) => `
    display: flex;
    align-items: center;
    gap: ${gap ? gap : pickGap(theme?._v1, 'size2').px};
  `}
`

export const ColumnCard = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0px;
  overflow-x: scroll;
`

export const CentralisedColumnCard = styled(ColumnCard)`
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => pickGap(theme._v1, 'size6').px};
`
