import { RkvstResourceCaps } from '@ui/types'

import api from '../core'

const group = 'iam'
const resource = 'access_policies'
const version = '1'
const capsUrl = api.parseCapsUrl(resource)
const resourceUrl = api.parseResourceUrl(group, resource, version)

export default {
  getCaps(): Promise<RkvstResourceCaps> {
    return api.get(`${capsUrl}`)
  },
}
