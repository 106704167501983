/*
 * For managing chicklets in components
 */
export const removeChicklet = <T extends string = string>(
  index: number,
  chicks: T[]
) => {
  const newChicks = [...chicks];
  newChicks.splice(index, 1);
  return newChicks;
};

export const addSingleChicklet = <T extends string = string>(
  chicks: T[],
  text?: T,
  toggle = false
) => {
  if (text?.trim()) {
    const index = chicks.indexOf(text);
    if (index === -1) {
      const newChicks = [...chicks.concat([text])];
      return newChicks;
    } else if (toggle) {
      return removeChicklet(index, chicks);
    }
  }
  return chicks;
};

export const addChicklet = <T extends string = string>(
  initialChicklets: T[],
  chick: T[] | T,
  toggle = false
) => {
  let newChicks: T[] = [];

  if (Array.isArray(chick)) {
    newChicks = chick.reduce((newChicks, chick) => {
      return addSingleChicklet(newChicks, chick, toggle);
    }, initialChicklets);
  } else {
    newChicks = addSingleChicklet(initialChicklets, chick, toggle);
  }

  return newChicks;
};
