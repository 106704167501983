import { pickColor, pickGap, pickShadow } from '@ui/themes'
import styled from 'styled-components'

import { H } from '../../atoms/Typography/H'
import { BaseCard, BorderlessCard, CustomImg, HeaderLayout, SectionCard, TopLevelCard, WidgetBar } from './Cards.styles'
import { HeaderBarProps, HeaderCardProps, ImageCardProps, PageCardProps } from './Cards.types'

export interface ImageSectionCardProps {
  image: string
  imageLabel?: React.ReactNode
  imageHeight?: string
  children?: React.ReactNode
  title: React.ReactNode
  className?: string
}

const ImageSectionCardFigure = styled.figure`
  ${({ theme }) => `
    margin: 0px;
    padding: 0px;
    position: relative;

    .label {
      position: absolute;
      bottom: ${pickGap(theme?._v1, 'size3').px};
      right: ${pickGap(theme?._v1, 'size2').px};
    }
  `}
`

export const ImageSectionCard = (props: ImageSectionCardProps) => {
  return (
    <SectionCard className={props.className}>
      {props.image && (
        <ImageSectionCardFigure>
          <>
            <CustomImg src={props.image} className="img" height={props.imageHeight} />
            <div className="label">{props.imageLabel}</div>
          </>
        </ImageSectionCardFigure>
      )}
      <div className="section-content">
        <H sz="xs" weight="semibold">
          {props.title}
        </H>
        {props.children}
      </div>
    </SectionCard>
  )
}

export const HeaderBar = (props: HeaderBarProps) => {
  const { leftItems, rightItems, className, reflowForMobile } = props
  return (
    <HeaderLayout className={`${className ? className : ''} ${reflowForMobile ? 'reflow-mobile' : ''}`}>
      <WidgetBar className="leftHeaderContent" key="left">
        {leftItems}
      </WidgetBar>
      <WidgetBar className="rightHeaderContent" key="right">
        {rightItems}
      </WidgetBar>
    </HeaderLayout>
  )
}

export const PageCard = (props: PageCardProps) => {
  const { headerContent, children } = props
  return (
    <TopLevelCard>
      {headerContent}
      {children}
    </TopLevelCard>
  )
}

export const HeaderCard = (props: HeaderCardProps) => {
  const { leftItems, rightItems, className, children, testTag } = props
  return (
    <BaseCard className={className} data-test={testTag}>
      <HeaderBar leftItems={leftItems} rightItems={rightItems} className="card-title" key="headerBar" />
      {children}
    </BaseCard>
  )
}

// XXX: This card probably belongs alongside the page that uses it.
export const CompressedCard = styled(HeaderCard)`
  margin: 0px;
  padding: 0px;
  gap: 0px;

  ${({ theme }) => `
    ${pickShadow(theme?._v1, 'sm')}
  `}

  ${({ theme }) => `
  .card-title {
    align-items: center;
    padding: ${pickGap(theme?._v1, 'size2').px};
    padding-left: ${pickGap(theme?._v1, 'size4').px};
  }


  .list-card-table {
    color: ${pickColor(theme?._v1, 'grey', 'i500')};
    thead th {
      padding: ${pickGap(theme?._v1, 'size3').px};
      padding-left: ${pickGap(theme?._v1, 'size4').px};
      text-align: left;
      background-color: ${pickColor(theme?._v1, 'grey', 'i200')};
    }
    tbody tr {
      border: none;
    }
    tbody td {
      padding: ${pickGap(theme?._v1, 'size3').px};
      padding-left: ${pickGap(theme?._v1, 'size4').px};
    }

    input {
      width: 100%;
    }
  }
  `}

  .body-footer {
    text-align: center;
    ${({ theme }) => `
      padding: ${pickGap(theme?._v1, 'size4').px};
      margin-bottom: ${pickGap(theme?._v1, 'size4').px};
      color: ${pickColor(theme?._v1, 'grey', 'i400')};
    `}
  }
`

/**
 * Image card that renders an image inside a card, filling the corners.
 */
export const ImageCard = (props: ImageCardProps) => {
  const { image, height, className } = props
  return (
    <BorderlessCard className={className}>
      <CustomImg src={image} height={height} />
    </BorderlessCard>
  )
}
