import { useEffect, useState } from 'react'

/**
 * useDebounce
 * @param value string to pass to bounce
 * @param delay in milliseconds
 */
export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    let handler = window.setTimeout(() => {
      if (handler) {
        setDebouncedValue(value)
      }
    }, delay)

    return () => {
      window.clearTimeout(handler)
      handler = 0
    }
  }, [value])

  return debouncedValue
}

/**
 * For debouncers not related to react (e.g. api callbacks)
 */
export const createDebouncer = (delay: number) => {
  let id = -1
  return (cb: () => void) => {
    if (id > -1) {
      clearTimeout(id)
    }

    id = window.setTimeout(() => {
      if (id > -1) {
        clearTimeout(id)
      }
      cb()
    }, delay)
  }
}

/**
 * For debouncers not related to react (e.g. api callbacks)
 * This version returns a method for clearing the debouncer
 */
export const createClearableDebouncer = (delay: number) => {
  const time: any = { id: -1 }

  time.clear = () => {
    if (time.id > 0) {
      window.clearTimeout(time.id)
    }
  }

  time.debounce = (cb: () => void) => {
    if (time.id > -1) {
      clearTimeout(time.id)
      time.id = -1
    }

    time.id = window.setTimeout(() => {
      if (time.id > -1) {
        clearTimeout(time.id)
      }
      cb()
    }, delay)
  }

  return time
}
