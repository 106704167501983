import styled from 'styled-components'

import { theme } from '../../../Css'

export const ListContainer = styled.div`
  .unresolved {
    color: ${theme.colors.error};
  }
  th {
    height: 32px;
  }
  th.with-filter {
    position: relative;
    cursor: pointer;
    transition: background 0.1s linear;
    padding-left: 20px;
  }
  th.with-filter:hover {
    background: rgb(224, 242, 254);
  }
  th.with-filter.clicked {
    background: rgb(224, 242, 254);
  }
  th.with-filter img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    cursor: pointer;
    transition: background 0.1s linear;
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
  th.with-filter .close-button {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    animation: fadeIn 0.2s linear;
    color: rgb(100, 100, 100);
    font-size: 12px;
    margin-right: 8px;
  }
  th.with-filter .close-button:hover {
    color: rgb(40, 40, 40);
  }
  .name {
    max-width: 160px;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: ${theme.layout.grid.max.md}px) {
    th,
    td {
      &.type,
      &.location,
      &.edit,
      &.delete,
      &.storage {
        display: none;
      }
    }
  }
`
