import api from '../core'

const group = null
const resource = 'archivistnode'
const version = '1'
const resourceUrl = api.parseResourceUrl(group, resource, version)

export default {
  get(params: any) {
    return api.get(resourceUrl, params)
  },
}
