import { Props } from '@app/components/Form/InputTypeAheadSelect/InputTypeAheadSelect.types'
import { Combobox } from '@headlessui/react'
import { createClearableDebouncer } from '@ui/components'
import includes from 'lodash/includes'
import React, { useMemo } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Highlighter from 'react-highlight-words'

import showError from '../showError'
import { Container } from './InputTypeAheadSelect.styles'

const MAX_OPTIONS = 5

const getMatchingOptions = <T = string,>(props: InputTypeAheadSelectProps<T>, maxOptions = MAX_OPTIONS) => {
  if (props.meta.pristine) {
    return []
  }

  const value = props.input.value.trim().toLowerCase()
  const matches: Array<string> = []

  if (!value) {
    return matches
  }

  for (const option of props.options) {
    if (includes(option.toLowerCase(), value)) {
      matches.push(option)

      if (matches?.length === maxOptions) {
        break
      }
    }
  }

  return matches
}

const DEFAULT_THRESHOLD = 2

export interface InputTypeAheadSelectProps<T = string> extends Props<T> {
  maxOptions?: number
  threshold?: number
  enforceNotClearOnBlur?: boolean
  optionsAlreadyFiltered?: boolean
}

const Presentation = <T = string,>(props: InputTypeAheadSelectProps<T>) => {
  const [inputText, setInputText] = useState('')
  const [isSelected, setIsSelected] = useState(false)
  const [focus, setFocus] = useState(false)

  const matchingOptions = useMemo(() => {
    if (props.optionsAlreadyFiltered) {
      return props.options
    } else {
      return props.loadNewOptions ? props.options.sort() : getMatchingOptions<T>(props, props.maxOptions)
    }
  }, [props.optionsAlreadyMatched, props.loadNewOptions, props.options, props.maxOptions, props.meta, props.input])

  const debouncer = useMemo(() => createClearableDebouncer(250), [])
  const [debouncedSearch, setdebouncedSearch] = useState<undefined | string>()
  const hasLoadOptions = !!props.loadNewOptions
  useEffect(() => {
    debouncer.debounce(() => {
      if (hasLoadOptions) {
        setdebouncedSearch(inputText)
      }
    })

    return () => {
      if (debouncer.id) {
        debouncer.clear()
      }
    }
  }, [hasLoadOptions, debouncer, inputText])

  useEffect(() => {
    if (debouncedSearch) {
      const threshold = props.threshold === undefined ? DEFAULT_THRESHOLD : props.threshold
      if (props.loadNewOptions && inputText.trim().length >= threshold && !isSelected)
        props.loadNewOptions(debouncedSearch)
    }
  }, [debouncedSearch, props.threshold])

  const handleUpdate = (ev: any, props: InputTypeAheadSelectProps<T>) => {
    const newValue = ev.length > 0 ? ev : ev.target?.value || ''

    // don't allow the input to contain inputText which is only spaces
    if (newValue?.length > 0 && newValue.trim().length === 0) {
      setInputText('')
      if (props.clearOptions) {
        props.clearOptions()
      }
    } else {
      setInputText(newValue)
      props.input.onChange(newValue)

      const threshold = props.threshold === undefined ? DEFAULT_THRESHOLD : props.threshold
      if (props.clearOptions && newValue.trim().length < threshold) {
        props.clearOptions()
      }
    }
  }

  return (
    <Container
      data-test={props.testTag}
      data-test-id={props.testTag}
      className={
        (props.label ? 'input select' : '') +
        (props.meta.error ? ' input-error' : '') +
        (props.className ? ` ${props.className}` : '')
      }
    >
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}

      {props.helpText ? <span className="helpText">{props.helpText}</span> : null}

      <div className="combobox-field">
        <Combobox<string>
          value={props.input.value}
          disabled={props.disabled}
          onChange={(item) => {
            handleUpdate(item, props)
            setIsSelected(true)
            if (props.clearOptions) props.clearOptions()
            setFocus(false)
            if (props.onSelect) {
              props.onSelect(item)
            }
          }}
        >
          <Combobox.Button as="div">
            <Combobox.Input
              data-test={`${props.id}`}
              id={`${props.id}`}
              onKeyDown={(ev: any) => {
                // fallback to default borwser key behaviour
                // typeahead caches Home and End unless that the user
                // press shift. This is because it uses Home / End and PaheUp / PageDown
                // no navigare through the dropdown option. We simulate this a shift key press
                // as we don't want to overwrite the borwser default behaviour for Home / End
                if (['Home', 'End'].includes(ev.key)) {
                  ev.shiftKey = true
                }
              }}
              onChange={(ev: any) => {
                setIsSelected(false)
                if (props.onChange) {
                  props.onChange(ev.target.value)
                }
                handleUpdate(ev, props)
              }}
              onBlur={(ev: any) => {
                if (props.onBlur) {
                  props.onBlur(ev)
                }

                //Don't let this override the combobox selection!
                const attr = ev.relatedTarget?.attributes?.getNamedItem('data-headlessui-state')
                if (attr) {
                  return
                }

                if (ev.target.value && props.onSelect) {
                  props.onSelect(ev.target.value)
                }

                if (ev.relatedTarget && ev.relatedTarget?.getAttribute('data-reach-combobox-option') === null) {
                  setFocus(false)
                }
              }}
              onFocus={(ev: any) => {
                setIsSelected(false)
                if (props.onFocus) {
                  props.onFocus(ev.target.value)
                }
                handleUpdate(ev, props)
                setFocus(true)
              }}
              placeholder={props.placeholder}
            />
          </Combobox.Button>
          {focus && matchingOptions?.length > 0 && (
            <Combobox.Options className="headlessui-drop-down" data-test="combobox-drop-down">
              {matchingOptions.map((matchingOption: any, i: number) => {
                return (
                  <Combobox.Option key={i} value={matchingOption}>
                    {props.children ? (
                      props.children(matchingOption)
                    ) : (
                      <Highlighter
                        highlightClassName="highlighted"
                        // all the searchWords will be auto cast to regexp, so we escape it
                        // as the search string "*" will make it crash
                        autoEscape={true}
                        searchWords={[inputText]}
                        textToHighlight={matchingOption.toString()}
                      />
                    )}
                  </Combobox.Option>
                )
              })}
            </Combobox.Options>
          )}
        </Combobox>
      </div>

      {showError(props.meta) && <span className="error-message">{props.meta.error}</span>}
    </Container>
  )
}

const InputTypeAheadSelect = Presentation

export default InputTypeAheadSelect
