import React, { useState } from 'react'
import { FieldRenderProps } from 'react-final-form'

import { RkvstLocation } from '../../state/initialState'
import Img from '../Img'
import showError from './showError'

type Props = {
  id: string
  locations: Array<RkvstLocation>
  placeholder?: string
  disabled?: boolean
  label?: string
  helpText?: string
  isConcatenated?: boolean
} & FieldRenderProps<RkvstLocation, HTMLInputElement>

const Presentation: React.FC<Props> = (props) => {
  const [selectedLocation, setSelectedLocation] = useState(props.input.value)
  const [optionsOpen, setOptionsOpen] = useState(false)

  const selectLocation = (location: RkvstLocation) => {
    setSelectedLocation(location)
    setOptionsOpen(false)
    props.input.onChange(location)
  }

  const truncate = (value: string) => {
    if (props.isConcatenated) {
      return value?.substr(0, 25) + '...'
    }
    return value
  }

  return props.disabled ? (
    <div className="input select-location select-using-api-model">
      {props.label && <label htmlFor={props.id}>{props.label}</label>}

      {props.helpText && <span className="helpText">{props.helpText}</span>}

      {selectedLocation && (
        <div className="select-wrapper disabled">
          <div className="disabled">
            <table className="legacy-table">
              <tbody>
                <tr>
                  <td className="name">{selectedLocation.display_name}</td>
                  <td className="description">{selectedLocation.description}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div
      className={'input select-location select-using-api-model' + (props.meta.error ? ' input-error' : '')}
      data-test="locations-field-wrapper"
      onClick={() => {
        setOptionsOpen(true)
      }}
    >
      {props.label && <label htmlFor={props.id}>{props.label}</label>}

      {props.helpText && <span className="helpText">{props.helpText}</span>}

      {optionsOpen && (
        <div
          className="background"
          onClick={(ev) => {
            setOptionsOpen(false)
            ev.stopPropagation()
          }}
        ></div>
      )}

      <div data-test="location" className="select-wrapper">
        <div
          className="selected legacy-overflow-text"
          data-test="locations-field-toggle"
          id="select_location"
          onClick={() => {
            setOptionsOpen(!optionsOpen)
          }}
        >
          {!selectedLocation ? (
            <span className="placeholder">{props.placeholder}</span>
          ) : (
            <table className="legacy-table">
              <tbody>
                <tr>
                  <td className="name">{truncate(selectedLocation.display_name)}</td>
                  <td className="description">{selectedLocation.description}</td>
                </tr>
              </tbody>
            </table>
          )}

          <Img name="search-icon" alt="Search" className="search" />
        </div>

        {optionsOpen && (
          <div className="options">
            <table className="clickablerows legacy-table">
              <thead>
                <tr>
                  <th className="name">Name</th>
                  <th className="description">Description</th>
                </tr>
              </thead>
              <tbody>
                {props.locations.map((location, i) => (
                  <tr
                    key={`option-${i}`}
                    onClick={(ev: any) => {
                      selectLocation(location)
                      ev.stopPropagation()
                    }}
                  >
                    <td className="name">{location.display_name}</td>
                    <td className="description">{location.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {showError(props.meta) && <span className="error-message">{props.meta.error}</span>}
    </div>
  )
}

const SelectLocation = Presentation

export default SelectLocation
