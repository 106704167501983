import React from 'react'
import { useParams } from 'react-router'
import { RouteProps } from 'react-router-dom'

import { WithRoleBasedAccessProps } from './roleBasedAccess'

/**
 * adds the url params automatically in the props of the component
 * as the application is build in that concept
 *
 * this is because react router relays in using hooks to extract
 * url parameters
 */
export const withRouterParams =
  <T = WithRoleBasedAccessProps,>(WrappedComponent: React.ComponentType<RouteProps>) =>
  (props: RouteProps & T) => {
    const params = useParams()
    return (
      <>
        <WrappedComponent {...params} {...props}></WrappedComponent>
      </>
    )
  }
