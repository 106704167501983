import { pickColor } from '@ui/themes'
import styled from 'styled-components'

export const StyledTable = styled.table`
  border-spacing: 0;

  // Portrait orientation handling
  &.supports-portrait {
    thead {
      tr.portrait-header {
        display: none;
        @media (max-width: 768px) {
          display: table-row;
        }
      }

      tr.normal-header {
        display: table-row;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    tbody > tr {
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        border: none;

        padding: 8px 0 8px 0;

        border-bottom: 1px solid ${({ theme }) => pickColor(theme._v1, 'grey', 'i200')};

        &:nth-child(even):not(:hover) {
          background-color: ${({ theme }) => pickColor(theme._v1, 'grey', 'i50')};
        }
      }
    }

    tbody > tr > td {
      @media (max-width: 768px) {
        padding: 8px 16px 8px 16px;
        border-bottom: none;

        &:before {
          content: attr(data-content);
          width: 120px;
          display: block;
          word-wrap: normal;

          ${({ theme }) => `
            font-size: ${theme._v1.typography.body.size.md.px};
            line-height: ${theme._v1.typography.body.size.md.hpx};
            font-family: ${theme._v1.typography.body.family};
            font-weight: ${theme._v1.typography.weight['medium']};
          `}
        }
      }
    }
  }
`

export const StyledTableCompress = styled(StyledTable)`
  &.supports-portrait {
    tbody > tr > td {
      @media (max-width: 768px) {
        &:before {
          float: left;
          display: inline-block;
          width: 50%;
        }
      }
    }
  }
`

export const StyledTableHeader = styled.th<{ size?: string }>`
  padding: 12px 16px;
  gap: 12px;
  text-align: left;

  .aligner {
    display: flex;
    align-items: center;

    svg {
      height: 16px;
    }
  }

  ${({ theme, size }) => `
    border-bottom: 1px solid ${pickColor(theme._v1, 'grey', 'i200')};
    background: ${pickColor(theme._v1, 'grey', 'i50')};
    color: ${pickColor(theme._v1, 'grey', 'i500')};
    ${size ? 'width: ' + size : ''}
  `}
`

export const StyledTableRow = styled.tr`
  border: 0 0 0 1px;
  border-style: ${({ theme }) => pickColor(theme._v1, 'grey', 'i200')};

  &.clickable:hover {
    cursor: pointer;
    background-color: ${({ theme }) => pickColor(theme._v1, 'information', 'i50')};
  }
`

export const StyledTableCell = styled.td<{ size?: string }>`
  padding: 16px;
  text-align: left;
  word-wrap: anywhere;
  ${({ theme, size }) => `
    border-bottom: 1px solid ${pickColor(theme._v1, 'grey', 'i200')};
    color: ${pickColor(theme._v1, 'grey', 'i900')};
    ${size ? 'width: ' + size : ''}
  `}
`
