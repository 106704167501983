import locationsService from '@app/api/resources/locations'
import { useQuery } from '@tanstack/react-query'

export const useLocation = (identity: string) => {
  return useQuery({
    queryKey: [identity],
    queryFn: async () => {
      return locationsService.getOne(identity)
    },
    enabled: !!identity,
  })
}

export const useLocations = (params?: any) => {
  return useQuery({
    queryKey: ['location-all'],
    queryFn: async () => {
      const locations = await locationsService.getList(params)
      return locations?.locations || []
    },
  })
}
