import { RkvstAttributes, RkvstCreateEvent, RkvstEvent } from '@app/state/initialState'
import { AxiosRequestConfig } from 'axios'

import api from '../core'
import { FilterQuery } from '../FilterQuery'
import { AttachmentInterface } from './attachments'
import { Model } from './Model'

const group = null
const resource = 'assets/-/events'
const version = '2'
const resourceUrl = api.parseResourceUrl(group, resource, version)

export type FilterParts = {
  [key: string]: string
}

export type EventsFilterParams = {
  type?: string | null
  locationId?: string | null
  hasLocations?: boolean
  assetType?: string | null | string[]
  assetName?: string | null | string[]
  assetProfile?: string | null
  eventType?: string | null | string[]
  assetAttributes?: any
  eventAttributes?: any
  eventAssetAttributes?: any
  identity?: string | string[]
  assetIdentity?: string | string[]
  actor?: string | string[]
  wallet?: string | string[]
  orFilters?: FilterParts[]
  declared?: [string, string]
  declaredSince?: string
  declaredBefore?: string
  accepted?: [string, string]
  acceptedSince?: string
  acceptedBefore?: string
  proofMechName?: string
  pageSize?: string
}

export class CreateEvent extends Model<RkvstCreateEvent> {
  public data: RkvstCreateEvent = {
    asset_attributes: {},
    event_attributes: {},
    principal_declared: undefined,
    operation: '',
    behaviour: '',
    timestamp_declared: undefined,
  }

  public addAssetAttribute(name: string, attribute: string | AttachmentInterface) {
    if (!this.data.asset_attributes) {
      this.data.asset_attributes = {}
    }
    this.data.asset_attributes[this.escapeKey(name)] = attribute
    return this
  }
  public addEventAttribute(name: string, attribute: string | AttachmentInterface) {
    if (!this.data.asset_attributes) {
      this.data.asset_attributes = {}
    }

    this.data.event_attributes[this.escapeKey(name)] = attribute
    return this
  }

  public addEventAttachments(attachments: AttachmentInterface[]) {
    this.addAttachments(attachments, this.data.event_attributes)
  }

  public addAssetAttachments(attachments: AttachmentInterface[]) {
    this.addAttachments(attachments, this.data.asset_attributes)
  }

  private addAttachments(attachments: AttachmentInterface[], attributes: RkvstAttributes) {
    attachments.forEach((attachment: AttachmentInterface) => {
      if (this.data) {
        attributes[this.escapeKey(attachment.attachment_key)] = {
          arc_attribute_type: 'arc_attachment',
          arc_blob_identity: attachment.arc_blob_identity,
          arc_blob_hash_alg: attachment.arc_blob_hash_alg,
          arc_blob_hash_value: attachment.arc_blob_hash_value,
          arc_file_name: attachment.arc_file_name,
          mime_type: attachment.mime_type,
        }
      }
    })
    return this
  }
}

export interface RkvstEventsResponse {
  events: RkvstEvent[]
  next_page_token: string
}

const queryIdentity = (identity: string, version: string, query: string) => {
  const url = `/v${version}/${identity}` + query

  // shape the response, which is a single event, into a list
  // of events because the reducer is expecting this filter API call to
  // return a list of results
  return api.get(url)
}

export class Event extends Model<RkvstEvent> {
  public getList(params?: any) {
    return api.get(resourceUrl, params)
  }

  // XXX: Public event listing should be separated out eventually.
  public async getListFiltered(
    params: EventsFilterParams,
    nextPageToken?: string,
    usePublicSearchApi?: boolean,
    config?: AxiosRequestConfig
  ): Promise<RkvstEventsResponse> {
    const filterQuery = new FilterQuery({
      page_size: '50',
      order_by: 'DEFAULT',
    })

    if (nextPageToken) {
      filterQuery.put('page_token', nextPageToken)
    }

    if (params.pageSize) {
      filterQuery.put('page_size', params.pageSize)
    }

    if (params.eventType) {
      filterQuery.putEventAttribute('arc_display_type', params.eventType)
    }

    if (params.hasLocations === true) {
      filterQuery.putAttribute('arc_home_location_identity', '*')
    } else if (params.hasLocations === false) {
      filterQuery.putAttribute('arc_home_location_identity!', '*')
    }

    if (params.locationId) {
      filterQuery.putAssetAttribute('arc_home_location_identity', params.locationId)
    }

    if (params.assetType) {
      filterQuery.putAssetAttribute('arc_display_type', params.assetType)
    }

    if (params.assetName) {
      filterQuery.putAssetAttribute('arc_display_name', params.assetName)
    }

    if (params.assetProfile) {
      filterQuery.putAssetAttribute('arc_profile', params.assetProfile)
    }

    if (params.eventAttributes) {
      Object.keys(params.eventAttributes).forEach((key) => {
        filterQuery.putEventAttribute(key, params.eventAttributes[key])
      })
    }

    if (params.eventAssetAttributes) {
      Object.keys(params.eventAssetAttributes).forEach((key) => {
        filterQuery.putEventAssetAttribute(key, params.eventAssetAttributes[key])
      })
    }

    if (params.assetAttributes) {
      Object.keys(params.assetAttributes).forEach((key) => {
        filterQuery.putAssetAttribute(key, params.assetAttributes[key])
      })
    }

    if (params.actor) {
      if (Array.isArray(params.actor)) {
        if (params.actor.length > 1) {
          filterQuery.putAttribute('principal_declared.display_name', params.actor)
        } else {
          filterQuery.put('principal_declared.display_name', params.actor[0])
        }
      } else {
        filterQuery.put('principal_declared.display_name', params.actor)
      }
    }

    if (params.wallet) {
      if (Array.isArray(params.actor)) {
        if (params.actor.length > 1) {
          filterQuery.putAttribute('from', params.wallet)
        } else {
          filterQuery.put('from', params.wallet[0])
        }
      } else {
        filterQuery.put('from', params.wallet)
      }
    }

    if (params.declared) {
      filterQuery.put('timestamp_declared_since', params.declared[0])
      filterQuery.put('timestamp_declared_before', params.declared[1])
    }
    if (params.declaredSince) {
      filterQuery.put('timestamp_declared_since', params.declaredSince)
    }

    if (params.declaredBefore) {
      filterQuery.put('timestamp_declared_before', params.declaredBefore)
    }

    if (params.accepted) {
      filterQuery.put('timestamp_accepted_since', params.accepted[0])
      filterQuery.put('timestamp_accepted_before', params.accepted[1])
    }

    if (params.acceptedSince) {
      filterQuery.put('timestamp_accepted_since', params.acceptedSince)
    }

    if (params.acceptedBefore) {
      filterQuery.put('timestamp_accepted_before', params.acceptedBefore)
    }

    if (params.proofMechName) {
      filterQuery.put('proof_mechanism', params.proofMechName)
    }

    if (params?.orFilters?.length) {
      let filters = ''
      params.orFilters.forEach((filter) => {
        Object.keys(filter).forEach((k) => {
          if (filters.length) {
            filters += ' OR '
          }
          filters += `${k}=${filter[k]}`
        })
        filterQuery.put('filters', filters)
      })
    }

    const query = filterQuery.build()

    // use different urls depending on if identities are specified
    if (params.identity) {
      if (Array.isArray(params.identity)) {
        const events = await Promise.all(
          params.identity.map((identity) => {
            return queryIdentity(identity, version, query)
          })
        )
        return {
          events: events.reduce((acc, result) => acc.concat(result ? [result] : []), [] as RkvstEvent[]),
          next_page_token: '',
        }
      } else {
        return queryIdentity(params.identity, version, query).then((res) => {
          return {
            events: [res],
            next_page_token: '',
          }
        })
      }
    }

    if (params.assetIdentity) {
      if (Array.isArray(params.assetIdentity)) {
        const events = await Promise.all(
          params.assetIdentity.map((assetIdentity) => {
            const url = `/v${version}/${assetIdentity}/events` + query
            return api.get<RkvstEventsResponse>(url, undefined, config)
          })
        )
        return {
          events: events.reduce((acc, result) => acc.concat(result.events || []), [] as RkvstEvent[]),
          next_page_token: '',
        }
      } else {
        const url = `/v${version}/${params.assetIdentity}/events` + query
        return api.get<RkvstEventsResponse>(url, undefined, config)
      }
    }

    // This doesn't coexist with identities being supplied. It does a lookup over all events.
    // This method does too many different things.
    if (usePublicSearchApi) {
      return api.get<RkvstEventsResponse>(
        api.parseResourceUrl(group, 'publicassets/-/events', version) + query,
        undefined,
        config
      )
    }

    const url = resourceUrl + query
    return api.get<RkvstEventsResponse>(url, undefined, config)
  }

  public stripResourceFromId(id: string) {
    return id.split('events/')[1]
  }

  public getTotalCount() {
    if (api.lastResponse?.headers) {
      return parseInt(api.lastResponse.headers['x-total-count'])
    }
  }
}

export default new Event()
