import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

import CapReachedOverlay from '../../common/CapReachedOverlay'
import { Modal } from '../../common/Modal'
import { useAppDispatch, useAppSelector } from '../../hooks'
import actionCreators from '../../state/actionCreators'
import { RkvstAsset } from '../../state/initialState'
import { RootState } from '../../state/store'
import Img from '../Img'

type Props = {
  asset?: RkvstAsset
  onClose: () => void
  Form: React.ReactNode
  title: string
  cappable: boolean
}

const modalStyles = css`
  #event-img {
    position: relative;
    height: 340px;

    .img {
      position: absolute;
      left: 0;
      top: 0;
      right: 15px;
      bottom: 0;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      width: 100%;
    }
  }

  .asset-id {
    background: #fff;
    opacity: 0.66;
    line-height: 16px;
    padding: 25px;

    img {
      float: left;
      margin-right: 20px;
    }

    span.id {
      display: block;
      margin-top: 5px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  #event-info {
    word-break: break-all;

    .status {
      margin-top: 10px;

      img {
        float: left;
        margin-right: 10px;
      }

      div {
        padding: 4px;
      }
    }

    p {
      margin: 20px 0;
    }
  }
`

const Container = styled.div`
  .new-asset-buttons {
    //XXX not currently used so hide
    display: none;
    position: relative;
    padding-bottom: 15px;

    @media (min-width: $grid-lg-min) {
      text-align: right;
    }

    button {
      margin-top: 10px;
      min-width: 275px;

      @media (min-width: $grid-lg-min) {
        margin-top: 0;
      }
    }

    .existing {
      @media (min-width: $grid-md-min) {
        margin-left: 10px;
      }
    }

    .register {
      @media (min-width: $grid-lg-min) {
        position: absolute;
        right: 15px;
        bottom: -40px;
      }
    }
  }
`

const Presentation: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch()

  /* Note we only show the caps overlay at this early point if ALL Asset caps are reached.
     if some are reached and others not then we'll show the modal but grey out the
     options that would fail */
  const simpleHashCapReached = useAppSelector((state: RootState) => state.assets.simpleHashCapReached)
  const merkleLogCapReached = useAppSelector((state: RootState) => state.assets.merkleLogCapReached)
  const allCapsReached = simpleHashCapReached && merkleLogCapReached

  useEffect(() => {
    dispatch(actionCreators.assets.getCaps())
  }, [])

  return (
    <Modal css={modalStyles} onClose={props.onClose} size="large">
      <Container className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2>{props.title}</h2>
          </div>
        </div>

        {props.Form}

        {allCapsReached && props.cappable && <CapReachedOverlay onClose={props.onClose} />}
      </Container>
    </Modal>
  )
}

const AssetFormModal = connect(null, {
  getLocations: actionCreators.locations.getList,
})((props: any) => {
  useEffect(() => {
    props.getLocations()
  }, [])

  return <Presentation {...props} />
})

export default AssetFormModal
