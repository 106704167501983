import styled from 'styled-components'

const Badge = styled.span`
  display: flex;
  align-items: center;
  vertical-align: middle;
  overflow-wrap: break-word;
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  padding: 0 8px;
  border-radius: 0.25rem;
  border: medium none;
  color: #ffffff;
  text-align: center;
  font-weight: 700;
  height: 24px;

  &.small {
    max-width: 120px;
    min-width: 85px;
    font-size: ${(props: any) => props.theme?.typography?.helpText?.fontSize};
  }
`

export default Badge
