export const copyToClipboard = async (data: string) => {
  if (!navigator.permissions) {
    navigator.clipboard.writeText(data);
  }

  try {
    // Ignore because for some browser the option is not supported, even if query will resolve
    // ts will complain
    // @ts-ignore
    const permission = await navigator.permissions.query({
      name: "clipboard-write",
    } as any);
    // in case that clipboard-write is not supported permission will be undefined so we
    // provide a backward compatibility fallback
    if (!permission || permission.state === "granted") {
      navigator.clipboard.writeText(data);
    }
  } catch (e) {
    navigator.clipboard.writeText(data);
  }
};
