import { lazily } from 'react-lazily'

import { wrapVisibleSuspense } from '../../atoms/placeholders/Suspense'

const {
  SectionWrapper: RawSectionWrapper,
  SearchPlaceHolder: RawSearchPlaceHolder,
  SectionBodyWrapper: RawSectionBodyWrapper,
} = lazily(() => import('./SectionWrapper'))
const SectionWrapper = wrapVisibleSuspense<typeof RawSectionWrapper>(RawSectionWrapper)
const SearchPlaceHolder = wrapVisibleSuspense<typeof RawSearchPlaceHolder>(RawSearchPlaceHolder)
const SectionBodyWrapper = wrapVisibleSuspense<typeof RawSectionBodyWrapper>(RawSectionBodyWrapper)
export { SearchPlaceHolder, SectionBodyWrapper, SectionWrapper }
