/**
 * Formats date for displaying
 */
export const dateFormat = (date: string) => {
  const d = new Date(date);
  return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
};

/**
 * Formats date for displaying
 */
export const dateOnlyFormat = (date: string) => {
  const d = new Date(date);
  return d.toLocaleDateString();
};

/**
 * Formats date for displaying and normalize to UTC time
 * considering the local timezone of the browser
 */
export const dateFormatUTC = (date: string) => {
  let d = new Date(date);
  d = new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
  return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
};
