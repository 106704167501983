import { mainTheme } from '@ui/themes'
import styled, { css } from 'styled-components'

import { Size, StyledSizableTypographyProps, StyledTypographyProps, Weight } from './Typography.types'

const bodyFontSizes = (sz: Size, theme: typeof mainTheme): string => {
  const body = theme._v1.typography.body
  switch (sz) {
    // Body text is the only place where xs is not a valid size - defaulting to sm.
    case 'xs':
    case 'sm':
      return `
        font-size: ${body.size.sm.px};
        line-height: ${body.size.sm.hpx};
      `
    case 'md':
      return `
        font-size: ${body.size.md.px};
        line-height: ${body.size.md.hpx};
      `
    case 'lg':
      return `
        font-size: ${body.size.lg.px};
        line-height: ${body.size.lg.hpx};
      `
    default:
      return `
        font-size: ${body.size.md.px};
        line-height: ${body.size.md.hpx};
      `
  }
}

const buttonFontSizes = (sz: Size, theme: typeof mainTheme): string => {
  const button = theme._v1.typography.button
  switch (sz) {
    case 'xs':
      return `
        font-size: ${button.size.xs.px};
        line-height: ${button.size.xs.hpx};
      `
    case 'sm':
      return `
        font-size: ${button.size.sm.px};
        line-height: ${button.size.sm.hpx};
      `
    case 'md':
      return `
        font-size: ${button.size.md.px};
        line-height: ${button.size.md.hpx};
      `
    case 'lg':
      return `
        font-size: ${button.size.lg.px};
        line-height: ${button.size.lg.hpx};
      `
    default:
      return `
        font-size: ${button.size.md.px};
        line-height: ${button.size.md.hpx};
      `
  }
}

export const bodyFontStyle = (sz: Size, weight: Weight, theme: typeof mainTheme): string => {
  return `
    ${bodyFontSizes(sz, theme)}
    font-weight: ${theme._v1.typography.weight[weight || 'medium']};
    font-family: ${theme._v1.typography.body.family};
  `
}

const buttonFontStyle = (sz: Size, weight: Weight, theme: typeof mainTheme): string => {
  return `
    ${buttonFontSizes(sz, theme)}
    font-weight: ${theme._v1.typography.weight[weight || 'medium']};
    font-family: ${theme._v1.typography.button.family};
  `
}

export const H1Style = css<StyledTypographyProps>`
  ${({ theme, weight }) => {
    const h = theme._v1.typography.heading
    return `
      font-family: ${h.family};
      font-size: ${h.size.lg.px};
      line-height: ${h.size.lg.hpx};
      font-weight: ${theme._v1.typography.weight[weight || 'medium']};
      margin: 0px;
    `
  }}
`

export const H2Style = css<StyledTypographyProps>`
  ${({ theme, weight }) => {
    const h = theme._v1.typography.heading
    return `
      font-family: ${h.family};
      font-size: ${h.size.md.px};
      line-height: ${h.size.md.hpx};
      font-weight: ${theme._v1.typography.weight[weight || 'medium']};
      margin: 0px;
    `
  }}
`

export const H3Style = css<StyledTypographyProps>`
  ${({ theme, weight }) => {
    const h = theme._v1.typography.heading
    return `
      font-family: ${h.family};
      font-size: ${h.size.sm.px};
      line-height: ${h.size.sm.hpx};
      font-weight: ${theme._v1.typography.weight[weight || 'medium']};
      margin: 0px;
    `
  }}
`

export const H4Style = css<StyledTypographyProps>`
  ${({ theme, weight }) => {
    const h = theme._v1.typography.heading
    return `
      font-family: ${h.family};
      font-size: ${h.size.xs.px};
      line-height: ${h.size.xs.hpx};
      font-weight: ${theme._v1.typography.weight[weight || 'medium']};
      margin: 0px;
    `
  }}
`

export const TextStyle = css<StyledSizableTypographyProps>`
  ${({ sz, weight, theme, family }) => `
    ${bodyFontSizes(sz, theme)}
    font-weight: ${theme._v1.typography.weight[weight || 'medium']};
    font-family: ${
      !family || family === 'primary' ? theme._v1.typography.body.family : theme._v1.typography.heading.family
    };
  `}
`

export const ButtonTextStyle = css<StyledSizableTypographyProps>`
  ${({ sz, weight, theme }) => {
    return buttonFontStyle(sz, weight || 'medium', theme)
  }}
`

export const StyledH1 = styled.h1<StyledTypographyProps>`
  ${H1Style}
`

export const StyledH2 = styled.h2<StyledTypographyProps>`
  ${H2Style}
`

export const StyledH3 = styled.h3<StyledTypographyProps>`
  ${H3Style}
`

export const StyledH4 = styled.h4<StyledTypographyProps>`
  ${H4Style}
`

export const StyledText = styled.span<StyledSizableTypographyProps>`
  ${TextStyle}
`
