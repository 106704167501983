import LocationMap from '@app/components/Locations/Map'
import MapPin from '@app/components/Locations/Map/MapPin'
import LocationAssetsModal from '@app/components/Locations/Modals/LocationAssetsModal'
import { useAppSelector } from '@app/hooks'
import { stripAnyAssetResourceFromId } from '@app/utils/ensurePublicIdentity'
import { ChevronDownIcon, ChevronRightIcon, H, Img, MarkerPinIcon } from '@ui/components'
import { SectionWrapper } from '@ui/components'
import { RkvstLocation } from '@ui/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOpen } from '../../../configuration.slice'
import { useAssetList } from '../../hooks/useAssetList'
import { SectionTitle } from './AssetList.styles'

interface MapSectionProps {}

export const MapSection = (props: MapSectionProps) => {
  const { open, setOpen } = useOpen('asset:list:section:map')
  const { t } = useTranslation()
  const locations = useAppSelector((state) => state.locations.list)
  const lastUpdateAssets = useAppSelector((state) => (state.locations as any).lastUpdate)
  const [selectedLocation, setSelectedLocation] = useState<RkvstLocation | null | undefined>(undefined)

  const { data: allAssets, loading } = useAssetList({ hasLocations: true, page_size: 1 }, undefined, lastUpdateAssets)

  const {
    data: assets,
    loading: loadingPin,
    isFetching,
  } = useAssetList({ locationId: selectedLocation?.identity, page_size: 51 }, undefined, lastUpdateAssets)

  const { data: unresolvedLocationAssets } = useAssetList({ hasLocations: false }, undefined, lastUpdateAssets)

  const pinCallback = (location: RkvstLocation) => {
    // make sure we do a re-draw of the element
    setSelectedLocation(location)
  }

  const showPin = !!(assets && assets.length === 1)

  if (!locations?.length || !allAssets?.length || loading) {
    return null
  }

  return (
    <SectionWrapper
      title={
        <SectionTitle onClick={() => setOpen(!open)} className="section-title" data-test="map-section">
          {open ? <ChevronDownIcon className="chevron" /> : <ChevronRightIcon className="chevron" />}
          <MarkerPinIcon />
          <H sz="md" weight="semibold" className="title">
            {t('Locations')}
          </H>
        </SectionTitle>
      }
    >
      {open && (
        <>
          <LocationMap
            className="map-widget"
            locations={locations}
            popupCallback={pinCallback}
            showPin={!loadingPin && showPin}
            onCloseMarker={() => setSelectedLocation(undefined)}
            style={{ height: '500px' }}
            menuItems={[
              { itemId: 'manageButtons', position: 'TOP_RIGHT' },
              { itemId: 'unresolved', position: 'LEFT_BOTTOM' },
            ]}
          >
            {!loadingPin && showPin && (
              <MapPin
                identity={assets[0].identity}
                asset={assets[0]}
                displayName={assets[0].attributes?.arc_display_name?.toString() || ''}
                displayID={stripAnyAssetResourceFromId(assets[0].identity)}
                displayType={assets[0].attributes?.arc_display_type?.toString() || ''}
                showAsLink={true}
              />
            )}
          </LocationMap>
          <div style={{ display: 'none' }}>
            <div id="unresolved">
              <button
                className="widget unresolved"
                id="widget_unresolved_button"
                onClick={() => {
                  setSelectedLocation(null)
                }}
              >
                <Img name="no-location" alt="No Location Assets" />
              </button>
            </div>
          </div>

          {!showPin && !loadingPin && selectedLocation !== undefined && (
            <LocationAssetsModal
              location={selectedLocation}
              limit={50}
              assets={assets || []}
              unresolvedLocationAssets={unresolvedLocationAssets || []}
              onClose={() => {
                setSelectedLocation(undefined)
              }}
            />
          )}
        </>
      )}
    </SectionWrapper>
  )
}
