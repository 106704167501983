import styled, { css } from 'styled-components'

import { theme } from '../../../Css'

export const LoadingAssetLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .label-text {
    font-size: 14px;
    margin-bottom: 12px;
    text-align: center;
  }

  & svg {
    height: 20px;
    width: 32px;
  }
`

export const Container = styled.div`
  position: relative;
  padding: 0;
  height: 500px;

  .title-bar,
  .button-bar {
    position: absolute;
    top: 30px;
  }

  .title-bar {
    left: 15px;
  }

  .button-bar {
    margin: 5px;
    right: 15px;
    .button-action {
      margin-left: 10px;
    }
  }

  .unresolved {
    padding: 10px;
    height: 52px;
    border: 0px;
    line-height: 27px;
    vertical-align: middle;
    margin-left: 10px;
    border-radius: 12px;

    img {
      float: left;
    }

    .message {
      display: inline-block;
      margin-left: 10px;
      margin-top: 1px;
    }

    .number {
      position: absolute;
      top: 20px;
      left: 19px;
      text-align: center;
      width: 28px;
      font-weight: 700;
      font-size: 14px;
    }
  }
  .gm-style .gm-style-iw-c {
    height: 120px;
    width: 400px;
    border-radius: 0px;
    padding: 10;

    .gm-style-iw-d {
      position: relative;
      a,
      a:hover,
      a:focus,
      a:visited {
        color: ${theme.colors.brand.body};
      }
      .primary-img {
        float: left;
        object-fit: cover;
        height: 70px;
        width: 70px;
        margin: 8px;
      }
      .info {
        display: inline-block;
        width: 225px;
        margin-right: 40px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;

        p {
          margin: 8px 0;
          strong {
            display: inline-block;
            width: 40px;
          }
        }
      }

      .arrow {
        position: absolute;
        top: 50%;
        margin-top: -6px;
        right: 0;
      }
    }
  }
`
