import {
  ColorProps,
  FactorProps,
  RgbaProps,
  ShadeFactorProps,
  TintFactorProps,
  TintTypeProps,
} from './LigthenDarkenColors.types'

export const getArgsFromRGBA = (rgba: RgbaProps) => {
  return rgba
    .substring(5, rgba.length - 1)
    .replace(/ /g, '')
    .split(',')
}

const getDarken = (color: ColorProps, shadeFactor: ShadeFactorProps) => Math.round(Number(color) * (1 - shadeFactor))

const getLighten = (color: ColorProps, tintFactor: TintFactorProps) =>
  Math.round(Number(color) + (255 - Number(color)) * tintFactor)

export const getColors = (rgba: RgbaProps, factor: FactorProps, type: TintTypeProps) => {
  const args = getArgsFromRGBA(rgba)

  if (args?.length !== 4) {
    throw new Error('rgba invalid')
  }

  if (factor < 0 || factor > 1) {
    throw new Error('factor needs to be in a range between 0.1 - 1')
  }

  let red
  let green
  let blue

  if (type === 'dark') {
    red = getDarken(args[0], factor)
    green = getDarken(args[1], factor)
    blue = getDarken(args[2], factor)
  }

  if (type === 'light') {
    red = getLighten(args[0], factor)
    green = getLighten(args[1], factor)
    blue = getLighten(args[2], factor)
  }

  return [red, green, blue]
}

export const darken = (rgba: RgbaProps, shadeFactor: ShadeFactorProps) => {
  const [red, green, blue] = getColors(rgba, shadeFactor, 'dark')
  return `rgba(${red}, ${green}, ${blue}, 1)`
}

export const lighten = (rgba: RgbaProps, tintFactor: TintFactorProps) => {
  const [red, green, blue] = getColors(rgba, tintFactor, 'light')
  return `rgba(${red}, ${green}, ${blue}, 1)`
}
