import { pickColor } from '@ui/themes'
import styled from 'styled-components'

import { BodyText, FeaturedIcon } from '../..'
import { SectionCard } from '../../molecules/Cards/Cards.styles'

export const DashboardPanel = styled(SectionCard)`
  ${({ theme }) => `
    flex-grow: 1;
    width: 100%;
    padding: 16px;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: ${pickColor(theme?._v1, 'grey', 'i900')}

    .icon {
      flex-grow: 0;
    }

    .content {
      flex-grow: 1;
    }

    .simple-metric {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
    }

    .title {
      color: ${pickColor(theme?._v1, 'grey', 'i900')};
      display: block;
      clear: both;
      padding-bottom: 8px;
    }


    .total {
      color: ${pickColor(theme?._v1, 'grey', 'i900')};
      font-size: 36px;
      display: block;
      clear: both;
    }
  `}
`

interface SimpleMetricProps {
  icon: React.ReactNode
  title: string
  value: string
}

export const SimpleMetric = (props: SimpleMetricProps) => {
  return (
    <DashboardPanel className="dashboard-panel">
      <FeaturedIcon variant="info" icon={props.icon} size="lg" className="icon" />
      <div className="content simple-metric">
        <BodyText className="title">{props.title}</BodyText>
        <BodyText className="total">{props.value}</BodyText>
      </div>
    </DashboardPanel>
  )
}
