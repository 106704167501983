export type ColorIntensity = 'i25' | 'i50' | 'i100' | 'i200' | 'i300' | 'i400' | 'i500' | 'i600' | 'i700' | 'i800' | 'i900';
export interface ColorIntensities {
  i25: string;
  i50: string;
  i100: string;
  i200: string;
  i300: string;
  i400: string;
  i500: string;
  i600: string;
  i700: string;
  i800: string;
  i900: string;
}

export type RkvstTheme = typeof rkvstTheme;
export type RkvstColor = keyof RkvstTheme['colors'];
export const pickColor = (theme: RkvstTheme | undefined, category: keyof RkvstTheme['colors'], intensity?: ColorIntensity) => {
  const intensities: string | ColorIntensities = (theme || rkvstTheme).colors[category];
  return intensity ? (intensities as ColorIntensities)[intensity] : intensities as string;
};

export const pickGap = (theme: RkvstTheme | undefined, category: keyof RkvstTheme['spacing']['gaps']) => {
  return (theme || rkvstTheme).spacing.gaps[category];
};

export const pickSize = (theme: RkvstTheme | undefined, category: keyof RkvstTheme['spacing']['containers']) => {
  return (theme || rkvstTheme).spacing.containers[category];
};

export const pickShadow = (theme: RkvstTheme | undefined, category: keyof RkvstTheme['shadows']) => {
  return theme && ('box-shadow: ' + rkvstTheme.shadows[category]) || null;
};

export const rkvstTheme = {
  typography: {
    // lpx: line-height in pixels
    // hem: line-height in em
    // spacing: word-spacing, if it does not have it then this sice has no word spacing
    heading: {
      family: "'Montserrat', Geometric, sans-serif",
      size: {
        lg: {px: '30px', em: '1.875em', hpx: '38px', hem: '2.375em'},
        md: {px: '24px', em: '1.5em', hpx: '32px', hem: '2em'},
        sm: {px: '20px', em: '1.25em', hpx: '30px', hem: '1.875em'},
        xs: {px: '18px', em: '1.125em', hpx: '28px', hem: '1.75em'},
      },
    },
    body: {
      family: "'Inter', Arial, sans-serif",
      size: {
        lg:  {px: '16px', em: '1em', hpx: '24px', hem: '1.5em'},
        md:  {px: '14px', em: '0.875em', hpx: '20px', hem: '1.25em'},
        sm:  {px: '12px', em: '0.75em', hpx: '18px', hem: '1.125em'},
      },
    },
    button: {
      family: "'Montserrat', Geometric, sans-serif",
      size: {
        lg:  {px: '18px', em: '1.125em', hpx: '28px', hem: '1.75em'},
        md:  {px: '16px', em: '1em', hpx: '24px', hem: '1.5em'},
        sm:  {px: '14px', em: '0.875em', hpx: '20px', hem: '1.25em'},
        xs:  {px: '12px', em: '0.75em', hpx: '18px', hem: '1.125em'},
      },
    },
    weight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
  },
  spacing: {
    gaps: {
      size0: {em: '0.15em', px: '2px'},
      size1: {em: '0.25em', px: '4px'},
      size2: {em: '0.5em', px: '8px'},
      size3: {em: '0.75em', px: '12px'},
      size4: {em: '1em', px: '16px'},
      size5: {em: '1.25em', px: '20px'},
      size6: {em: '1.5em', px: '24px'},
      size8: {em: '2em', px: '32px'},
      size10: {em: '2.5em', px: '40px'},
      size12: {em: '3em', px: '48px'},
      size16: {em: '4em', px: '64px'},
      size20: {em: '5em', px: '80px'},
      size24: {em: '6em', px: '96px'},
      size32: {em: '8em', px: '128px'},
      size40: {em: '10em', px: '160px'},
      size48: {em: '12em', px: '192px'},
      size56: {em: '14em', px: '224px'},
      size64: {em: '16em', px: '256px'},
    },
    containers: {
      sm: {em: '40em', px: '640px'},
      md: {em: '48em', px: '768px'},
      lg: {em: '64em', px: '1024px'},
      xl: {em: '80em', px: '1280px'},
    },
  },
  shadows: {
    xs: "0px 1px 2px rgba(16, 24, 40, 0.05);",
    sm: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);",
    md: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    lg: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);",
    xl: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);",
    xxl: "0px 24px 48px -12px rgba(16, 24, 40, 0.18);",
    xxxl: "0px 32px 64px -12px rgba(16, 24, 40, 0.14);",
  },
  blurs: {
    // to use with backdrop-filter
    sm: 'blur(4px);',
    md: 'blur(8px);',
    lg: 'blur(12px);',
    xl: 'blur(20px);',
  },
  colors: {
    primary: {
      i25: '#F5FBFF',
      i50: '#F0F9FF',
      i100: '#E0F2FE',
      i200: '#B9E6FE',
      i300: '#7CD4FD',
      i400: '#36BFFA',
      i500: '#0BA5EC',
      i600: '#0086C9',
      i700: '#026AA2',
      i800: '#065986',
      i900: '#0B4A6F',
    },
    secondary: {
      i25: '#E3EAFF',
      i50: '#ABB7DA',
      i100: '#7985A8',
      i200: '#515D80',
      i300: '#3D496C',
      i400: '#333F62',
      i500: '#293558',
      i600: '#1F2B4E',
      i700: '#152144',
      i800: '#0B173A',
      i900: '#010D30',
    },
    tertiary: {
      i25: '#FFFBF0',
      i50: '#FFF5DE',
      i100: '#FFEEC5',
      i200: '#FFE6AB',
      i300: '#FFDF92',
      i400: '#FFD778',
      i500: '#FFC845',
      i600: '#FFB400',
      i700: '#E5A200',
      i800: '#CC9000',
      i900: '#B27E00',
    },
    error: {
      i25: '#FFFBFA',
      i50: '#FEF3F2',
      i100: '#FEE4E2',
      i200: '#FECDCA',
      i300: '#FDA29B',
      i400: '#F97066',
      i500: '#F04438',
      i600: '#D92D20',
      i700: '#B42318',
      i800: '#912018',
      i900: '#7A271A',
    },
    warning: {
      i25: '#FFFCF5',
      i50: '#FFFAEB',
      i100: '#FEF0C7',
      i200: '#FEDF89',
      i300: '#FEC84B',
      i400: '#FDB022',
      i500: '#F79009',
      i600: '#DC6803',
      i700: '#B54708',
      i800: '#93370D',
      i900: '#7A2E0E',
    },
    success: {
      i25: '#F6FEF9',
      i50: '#ECFDF3',
      i100: '#D1FADF',
      i200: '#A6F4C5',
      i300: '#6CE9A6',
      i400: '#32D583',
      i500: '#12B76A',
      i600: '#039855',
      i700: '#027A48',
      i800: '#05603A',
      i900: '#054F31',
    },
    information: {
      i25: '#F5FAFF',
      i50: '#EFF8FF',
      i100: '#D1E9FF',
      i200: '#B2DDFF',
      i300: '#84CAFF',
      i400: '#53B1FD',
      i500: '#2E90FA',
      i600: '#1570EF',
      i700: '#175CD3',
      i800: '#1849A9',
      i900: '#194185',
    },
    grey: {
      i25: '#FCFCFD',
      i50: '#F9FAFB',
      i100: '#F2F4F7',
      i200: '#EAECF0',
      i300: '#D0D5DD',
      i400: '#98A2B3',
      i500: '#667085',
      i600: '#475467',
      i700: '#344054',
      i800: '#1D2939',
      i900: '#101828',
    },
    blueGrey: {
      i25: '#FCFCFD',
      i50: '#F8F9FC',
      i100: '#EAECF5',
      i200: '#D5D9EB',
      i300: '#AFB5D9',
      i400: '#717BBC',
      i500: '#4E5BA6',
      i600: '#3E4784',
      i700: '#363F72',
      i800: '#293056',
      i900: '#101323',
    },
    indigo: {
      i25: '#F5F8FF',
      i50: '#EEF4FF',
      i100: '#E0EAFF',
      i200: '#C7D7FE',
      i300: '#A4BCFD',
      i400: '#8098F9',
      i500: '#6172F3',
      i600: '#444CE7',
      i700: '#3538CD',
      i800: '#2D31A6',
      i900: '#2D3282',
    },
    purple: {
      i25: '#FAFAFF',
      i50: '#F4F3FF',
      i100: '#EBE9FE',
      i200: '#D9D6FE',
      i300: '#BDB4FE',
      i400: '#9B8AFB',
      i500: '#7A5AF8',
      i600: '#6938EF',
      i700: '#5925DC',
      i800: '#4A1FB8',
      i900: '#3E1C96',
    },
    pink: {
      i25: '#FEF6FB',
      i50: '#FDF2FA',
      i100: '#FCE7F6',
      i200: '#FCCEEE',
      i300: '#FAA7E0',
      i400: '#F670C7',
      i500: '#EE46BC',
      i600: '#DD2590',
      i700: '#C11574',
      i800: '#9E165F',
      i900: '#851651',
    },
    rose: {
      i25: '#FFF5F6',
      i50: '#FFF1F3',
      i100: '#FFE4E8',
      i200: '#FECDD6',
      i300: '#FEA3B4',
      i400: '#FD6F8E',
      i500: '#F63D68',
      i600: '#E31B54',
      i700: '#C01048',
      i800: '#A11043',
      i900: '#89123E',
    },
    orange: {
      i25: '#FFFAF5',
      i50: '#FFF6ED',
      i100: '#FFEAD5',
      i200: '#FDDCAB',
      i300: '#FEB273',
      i400: '#FD853A',
      i500: '#FB6514',
      i600: '#EC4A0A',
      i700: '#C4320A',
      i800: '#9C2A10',
      i900: '#7E2410',
    },
    white: '#FFF',
    // To be removed once the DS is implemented
    legacyGrey: '#343A40',
  },
  radius: {
    big: '8px'
  },
};

export default rkvstTheme;
