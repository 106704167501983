import { EllipsedLabel } from '@ui/components'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { PrimaryImage } from '../../../common/PrimaryImage'
import i18n from '../../../i18n'
import { RkvstAsset, RkvstEvent } from '../../../state/initialState'
import Img from '../../Img'

type Props = {
  identity: string
  asset?: RkvstAsset
  event?: RkvstEvent
  displayName: string
  displayID: string
  displayType: string
  showAsLink?: boolean
}

const MapPinInfoText = styled(EllipsedLabel)`
  max-width: 230px;
  vertical-align: middle;
`

const MapPinInfo = styled.aside`
  display: inline-block;
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;

  p {
    margin: 8px 0;
  }
`

const MapPinLabel = styled.strong`
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
  width: 50px;
`

export const getPopupElement = () => {
  const ll = document.getElementById('pin-tooltip')
  return ll?.firstChild as HTMLElement
}

const MapPin: React.FC<Props> = (props) => {
  const renderPin = () => (
    <>
      <PrimaryImage asset={props.asset} event={props.event} qrcallback={null} />
      <MapPinInfo data-test="map-dialog-info">
        <p>
          <MapPinLabel>{i18n.t('Name')}:</MapPinLabel>
          <MapPinInfoText>{props.displayName}</MapPinInfoText>
        </p>
        <p>
          <MapPinLabel>{i18n.t('ID')}:</MapPinLabel>
          <MapPinInfoText className="id">{props.displayID}</MapPinInfoText>
        </p>
        <p>
          <MapPinLabel>{i18n.t('Type')}:</MapPinLabel>
          <MapPinInfoText id="asset_location_popup_type">{props.displayType}</MapPinInfoText>
        </p>
      </MapPinInfo>
    </>
  )

  return (
    <div>
      {props.showAsLink ? (
        <Link id={'infocontent'} to={`/${props.identity}`}>
          {renderPin()}
          <Img name="arrow" alt="Arrow" className="arrow" />
        </Link>
      ) : (
        renderPin()
      )}
    </div>
  )
}

export default MapPin
