import { trackEvent } from '@app/utils/userStats'
import { RKVSTSwitch } from '@ui/components'
import arrayMutators from 'final-form-arrays'
import forOwn from 'lodash/forOwn'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { Asset, CreateAsset } from '../../../api/resources/assets'
import suggestions from '../../../api/resources/suggestions'
import { withAlertsContext } from '../../../common/Alerts'
import { AlertData } from '../../../common/Alerts/Alerts.types'
import profileData from '../../../data/profile_data.json'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { SuggestionsKey } from '../../../hooks/hooks.types'
import useLoadSuggestedDataAssets from '../../../hooks/useLoadSuggestedAssets'
import i18n from '../../../i18n'
import actionCreators from '../../../state/actionCreators'
import { ReduxState, RkvstAsset } from '../../../state/initialState'
import { RootState } from '../../../state/store'
import ExtendedAttributes from '../../Form/ExtendedAttributes'
import InputCheckbox from '../../Form/InputCheckbox'
import InputSelect from '../../Form/InputSelect'
import InputText from '../../Form/InputText'
import InputTextArea from '../../Form/InputTextArea'
import InputTypeAheadSelect from '../../Form/InputTypeAheadSelect'
import ProfileAttributes from '../../Form/ProfileAttributes'
import SelectLocation from '../../Form/SelectLocation'
import Img from '../../Img'
import { withLoadingContext } from '../../Loading'
import { Container } from './AssetForm.style'
import { AssetFormProps } from './AssetForm.types'
import { InputItemType } from './AssetForm.types'
import UploadAttachmentsModal from './components/UploadAttachmentsModal'

const trim = (data: any) => {
  const trimmed: any = {}

  forOwn(data, (value: any, key: string) => (trimmed[key] = value?.trim ? value.trim() : value))
  return trimmed
}

export const buildReqBodyCreate = (data: any, attachmentsData: any) => {
  const asset = new CreateAsset().setData({
    attributes: {
      arc_description: data.description,
      arc_display_type: data.type,
      arc_display_name: data.display_name,
    },
    chain_id: '',
    public: data.public,
    behaviours: ['RecordEvidence'],
    proof_mechanism: data.proof_mechanism || 'MERKLE_LOG',
  })

  if (data.location) {
    asset.addAttribute('arc_home_location_identity', data.location.identity)
  }
  if (data.profiles && data.profiles !== i18n.t('assets:modal.none')) {
    asset.addAttribute('arc_profile', data.profiles)
  }

  Object.keys(data).forEach((key: string) => {
    const obj = data[key]
    if (obj && obj.key && obj.profiles !== i18n.t('assets:modal.none')) {
      asset.addAttribute(obj.key, obj.value)
    }
  })

  if (data.extended_attributes) {
    data.extended_attributes.forEach(({ type, key, value }: { type: string; key: string; value: any }) => {
      if (type == 'keyvalue') {
        if (key?.length > 0 && value?.length > 0) {
          asset.addAttribute(key, value)
        }
      }
    })
  }

  // attachments added - loop through retrived attachments data
  asset.addAttachments(attachmentsData)

  return asset.getData()
}

const buildInitialValues = () => getProfileAttributes()

const getProfileAttributes = (profile?: string) => {
  const profileAttributes = { asset_attributes: {} }
  if (profile && profile in profileData) {
    if (Object.keys(profileData[profile])[0]) {
      const data = profileData[profile][Object.keys(profileData[profile])[0]]
      const attributes = data.asset_attributes
      if (attributes) {
        Object.keys(attributes).forEach((key: string) => {
          if (attributes[key]) profileAttributes.asset_attributes[key] = attributes[key]
        })
      }
    }
  }

  return profileAttributes
}

const getProfileRequiredAttributes = (profile: string, profileAttributes: any) => {
  const assetAttributeKeys = Object.keys(profileAttributes.asset_attributes).filter((key: string) => {
    return profileAttributes.asset_attributes[key]?.mandatory
  })
  return [...assetAttributeKeys]
}

// Those are profiles not been used for regular asset creation
const BANNED_PROFILES: string[] = ['Document']

const Presentation: React.FC<AssetFormProps> = (props) => {
  const [initialValues] = useState(buildInitialValues())
  const [showProfileAttributes, setShowProfileAttributes] = useState(false)
  const [profileRequiredAttributes, setProfileRequiredAttributes] = useState<any[]>([])
  const [assetTypeOptions, setAssetTypeOptions] = useState<string[]>([])
  const [uploadModalShow, setUploadModalShow] = useState<boolean>(false)
  const [formData, setFormData] = useState(null)
  const [attachmentsData, setAttachmentsData] = useState<{ key: string; file: any }[]>([])
  const [activeTab, setActiveTab] = useState(0)
  const { data: assetTypes, loadSuggestedData: loadSuggestedAssetTypes } = useLoadSuggestedDataAssets(
    SuggestionsKey.ARC_DISPLAY_TYPE
  )

  const editMode = !!props.asset

  const dispatch = useAppDispatch()
  const simpleHashCapReached = useAppSelector((state: RootState) => state.assets.simpleHashCapReached)
  const merkleLogCapReached = useAppSelector((state: RootState) => state.assets.merkleLogCapReached)

  useEffect(() => {
    dispatch(actionCreators.assets.getCaps())
  }, [])

  useEffect(() => {
    setAssetTypeOptions(assetTypes)
  }, [assetTypes])

  const getProfiles = (): InputItemType[] => {
    const data: InputItemType[] = Object.keys(profileData)
      .map((value: string): InputItemType => {
        return { key: value, value }
      })
      .filter((profile) => !BANNED_PROFILES.includes(profile.value))

    const it: InputItemType = { key: i18n.t('assets:modal.none'), value: i18n.t('assets:modal.none') }

    data.unshift(it)
    return data
  }

  const validate = (data: any) => {
    data = trim(data)
    const errors: any = {}
    const allRequiredFields = [...profileRequiredAttributes, ...['display_name', 'type']]

    allRequiredFields.forEach((field) => {
      if (!data.hasOwnProperty(field) || data[field] === '') {
        errors[field] = i18n.t('globals:form.this_field_is_required')
      }
    })
    return errors
  }

  const handleProfileChange = (event: string, form: any) => {
    const profileName = form.getState().values.profiles || event
    form.mutators.setCustomAttributes(profileName)
  }

  const getListOfAttachments = (formData: any): { key: string; file: any }[] => {
    let result = []
    if (formData?.extended_attributes) {
      const filtered = formData.extended_attributes.filter((item: any) => {
        return item.type === 'attachment' && item.value && item.value !== ''
      })

      if (filtered.length && filtered[0].value) {
        result = filtered.map((item: any) => {
          return {
            key: item.key,
            file: item.value,
          }
        })
      }
    }
    return result
  }

  const createAsset = (attachments: any, _formData?: any) => {
    const result = buildReqBodyCreate(_formData || formData, attachments)
    if (result !== null) {
      dispatch(actionCreators.assets.create(result, props.setAlert, props.setLoading, props.onCreateError))
    }
  }

  const closeModals = () => {
    setUploadModalShow(false)
    props.onClose()
  }

  const closeAttachmentsModal = () => {
    setUploadModalShow(false)
  }

  const errorUploadingAttachments = (errorMessage: string) => {
    props.setAlert({
      type: 'error',
      message: errorMessage,
      timeout: 6000,
    })
    setUploadModalShow(false)
  }

  return (
    <Form
      onSubmit={async (data: any) => {
        const files: { key: string; file: any }[] = getListOfAttachments(data)

        if (files.length) {
          // show upload modal
          setUploadModalShow(true)
          // pass attachments to attachments modal
          setAttachmentsData(files)
          setFormData(data)
        } else {
          trackEvent('AssetRegistered', {})
          createAsset([], data)
          closeModals()
          props.onSubmit && props.onSubmit()
        }
      }}
      mutators={{
        ...arrayMutators,
        setCustomAttributes: ([profile], state, utils) => {
          if (profile) {
            if (profile !== i18n.t('assets:modal.none')) {
              const profileAttributes = getProfileAttributes(profile)
              setProfileRequiredAttributes(getProfileRequiredAttributes(profile, profileAttributes))
              setShowProfileAttributes(!!Object.keys(profileAttributes?.asset_attributes)?.length)
              utils.changeValue(state, 'asset_attributes', () => profileAttributes.asset_attributes)
            } else {
              setProfileRequiredAttributes([])
              setShowProfileAttributes(false)
            }
          } else {
            setProfileRequiredAttributes([])
          }
        },
      }}
      validate={validate}
      initialValues={{ proof_mechanism: 'MERKLE_LOG', ...initialValues }}
    >
      {({ form, handleSubmit, values }) => (
        <form
          data-test={props.testTag}
          onSubmit={(ev) => {
            if (form.getState()?.invalid && form.getState().errors?.display_name) {
              setActiveTab(0)
            }
            if (form.getState()?.invalid && form.getState().errors?.type) {
              setActiveTab(0)
            }
            return handleSubmit(ev)
          }}
          noValidate
          autoComplete="off"
        >
          <Container className="asset-form">
            <Tabs selectedIndex={activeTab} onSelect={(index) => setActiveTab(index)}>
              <TabList>
                <Tab data-test="asset-info">{i18n.t('assets:modal.asset_information')}</Tab>
                <Tab data-test="extended-attrs">{i18n.t('assets:modal.advanced_options')}</Tab>
              </TabList>
              <TabPanel>
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <Field name="display_name">
                        {(fieldProps) => (
                          <InputText
                            id="display_name"
                            label={i18n.t('assets:modal.name').toString()}
                            helpText={i18n.t('assets:modal.asset_name.help').toString()}
                            placeholder={i18n.t('assets:modal.name').toString()}
                            disabled={editMode}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                      <Field name="type">
                        {(fieldProps) => (
                          <InputTypeAheadSelect
                            id="display_type"
                            label={i18n.t('assets:modal.asset_type').toString()}
                            helpText={i18n.t('assets:modal.asset_type.help').toString()}
                            disabled={editMode}
                            options={assetTypeOptions}
                            loadNewOptions={loadSuggestedAssetTypes}
                            placeholder={i18n.t('assets:modal.asset_type').toString()}
                            clearOptions={() => setAssetTypeOptions([])}
                            {...fieldProps}
                          />
                        )}
                      </Field>

                      <Field name="public" type="checkbox">
                        {(fieldProps) => (
                          <RKVSTSwitch
                            id="make_public"
                            labelText={i18n.t('assets:modal.make_public').toString()}
                            helpText={i18n.t('assets:modal.make_public.help').toString()}
                            {...fieldProps}
                          ></RKVSTSwitch>
                        )}
                      </Field>
                    </div>
                    <div className="col-md-6 right-column">
                      <Field name="description">
                        {(fieldProps) => (
                          <InputTextArea
                            label={i18n.t('assets:modal.description').toString()}
                            helpText={i18n.t('assets:modal.description.help').toString()}
                            id="description"
                            className="large"
                            disabled={editMode}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                      <Field name="proof_mechanism">
                        {(fieldProps) => (
                          <InputSelect
                            id="proof_mechanism"
                            label={i18n.t('assets:modal.proof_mechanism').toString()}
                            helpText={i18n.t('assets:modal.proof_mechanism.help').toString()}
                            options={[{ key: 'MERKLE_LOG', value: 'Merkle Log', disabled: merkleLogCapReached }]}
                            defaultValue={'MERKLE_LOG'}
                            disabled={editMode}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div data-test="advanced-tab">
                  <div className="row">
                    {props.locations.length ? (
                      <div className="col-md-6" data-test="location-field">
                        <Field name="location">
                          {(fieldProps) => (
                            <SelectLocation
                              id="location"
                              label={i18n.t('assets:modal.asset_location').toString()}
                              helpText={i18n.t('assets:modal.asset_location.help').toString()}
                              placeholder={i18n.t('assets:modal.name_description').toString()}
                              locations={props.locations}
                              disabled={editMode}
                              {...fieldProps}
                            />
                          )}
                        </Field>
                      </div>
                    ) : null}
                    <div className={props.locations.length ? 'col-md-6' : 'col-md-12'}>
                      <Field name="profiles">
                        {(fieldProps) => (
                          <InputSelect
                            id="profiles"
                            label={i18n.t('assets:modal.profile').toString()}
                            helpText={i18n.t('assets:modal.profile.help').toString()}
                            options={getProfiles()}
                            onSelect={(event: string) => handleProfileChange(event, form)}
                            defaultValue={i18n.t('assets:modal.none').toString()}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="row">
                    {showProfileAttributes && (
                      <div className={'col-md-12'}>
                        <h3>Profile Attributes </h3>
                        <hr />
                        <ProfileAttributes disabled={editMode} fieldArrayName="asset_attributes" />
                        <hr />
                      </div>
                    )}

                    <div className="col-md-12">
                      <label>Extended attributes </label>
                      <span className="helpText">{i18n.t('assets:modal.extended_attributes.help')}</span>
                      <ExtendedAttributes
                        suggestionsGetter={suggestions.suggestAssetAttributes.bind(suggestions)}
                        keyHeading={i18n.t('form:extended_attributes.attributes').toString()}
                        type={'extended_attributes'}
                        threshold={1}
                        disabled={editMode}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>

            <div className="row">
              <div className="col-md-12 text-right buttons">
                {!props.asset ? (
                  <button className="button-action" id="create_asset_button" type="submit">
                    <Img name="Register-Asset-Icon-White" alt="Asset" />
                    <span className="text-vertical-middle">{i18n.t('assets:modal.create_asset')}</span>
                  </button>
                ) : (
                  <>
                    <button className="button-action" id="save_asset_changes_button" type="submit">
                      <Img name="save-icon" alt="Save" />
                      <span className="text-vertical-middle">{i18n.t('assets:modal.save_changes')}</span>
                    </button>
                    <button
                      className="button-action button-action-cancel"
                      onClick={() => {
                        props.onClose()
                      }}
                    >
                      <span className="text-vertical-middle">{i18n.t('assets:modal.cancel')}</span>
                    </button>
                  </>
                )}
              </div>
            </div>
            {uploadModalShow && (
              <UploadAttachmentsModal
                attachmentsData={attachmentsData}
                create={createAsset}
                closeAttachmentsModal={closeAttachmentsModal}
                closeModals={closeModals}
                errorUploadingAttachments={errorUploadingAttachments}
                uploadAttachmentsModalVisible={uploadModalShow}
              />
            )}
          </Container>
        </form>
      )}
    </Form>
  )
}

export const mapState = (state: ReduxState) => {
  // until a refactor done, this method is needed in the types file.
  return {}
}
// TODO: Where you have "createAsset" execute dispatch
export const mapDispatch = (dispatch: Dispatch<any>) => ({
  createAsset: (data: RkvstAsset, setAlert: (p: AlertData) => void, setLoading: Dispatch<SetStateAction<boolean>>) =>
    dispatch(actionCreators.assets.create(data, setAlert, setLoading)),
  createEvent: (assetIdentity: string, data: any) => dispatch(actionCreators.assets.createEvent(assetIdentity, data)),
  getCaps: actionCreators.tenancies.getCaps,
})

const AssetForm = connect(mapState, mapDispatch)(withLoadingContext(withAlertsContext(Presentation)))

export default AssetForm
