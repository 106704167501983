import { initialState as pagesConfiguration } from '@app/pages/configuration.slice'
import PagesConfiguration from '@app/pages/configuration.types'
import { OnboardingSample } from '@app/pages/OnboardingSuccess/OnboardingSuccess.types'
import { createSelector, SerializedError } from '@reduxjs/toolkit'
import {
  DictAttributeValue,
  RkvstAccessPermission,
  RkvstAccessPolicy,
  RkvstAppRegistration,
  RkvstAsset,
  RkvstAssetAttributes,
  RkvstAttributes,
  RkvstAttributeValue,
  RkvstCreateAsset,
  RkvstCreateEvent,
  RkvstEvent,
  RkvstEventAttributes,
  RkvstIdentityProvider,
  RkvstLocation,
  RkvstLocationPermissions,
  RkvstNode,
  RkvstPrincipal,
  RkvstSubject,
  RkvstTlsCaCertificate,
  RkvstUser,
  RkvstUserRole,
  ROLES,
  TrackingStatus,
} from '@ui/types'

// TODO: ideally we shold replace everywhere that is using
// import { MyType } from 'some_path/initialState'
// by
// import { MyType } from '@ui/types'
// to be done after permissioned view
export {
  DictAttributeValue,
  RkvstAccessPermission,
  RkvstAccessPolicy,
  RkvstAppRegistration,
  RkvstAsset,
  RkvstAssetAttributes,
  RkvstAttributes,
  RkvstAttributeValue,
  RkvstCreateAsset,
  RkvstCreateEvent,
  RkvstEvent,
  RkvstEventAttributes,
  RkvstIdentityProvider,
  RkvstLocation,
  RkvstLocationPermissions,
  RkvstNode,
  RkvstPrincipal,
  RkvstSubject,
  RkvstTlsCaCertificate,
  RkvstUser,
  RkvstUserRole,
  ROLES,
  TrackingStatus,
}

interface StateList<T> {
  list: T[]
}

interface RkvstFilter<T> {
  list: T[]
  nextPageToken: string | null
  currentFilterConditions: any[]
  // used for when we manually add things to the filtered view
  // that may not fit the current filters, such as when notifications come through.
  // If this flag is set the UI can display a message to the
  // user that they might want to reapply their filters.
  expired?: boolean
}
export interface RkvstFiltered<T> {
  filtered: RkvstFilter<T>
}

interface CapReached {
  capReached: boolean
}

interface Assets extends StateList<RkvstAsset>, RkvstFiltered<RkvstAsset> {
  hasError: boolean
  loading?: boolean
  loaded?: boolean
  simpleHashCapReached: boolean
  merkleLogCapReached: boolean
  // For high performance watchers over the assets array
  // the list of assets can be quite big and in some components
  // we might want to watch change sin this list in the hooks.
  // this might bring some impact to the performance of react.
  // because of that we add a timestamp which will be updated
  // everytime that the list itself is mutated and therfore
  // the hooks will just need to watch this timestamp as opose
  // to the whole list of assets
  lastUpdate?: number
}

interface CurrentAssetEvents {
  asset: RkvstAsset | null
  events: RkvstEvent[]
  nextPageToken: string | null
}

interface Archivistnode {
  node: RkvstNode | undefined
}

export interface Whoami {
  principal: RkvstPrincipal | undefined
  roles: RkvstUserRole[] | undefined
  avatarUrl: string | undefined
  err: number | undefined
  tenantid: string | undefined
  is_social: boolean | undefined
  tier: string | undefined
  expired: boolean | undefined
}

export interface Sample {
  sampleType: OnboardingSample

  // Not sure if these belong here or up one level
  isAssetLoading: boolean
  sampleReady: boolean

  asset?: RkvstAsset
  events: RkvstEvent[]
}

export interface OnboardingState {
  application: RkvstAppRegistration | undefined
  applicationError: SerializedError | undefined
  awaitingApplication: boolean
  awaitingAccessPolicy: boolean
  accessPolicy: RkvstAccessPolicy | undefined

  // Migrating to createEntityAdapter would tidy this up, reduce our code etc.
  samples: {
    DocumentLineage: Sample
    SBOM: Sample
    C2PA: Sample
    TrackAndTrace: Sample
  }
}

interface LoadingState {
  loading?: boolean
  error?: boolean | Error
}
interface Locations extends StateList<RkvstLocation>, RkvstFiltered<RkvstLocation>, CapReached, LoadingState {}

export type ReduxState = {
  events: StateList<RkvstEvent> & RkvstFiltered<RkvstEvent>
  assets: Assets
  currentAssetEvents: CurrentAssetEvents
  locations: Locations
  archivistnode: Archivistnode
  //whoami: Whoami
  subjects: StateList<RkvstSubject>
  identityProviders: StateList<RkvstIdentityProvider>
  rootPrincipals: StateList<any>
  sampleSets: StateList<string>
  appRegistrations: StateList<RkvstAppRegistration> & RkvstFiltered<RkvstAppRegistration> & CapReached & LoadingState
  accessPolicies: CapReached
  compliancePolicies: CapReached
  users: StateList<RkvstUser>
  blobs: CapReached
  onboarding: OnboardingState
  dropboxConnection: DropboxConnection
  pagesConfiguration: PagesConfiguration
}

interface dboxConn {
  dropbox_folders?: string[]
  dropbox_id: string
  client_id: string
}
export interface DropboxConnection extends LoadingState {
  connected?: boolean
  connection?: dboxConn
}

export const emptyFilter = () => ({
  list: [],
  nextPageToken: null,
  currentFilterConditions: [],
  expired: false,
})

export default (selfsub?: RkvstSubject): ReduxState => ({
  events: {
    list: [],
    filtered: emptyFilter(),
  },
  assets: {
    list: [],
    hasError: false,
    filtered: emptyFilter(),
    simpleHashCapReached: false,
    merkleLogCapReached: false,
  },
  currentAssetEvents: {
    asset: null,
    events: [],
    nextPageToken: null,
  },
  locations: {
    list: [],
    filtered: emptyFilter(),
    capReached: false,
  },
  archivistnode: { node: undefined },
  subjects: {
    list: selfsub ? [selfsub] : [],
  },
  identityProviders: {
    list: [],
  },
  rootPrincipals: {
    list: [],
  },
  sampleSets: {
    list: [],
  },
  appRegistrations: {
    list: [],
    filtered: emptyFilter(),
    capReached: false,
  },
  accessPolicies: {
    capReached: false,
  },
  compliancePolicies: {
    capReached: false,
  },
  users: {
    list: [],
  },
  blobs: {
    capReached: false,
  },
  onboarding: {
    // TODO: We shouldn't be required to define this here, when slices already handle initial values
    application: undefined,
    applicationError: undefined,
    awaitingApplication: true,
    awaitingAccessPolicy: true,
    accessPolicy: undefined,

    // Using an array is safer than object properties.
    // This approach isn't really necessary, wipe the state
    // on button click.
    samples: {
      DocumentLineage: {
        asset: undefined,
        sampleType: 'DocumentLineage',
        isAssetLoading: true,
        sampleReady: false,
        events: [],
      },
      SBOM: {
        sampleType: 'SBOM',
        isAssetLoading: true,
        sampleReady: false,
        events: [],
      },
      C2PA: {
        sampleType: 'C2PA',
        isAssetLoading: true,
        sampleReady: false,
        events: [],
      },
      TrackAndTrace: {
        sampleType: 'TrackAndTrace',
        isAssetLoading: true,
        sampleReady: false,
        events: [],
      },
    },
  },
  dropboxConnection: {},
  pagesConfiguration,
})
