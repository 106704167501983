import { DropZone } from '@ui/components'
import React from 'react'

import { Container } from './Attachments.style'
import { AttachmentsProps } from './Attachments.types'

const AttachmentsComponent: React.FC<AttachmentsProps> = (props) => {
  return (
    <Container>
      {props.name && <label htmlFor="id">{props.name}</label>}
      <DropZone
        className="drop-attachment"
        variant="inline"
        files={props.attachments}
        onDrop={({ file }) => {
          const newAttchments = [...props.attachments, file]
          props.input.onChange(props.allowmultiple ? newAttchments : file)
        }}
        onClear={(file) => {
          if (file) {
            const newAttchments = [...props.attachments].filter((attachment) => attachment !== file)
            props.input.onChange(props.allowmultiple ? newAttchments : undefined)
          }
        }}
        {...props}
      />
    </Container>
  )
}

const Attachments = AttachmentsComponent
export default Attachments
