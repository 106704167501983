import { StyledH1, StyledH2, StyledH3, StyledH4 } from './Typography.styles'
import { TextProps } from './Typography.types'

/**
 * H is a multipurpose heading component. Sizes correspond to header levels like so:
 *   lg = h1
 *   md = h2
 *   sm = h3
 *   xs = h4
 */
export const H = ({ weight = 'medium', sz = 'md', className = '', testTag = '', children }: TextProps) => {
  switch (sz) {
    case 'lg':
      return (
        <StyledH1 weight={weight} className={className} data-test={testTag}>
          {children}
        </StyledH1>
      )
    case 'md':
      return (
        <StyledH2 weight={weight} className={className} data-test={testTag}>
          {children}
        </StyledH2>
      )
    case 'sm':
      return (
        <StyledH3 weight={weight} className={className} data-test={testTag}>
          {children}
        </StyledH3>
      )
    case 'xs':
      return (
        <StyledH4 weight={weight} className={className} data-test={testTag}>
          {children}
        </StyledH4>
      )
  }
}
