import { Reducer } from 'redux'

import { apiAction } from '../actionCreators'
import initialState, { ReduxState, RkvstAsset, TrackingStatus } from '../initialState'

const mergeAssetIntoList = (asset: RkvstAsset, list: Array<RkvstAsset>) => {
  const mergedList = []
  let assetAlreadyInList = false

  for (let i = list.length - 1; i >= 0; i--) {
    const item = list[i]

    if (item.identity === asset.identity) {
      mergedList[i] = asset
      assetAlreadyInList = true
    } else {
      mergedList[i] = item
    }
  }

  if (assetAlreadyInList) {
    return mergedList
  }

  mergedList.unshift(asset)

  return mergedList
}

const setTracked = (id: string, assets: RkvstAsset[], tracked: TrackingStatus) => {
  const newAssets: RkvstAsset[] = []

  for (const asset of assets) {
    if (asset.identity === id) {
      newAssets.push({ ...asset, tracked })
    } else {
      newAssets.push({ ...asset })
    }
  }

  return newAssets
}

const reducer: Reducer<ReduxState['assets']> = (state = initialState().assets, action: any) => {
  switch (action.type) {
    case apiAction.fulfilled('api/assets/startTracking'): {
      // set the updated asset TRACKED in caches
      return {
        ...state,
        // For high performance watchers over the assets array
        lastUpdate: new Date().getTime(),
        list: setTracked(action.payload.asset_identity, state.list, 'TRACKED'),
        filtered: {
          ...state.filtered,
          list: setTracked(action.payload.asset_identity, state.filtered.list, 'TRACKED'),
        },
      }
    }

    case apiAction.fulfilled('api/assets/stopTracking'): {
      // set the updated asset NOT_TRACKED in caches
      return {
        ...state,
        list: setTracked(action.payload.asset_identity, state.list, 'NOT_TRACKED'),
        // For high performance watchers over the assets array
        lastUpdate: new Date().getTime(),
        filtered: {
          ...state.filtered,
          list: setTracked(action.payload.asset_identity, state.filtered.list, 'NOT_TRACKED'),
        },
      }
    }

    case apiAction.fulfilled('api/assets/getList'): {
      return {
        ...state,
        hasError: false,
        loading: false,
        loaded: true,
        list: action.payload.assets,
        // For high performance watchers over the assets array
        lastUpdate: new Date().getTime(),
      }
    }

    case apiAction.pending('api/assets/getList'):
      return {
        loading: true,
        ...state,
      }
      break

    case apiAction.failed('api/assets/getList'):
    case apiAction.rejected('api/assets/getList'):
      return {
        ...state,
        loaded: true,
        loading: false,
        hasError: true,
      }
      break

    case apiAction.fulfilled('api/assets/getListFiltered'): {
      const newAssets = action.payload.assets

      return {
        ...state,
        filtered: {
          ...state.filtered,
          // if this is a new page, append, otherwise if first page replace
          list: action.meta.nextPageToken ? [...state.filtered.list, ...newAssets] : newAssets,
          nextPageToken: action.payload.next_page_token || null,

          // fresh filtered data, so filters not expired
          expired: false,
        },
      }
    }
    case apiAction.fulfilled('api/assets/create'): {
      const asset = action.payload
      const list = [asset, ...state.list]
      const filteredList = [asset, ...state.filtered.list]

      return {
        ...state,
        list,
        // For high performance watchers over the assets array
        lastUpdate: new Date().getTime(),
        filtered: {
          ...state.filtered,
          list: filteredList,
          expired: true,
        },
      }
    }
    case 'notifications/event_and_asset':
    case 'notifications/asset': {
      // merge the asset into the cache
      const mergedList = mergeAssetIntoList(action.asset, state.list)
      const mergedFilterList = mergeAssetIntoList(action.asset, state.filtered.list)

      return {
        ...state,
        list: mergedList,
        // For high performance watchers over the assets array
        lastUpdate: new Date().getTime(),
        filtered: {
          ...state.filtered,
          list: mergedFilterList,
          // mark current filters expired, as the added asset may not match current filters
          expired: true,
        },
      }
    }

    case apiAction.fulfilled('api/assets/getCaps'): {
      const simpleHashCount = action.payload.caps.find(
        (element: { resource_type: string }) => element.resource_type == 'AssetsSimpleHash'
      ).resource_remaining
      const merkleLogCount = action.payload.caps.find(
        (element: { resource_type: string }) => element.resource_type == 'AssetsMerkleLog'
      ).resource_remaining

      return {
        ...state,
        simpleHashCapReached: simpleHashCount == '0',
        merkleLogCapReached: merkleLogCount == '0',
      }
    }

    case apiAction.failed('api/assets/getCaps'): {
      return {
        ...state,
        simpleHashCapReached: false,
        merkleLogCapReached: false,
      }
    }
  }
  return state
}

export default reducer
