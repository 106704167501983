import { LoadingCard, RouteContentPlaceHolder } from '@ui/components'
import { mainTheme } from '@ui/themes'
import styled from 'styled-components'

const MainPlacholderContainer = styled.article`
  text-align: center;
  width: 100vw;
  height: 100vh;
`

const SectionPlaceholderContainer = styled.article<{ height?: string }>`
  text-align: center;
  width: 100%;
  height: ${({ height }) => height || '100%'};
  padding: 15px;
`

export const SectionPlaceholder = (props: any) => {
  return (
    <SectionPlaceholderContainer {...props}>
      <LoadingCard height="100%">{props.children}</LoadingCard>
    </SectionPlaceholderContainer>
  )
}

const MenuLoadingContainer = styled(MainPlacholderContainer)`
  padding: 12px;
  display: grid;
  grid-template-columns: ${mainTheme.layout.navWidth}px 1fr;
  grid-template-rows: ${mainTheme.layout.headerHeight}px 1fr;
  gap: 10px;
  grid-template-areas:
    'header header'
    'content content';

  @media (min-width: 1025px) {
    grid-template-areas:
      'header header'
      'sidebar content';

    .sidebar.sidebar {
      display: block;
    }
  }

  .header {
    grid-area: header;
  }

  .sidebar {
    grid-area: sidebar;
    display: none;
  }

  .content {
    grid-area: content;
  }
`

export const MenuPlaceholder = (props: any) => {
  return (
    <MenuLoadingContainer {...props}>
      <LoadingCard height="auto" className="header" />
      <LoadingCard height="100%" className="sidebar" />
      <LoadingCard height="100%" className="content" />
    </MenuLoadingContainer>
  )
}

export const LoadingContainer = styled.article`
  width: 100%;
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
export const StyledRouteContentPlaceHolder = styled(RouteContentPlaceHolder)`
  flex-grow: 0;
  width: 50%;
  min-width: 300px;
`
