import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { Store } from 'redux'
import { configurePendingEffects } from 'redux-pending-effects'

import subjectsResource from '../api/resources/subjects'
import initialState, { ReduxState } from './initialState'
import rootReducer from './reducers'
import reducer from './reducers'

const preloadDataForInitialState = async () => {
  // Preload whoami self subject into initial state before rendering app
  //
  // Reason - so we no need to show flash of content while we figure out what the user
  // can see based on user role and various properties of backend
  // skip loading data if logged out
  const loggedOut = window.location.pathname.startsWith('/logged-out')
  if (loggedOut) {
    return {}
  }

  const self_identity = 'subjects/00000000-0000-0000-0000-000000000000'
  let initialSelfSubjectData
  const subjectsPromise: Promise<any> = subjectsResource
    .getOne(self_identity)
    .then((self) => {
      initialSelfSubjectData = self
    })
    .catch((err) => {
      initialSelfSubjectData = {
        err: err.response ? err.response.status : 401, // default to 401 for all other types of error getting subject
      }
    })

  // wait for all the promises to complete
  await Promise.allSettled([subjectsPromise])

  return {
    initialSelfSubjectData,
  }
}

export const createStore = async (): Promise<Store<ReduxState>> => {
  let data: any
  try {
    data = await preloadDataForInitialState()
  } catch (e) {
    console.error(e)
  }

  const { middlewares } = configurePendingEffects({
    promise: true,
    toolkit: true,
  })

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [...middlewares, ...getDefaultMiddleware()],
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState(data?.initialSelfSubjectData),
  })

  return store
}

export type RootState = ReturnType<typeof reducer>
