import styled from 'styled-components'

import { layout, theme } from '../../../Css'

export const Container = styled.div`
  & .button-action-cancel {
    margin-left: 10px;
  }

  & .row {
    border: 0px;
  }

  & .right-column {
    @media (min-width: ${layout.grid.min.sm}px) {
      border-left: 1px solid ${theme.colors.soft_highlight};
      padding-left: 25px;
    }
  }

  & .make-public-checkbox {
    .helpText {
      font-size: ${theme.typography.text.fontSize};
      line-height: ${theme.typography.text.lineHeight};
      font-weight: 500;
      letter-spacing: 0em;
      white-space: normal;
    }
    input {
      margin-top: 0px;
      margin-left: 2rem;
      padding: 0px;
      width: auto;
      height: auto;
    }
  }
`
