import { css } from 'styled-components'

import { listDropdown } from './FormInputs'

/**
 * This file is to add all the third part dependencies css implementations
 * for the DataTrails UI. Placed in a spearated file to make it easier to be remove
 * in case we change the libraries
 */

export const headlessCombobox = css`
  .headlessui-drop-down {
    ${listDropdown}
  }
`

export const headlessComboboxTable = css`
  .headlessui-drop-down.headlessui-drop-down {
    width: 90%;
    margin-top: -8px;
    margin-left: 5px;
  }
`
