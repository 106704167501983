export enum ROLES {
  administrator = "archivist_administrator",
  user = "archivist_user",
}

type ConfirmationStatus = "CONFIRMED" | "COMMITTED" | "PENDING" | "FAILED"
type ProofMechanism = "SIMPLE_HASH" | "KHIPU" | "MERKLE_LOG"
export type TrackingStatus = "TRACKED" | "NOT_TRACKED"

export type RkvstEvent = {
  identity: string
  tenant_identity: string
  asset_identity: string
  timestamp_declared: string
  timestamp_accepted: string
  transaction_id: string
  block_number?: number 
  operation: string
  confirmation_status: ConfirmationStatus
  from: string
  behaviour: string
  principal_declared: RkvstPrincipal
  principal_accepted: RkvstPrincipal
  asset_attributes: RkvstAttributes
  event_attributes: RkvstAttributes
  merklelog_entry?: MerklelogEntry
}
export interface RkvstCreateAsset {
  attributes: RkvstAttributes
  behaviours: string[]
  chain_id: string
  proof_mechanism: ProofMechanism
  public: boolean
}
export interface RkvstAsset extends RkvstCreateAsset {
  identity: string
  confirmation_status: ConfirmationStatus
  owner: string
  tenant_identity: string
  tracked: TrackingStatus
  at_time?: string
}

export interface RkvstCreateEvent {
  asset_attributes: RkvstAttributes
  event_attributes: RkvstAttributes
  principal_declared: RkvstPrincipal | undefined
  operation: string
  behaviour: string
  timestamp_declared: string | undefined
}

export type RkvstLocation = {
  identity: string
  display_name: string
  description: string
  latitude: number
  longitude: number
  attributes: RkvstAttributes
  tenant: string
  owner: string
}
export type RkvstNode = any
export type RkvstPrincipal = {
  display_name: string
  email: string
  issuer: string
  subject: string
}

export type RkvstLocationPermissions = string[]
export type RkvstIdentityProvider = any
export type RkvstEventAttributes = any
export type RkvstAssetAttributes = any

// CustomClaims are the custom claims for the app registration
export interface CustomClaims {
  [key: string]: string
}

export interface RkvstCreateAppRegistration {
  display_name: string
  custom_claims?: CustomClaims
}

export interface Credential {
  secret: string
  valid_from: string
  valid_until: string
}

export interface RkvstAppRegistration extends RkvstCreateAppRegistration {
  identity: string
  client_id: string
  tenant_id: string
  credentials: Credential[]
  dropbox_integrated?: boolean
}

export type RkvstAttributeValue =
  | undefined
  | string
  | DictAttributeValue
  | DictAttributeValue[]
  | RkvstAttachmentAttribute
export interface RkvstAttributes {
  document_document?: RkvstAttachmentAttribute
  [key: string]: RkvstAttributeValue
}

interface UserAttributes {
  or: string[]
}

export interface RkvstAttachmentAttribute {
  arc_attribute_type?: string
  arc_blob_identity?: string
  arc_blob_hash_alg?: string
  arc_blob_hash_value?: string
  arc_file_name?: string
  mime_type?: string
  [key: string]: string | undefined
}

export interface DictAttributeValue {
  [key: string]: string
}

export interface RkvstAccessPermission {
  subjects: string[]
  behaviours: string[]
  asset_attributes_read: string[]
  asset_attributes_write: string[]
  event_arc_display_type_read: string[]
  event_arc_display_type_write: string[]
  user_attributes: UserAttributes[]
}

export interface RkvstCreateAccessPolicy {
  display_name: string
  description: string
  filters: any[]
  access_permissions: RkvstAccessPermission[]
}

export interface RkvstAccessPolicy extends RkvstCreateAccessPolicy {
  identity: string
}

export type RkvstUserRole = ROLES.administrator | ROLES.user

export type RkvstSubject = {
  identity: string
  display_name: string
  wallet_address: string[]
  wallet_pub_key: string[]
  tessera_pub_key: string[]
  confirmation_status: string
  tenant: string
}

export type RkvstUser = any

export type RkvstTlsCaCertificate = {
  identity: string
  display_name: string
  tlscacertificate: string
}

export type UserTenant = {
  identity: string
  display_name: string
}

export type MerklelogEntry = {
  log_version: number
  log_epoch: number
  commit: MerkleLogCommitment
}

export type MerkleLogCommitment = {
  index: number
  leaf_index: number
  idtimestamp: string
}

export type RkvstResourceCap = {
  resource_type: string
  resource_cap: number
  resource_remaining: number
}

export type RkvstResourceCaps = {
  caps: RkvstResourceCap[]
}

export type TenancyCapsResponse = {
  limits: {
    AccessPolicies?: number
    AppRegistrations?: number
    AssetsKhipu?: number
    AssetsMerkleLog?: number
    AssetsPublic?: number
    AssetsSimpleHash?: number
    Blobs?: number
    BlobsSize?: number
    CompliancePolicies?: number
    EnterpriseSSO?: number
    Events?: number
    EventsPerMonth?: number
    Invites?: number
    Locations?: number
    TLSCACertificate?: number
    TenantUsers?: number
  }
}
