import { pickGap } from '@ui/themes'
import styled from 'styled-components'

export const ButtonGroup = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;

    button {
      border-radius: 0;
      margin-bottom: -1px; // Ensures a single-width border between buttons.
    }

    ${({ theme }) => {
      const r = pickGap(theme._v1, 'size2').px
      return `
        button:first-child {
          border-top-left-radius: ${r};
          border-top-right-radius: ${r};

          // Don't adjust left margin for first button.
          margin-left: 0px;
        }

        button:last-child {
          border-bottom-left-radius: ${r};
          border-bottom-right-radius: ${r};
        }
      `
    }}
  }

  @media (min-width: 769px) {
    gap: 0px;

    button {
      border-radius: 0;
      margin-left: -1px; // Ensures a single-width border between buttons.
    }

    ${({ theme }) => {
      const r = pickGap(theme._v1, 'size2').px
      return `
        button:first-child {
          border-top-left-radius: ${r};
          border-bottom-left-radius: ${r};

          // Don't adjust left margin for first button.
          margin-left: 0px;
        }

        button:last-child {
          border-top-right-radius: ${r};
          border-bottom-right-radius: ${r};
        }
      `
    }}
  }
`
