/**
 * Workaround to change svg colors when used as a background color
 * in a css style. Please not that this will only work when using a svg
 * as a background image in a css style. Also the svg need to specify
 * it's color as "currentColor"
 *
 * @usage
 *
 * import MyIcon from './assets/my-icon.svg'
 *
 * const MyButton = styled.button`
 *  background-image: url(${changeSvgColor(MyIcon, 'yellow')});
 * `
 */
export const changeSvgColor = (importedSvg: string, color: string) => {
  const [meta, base64] = importedSvg.split("base64,");
  const svg = atob(base64).replaceAll("currentColor", color);
  return [meta, btoa(svg)].join("base64,");
};

export const addSvgAttribute = (
  importedSvg: string,
  attribute: string,
  value: string
) => {
  const [meta, base64] = importedSvg.split("base64,");
  const svg = atob(base64).replace("<svg", `<svg ${attribute}="${value}"`);
  return [meta, btoa(svg)].join("base64,");
};

export const replaceSvgAttribute = (
  importedSvg: string,
  attribute: string,
  value: string
) => {
  const [meta, base64] = importedSvg.split("base64,");
  let svg = atob(base64);
  svg = svg.replace(
    new RegExp(`${attribute}="([^"]*)"`),
    `${attribute}="${value}"`
  );
  svg = svg.replace(
    new RegExp(`${attribute}='([^']*)'`),
    `${attribute}='${value}'`
  );
  return [meta, btoa(svg)].join("base64,");
};
