import api from '../core'

const group = ''
const resource = 'invites'
const version = '1' // note missing V as parseResourceUrl appends the v...
const resourceUrl = api.parseResourceUrl(group, resource, version)

export default {
  createInvite(data: any) {
    return api.post(resourceUrl, data)
  },

  deleteInvite(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)
    return api.delete(`${resourceUrl}/${strippedId}`)
  },

  getInviteList() {
    return api.get(resourceUrl)
  },

  getInvite(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)
    return api.get(`${resourceUrl}/${strippedId}`)
  },
}
