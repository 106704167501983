import { css } from 'styled-components'

import * as downArrow from '../assets/icons/arrow-down@2x.png'
import * as magnifiyingGlass from '../assets/icons/Auditor-Menu-Icon.png'
import { theme } from '../Css'

export const inputStyle = css`
  border: 0.5px solid ${theme.colors.brand.inputBorder};
`

export const inputStyleDeligthful = css`
  border: 0.5px solid ${theme.colors.soft_highlight};
  border-bottom: 0.5px solid ${theme.colors.default};
  transition: map-get($transition, speed-slow) box-shadow map-get($transition, easing),
    map-get($transition, speed-slow) transform map-get($transition, easing);
  &:hover {
    border: 0.5px solid ${theme.colors.default};
  }
  &:focus {
    border: 0.5px solid ${theme.colors.brandBlue};
  }
`

export const inputStyleMinimalist = css`
  border: 0.5px solid ${theme.colors.mainBackground};
  transition: map-get($transition, speed-slow) box-shadow map-get($transition, easing),
    map-get($transition, speed-slow) transform map-get($transition, easing);
  &:hover {
    border: 0.5px solid ${theme.colors.soft_highlight};
    border-bottom: 0.5px solid ${theme.colors.default};
  }
  &:focus {
    border: 0.5px solid ${theme.colors.default};
  }
`

export const listDropdown = css`
  z-index: 2;
  list-style: none;
  padding: 0px;
  position: absolute;
  width: 100%;
  margin-top: -3px;
  padding-top: 3px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-left: 0.5px solid ${theme.colors.highlight};
  border-bottom: 0.5px solid ${theme.colors.highlight};
  border-right: 0.5px solid ${theme.colors.highlight};
  background-color: ${theme.colors.mainBackground};

  li {
    padding: 1px 8px;
    margin-bottom: 3px;
    cursor: pointer;
    background-color: ${theme.colors.mainBackground};
    transition: background 0.3s ease-out;
  }
  li:last-child {
    margin-bottom: 0px;
  }
  li[data-headlessui-state='active'] {
    background-color: ${theme.colors.highlight};
    transition: background 0.3s ease-out;
  }
`

export const combobox = css`
  @media only screen and (max-width: 550px) {
    // In small screens the caret gets on the way so we better hide it
    .combobox-field.combobox-field.combobox-field::after {
      display: none;
    }
  }

  .combobox-field {
    position: relative;
  }
  .combobox-field::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none; // Arrow clickable in some browsers
    right: 7px;
    top: 15px;
    width: 12px;
    height: 6px;
    background-size: contain;
    background-repeat: no-repeat;
  }
`

export const comboboxSearch = css`
  .combobox-field::after {
    top: 10px;
    width: 16px;
    height: 16px;
  }

  .combobox-field:hover {
    &::after {
      background-image: url(${magnifiyingGlass});
    }
  }
`

export const comboboxCaret = css`
  .combobox-field::after {
    background-image: url(${downArrow});
  }
  .combobox-field:hover {
    &::before {
      background-image: url(${downArrow});
    }
  }
`

export const comboboxTable = css`
  .combobox-field.combobox-field::after {
    right: 17px;
    top: 25px;
    background-image: none;
  }
  &:hover {
    .combobox-field.combobox-field::after {
      background-image: url(${downArrow});
    }
  }
`
