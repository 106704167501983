import api from '../core'

const group = null
const resource = 'samples'
const version = '1'
const resourceUrl = api.parseResourceUrl(group, resource, version)

export default {
  getList() {
    return api.get(resourceUrl)
  },

  create(dataset: any) {
    return api.post(resourceUrl, dataset)
  },
}
