import AssetForm from '@app/components/Assets/AssetForm'
import AssetFormModal from '@app/components/Assets/AssetFormModal'
import { useAppSelector } from '@app/hooks'
import useAlerts from '@app/hooks/useAlerts'
import mockAssets from '@app/mocks/dropboxAssets.json'
import { useAssetList, useHasAssets } from '@app/pages/Asset/hooks/useAssetList'
import { DropboxConnection } from '@app/state/initialState'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CopyIcon,
  DropboxSvg,
  EllipsedLabel,
  H,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from '@ui/components'
import { SearchPlaceHolder, SectionWrapper } from '@ui/components'
import { RkvstAsset } from '@ui/types'
import { copyToClipboard, getAssetName, stripAnyAssetResourceFromId } from '@ui/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOpen } from '../../../configuration.slice'
import {
  FilterTypeaheadCategories,
  InfiniteScroll,
  SectionTableStyle,
  SectionTitle,
  StyledForm,
} from './AssetList.styles'
import { Filter } from './AssetList.types'
import { useCategories } from './useCategories'

interface DropboxSectionProps {
  startClosed?: boolean
}

export const DropboxSection = (props: DropboxSectionProps) => {
  const { open, setOpen } = useOpen('asset:list:section:dropbox')
  const dropboxConnectionData = useAppSelector((state) => state.dropboxConnection)
  const { setAlert } = useAlerts()
  const [refresh, setRefresh] = useState<number | undefined>()

  const { hasAnyAssets: hasAnyDropboxAsset, isPolling } = useHasAssets(
    (message) => {
      if (!isPolling) {
        setAlert({ type: 'error', message })
      }
    },
    undefined,
    { dropbox: true }
  )

  const [filter, setFilter] = useState<Filter>({})
  const { t } = useTranslation()
  const { categories, renderCategory } = useCategories(
    [
      { name: 'displayName', title: t('File Name').toString() },
      { name: 'dropboxPath', title: t('Folder Path').toString() },
      // { name: 'hash', title: t('Document Hash').toString() },
      // {name: 'file', hash: 'dropbox'},
    ],
    filter,
    (f: Filter) => {
      setRefresh(undefined)
      setFilter(f)
    }
  )

  if (
    !hasAnyDropboxAsset &&
    (!dropboxConnectionData || dropboxConnectionData.loading || !dropboxConnectionData.connection)
  ) {
    return null
  }

  return (
    <>
      <SectionWrapper
        title={
          <SectionTitle onClick={() => setOpen(!open)} className="section-title" data-test="dropbox-section">
            {open ? <ChevronDownIcon className="chevron" /> : <ChevronRightIcon className="chevron" />}
            <img src={DropboxSvg} className="asset-icon" />
            <H sz="md" weight="semibold" className="title">
              {t('Dropbox Files')}
            </H>
          </SectionTitle>
        }
        actions={
          <StyledForm action="#" autoComplete="off">
            <FilterTypeaheadCategories
              className="typeahead"
              initialCateogry={0}
              categories={categories}
              testTag="filter-dropbox"
              sizeCategory="150px"
              placeholder={t('Select filter')}
              placeholderContent={<SearchPlaceHolder />}
              renderCategory={renderCategory}
              onSelect={() => {
                setFilter({})
              }}
            />
          </StyledForm>
        }
      >
        {open && <DropboxTable filter={filter} refresh={refresh} />}
      </SectionWrapper>
    </>
  )
}

interface DropboxTableProps {
  filter: Filter
}

const DropboxTable = (props: DropboxTableProps & { refresh?: string | number }) => {
  const [token, setToken] = useState<string | undefined>()
  const [filter, setFilter] = useState<Filter>({ ...props.filter, page: 1, page_size: 50, dropbox: true })
  const { isError, refetch, loading, error, data: assets, nextToken } = useAssetList(filter, token, props.refresh)
  const { t } = useTranslation()

  useEffect(() => {
    setFilter({ ...props.filter, page: 1, page_size: 50, dropbox: true })
  }, [props.filter])

  const { setAlert } = useAlerts()
  const copy = (item: string) => {
    copyToClipboard(item).then(() => {
      setAlert({ type: 'success', message: t('share:Copied', { subject: item }).toString(), timeout: 2000 })
    })
  }

  useEffect(() => {
    setToken(undefined)
  }, [props.refresh])

  return (
    <InfiniteScroll<RkvstAsset>
      rows={assets || []}
      filter={filter}
      loading={loading}
      isError={isError}
      error={error}
      refetch={refetch}
      refresh={props.refresh}
      nextToken={nextToken || ''}
      increasePage={(token: string) => setToken(token || '')}
      table={({ rows }) => (
        <SectionTableStyle data-test="dropbox-table">
          <thead>
            <StyledTableRow className="portrait-header">
              <StyledTableHeader>{t('File Name')}</StyledTableHeader>
              <StyledTableHeader>{t('Folder Path')}</StyledTableHeader>
              <StyledTableHeader>{t('Asset ID')}</StyledTableHeader>
            </StyledTableRow>
          </thead>

          {!isError && !!rows?.length && (
            <tbody>
              {rows?.map((asset, id) => (
                <StyledTableRow
                  key={id + ':' + asset.identity}
                  title={t('view file {{name}}', { name: getAssetName(asset) })}
                  onClick={(ev: any) => {
                    if (ev.button == 0) {
                      if (ev.ctrlKey || ev.metaKey) {
                        window.open(`/${asset.identity}`, '_blank')
                      } else {
                        window.open(`/${asset.identity}`, '_self')
                      }
                    } else if (ev.button == 1) {
                      window.open(`/${asset.identity}`, '_blank')
                    }
                    ev.stopPropagation()
                  }}
                >
                  <StyledTableCell data-content={t('File Name')}>{getAssetName(asset)}</StyledTableCell>
                  <StyledTableCell data-content={t('Folder Path')}>
                    {asset.attributes?.dropbox_path?.toString()}
                  </StyledTableCell>
                  <StyledTableCell data-content={t('Asset ID')}>
                    <div className="cell-action">
                      <EllipsedLabel size="8em">{stripAnyAssetResourceFromId(asset.identity)}</EllipsedLabel>
                      <CopyIcon
                        className="clickable"
                        title={t('Copy Document ID to clipboad')}
                        onClick={() => copy(asset.identity || '')}
                      />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </tbody>
          )}
        </SectionTableStyle>
      )}
    />
  )
}
