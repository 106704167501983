import styled, { useTheme } from 'styled-components'

/**
 * Please do note that the placeholders acts in a negative spae way
 * So the placeholders fills the gaps in between lines, images, etc...
 * so the loading animation can be shown filling the lines
 * of the place holders
 */

interface ContentLoadingPlaceHolderProps extends RkvstBaseProps {
  background?: string
  children?: React.ReactNode
  size?: string
}
export const ContentPlaceHolder = (props: ContentLoadingPlaceHolderProps) => {
  return (
    <PlaceHolderContainer {...props}>
      <PlaceHolderContent background={props.background} size={props.size}>
        {props.children}
      </PlaceHolderContent>
    </PlaceHolderContainer>
  )
}

const StyledRoutePlaceHolder = styled(ContentPlaceHolder)`
  background-color: ${({ theme }) => theme?.colors?.brand?.bodyBackground};
`
export const RouteContentPlaceHolder = (props: any) => {
  const theme = useTheme()
  const background = theme?.colors?.brand?.bodyBackground

  return (
    <StyledRoutePlaceHolder {...props} background={background}>
      <>
        <PlaceHolderImageShortLine background={background} />
        <PlaceHolderImageLine background={background} />
        <PlaceHolderImageLine background={background} lastLine={true} />
        <PlaceHolderLine background={background} />
        <PlaceHolderParagraphSpace background={background} />
        <PlaceHolderShortLine background={background} />
        <PlaceHolderLine background={background} />
        <PlaceHolderLine background={background} />
        <PlaceHolderLine background={background} />
      </>
    </StyledRoutePlaceHolder>
  )
}

export default ContentPlaceHolder

const PlaceHolderContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme?.colors?.brand?.lightGrey};
`

const PlaceHolderContent = styled.div<ContentLoadingPlaceHolderProps>`
  width: 100%;
  overflow: hidden;
  ${({ background, theme, size }) => `
    padding: ${size || '20px'} 0 0 0;
    background-color: ${background || theme?.colors?.brand?.mainBackground};
  `}
  position: relative;

  // Animation
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8; // Fallback
  background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
  background-size: 1300px; // Animation Area
`

const Line = styled.div`
  height: 40px;
  margin-bottom: 0px;
`

export const PlaceHolderLine = styled.div<ContentLoadingPlaceHolderProps>`
  width: 100%;
  ${({ size }) => `
    height: ${size || '20px'};
    margin-bottom: ${size || '20px'};
  `}
  background-color: ${({ background, theme }) => background || theme?.colors?.brand?.mainBackground};

  @keyframes placeholderAnimate {
    0% {
      background-position: -650px 0;
    }
    100% {
      background-position: 650px 0;
    }
  }
`

export const PlaceHolderParagraphSpace = styled(PlaceHolderLine)`
  margin-bottom: 0px;
`

const ShortLine1 = styled(PlaceHolderLine)`
  width: 80%;
  float: left;
`
const ShortLine2 = styled(PlaceHolderLine)`
  width: 20%;
  float: right;
  margin-top: -20px;
  height: 40px;
`

interface LineProps {
  background?: string
  [k: string]: any
}
export const PlaceHolderShortLine = (props: LineProps) => (
  <Line>
    <ShortLine1 background={props.background} />
    <ShortLine2 background={props.background} />
  </Line>
)

const ImageLine1 = styled(PlaceHolderLine)`
  margin-left: 35%;
  width: 5%;
  float: left;
  ${({ lastLine }: ImageLineProps) => {
    if (!lastLine) {
      return `
        margin-top: -20px;
        height: 40px;
      `
    } else {
      return `
        margin-top: -20px;
        height: 60px;
      `
    }
  }}
`

const ImageLine2 = styled(PlaceHolderLine)`
  width: 60%;
  float: left;
`

interface ImageLineProps extends LineProps {
  lastLine?: boolean
}

export const PlaceHolderImageLine = (props: ImageLineProps) => (
  <Line>
    <ImageLine1 {...props} />
    <ImageLine2 background={props.background} />
  </Line>
)

const ImageShortLine2 = styled(PlaceHolderLine)`
  width: 40%;
  float: left;
`

const ImageShortLine3 = styled(PlaceHolderLine)`
  width: 20%;
  float: right;
  margin-top: -20px;
  height: 40px;
`

export const PlaceHolderImageShortLine = (props: ImageLineProps) => (
  <Line>
    <ImageLine1 {...props} />
    <ImageShortLine2 background={props.background} />
    <ImageShortLine3 background={props.background} />
  </Line>
)

export interface GenericContentLoaderProps extends ContentLoadingPlaceHolderProps {
  width?: string
  height?: string
}

export interface ContentLoadingProps extends GenericContentLoaderProps {
  isLoading: boolean
}

export const GenericContentLoader = styled(PlaceHolderContent)<GenericContentLoaderProps>`
  border-radius: 8px;
  ${({ width, height }) => {
    return `
      width: ${width};
      height: ${height}
    `
  }}
`

export const ContentLoader = (props: ContentLoadingProps): JSX.Element => {
  const { isLoading, children, width, height } = props
  if (isLoading) {
    const theme = useTheme()
    const background = theme?.colors?.brand?.bodyBackground

    return <GenericContentLoader background={background} width={width} height={height} />
  }

  return <>{children}</>
}
