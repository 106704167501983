import { ScannedInfo } from '@app/api/resources/attachments.types'
import { RkvstAsset, RkvstEvent } from '@ui/types'
import { ensurePublicIdentity } from '@ui/utils'

import { isCurrentBrowserRoutePublic } from '../../roleBasedAccess'
import api from '../core'
import { Asset } from './assets'
import { Model } from './Model'

const group = null
const resource = 'attachments'
const version = '2'
const blobsGroup = null
const blobsResource = 'blobs'
const blobsVersion = '1'
const resourceUrl = api.parseResourceUrl(group, resource, version)
const legacyResourceUrl = api.parseResourceUrl(group, resource, '1')
const blobsResourceUrl = api.parseResourceUrl(blobsGroup, blobsResource, blobsVersion)

export interface AttachmentInterface {
  type?: string
  arc_attribute_type: string
  arc_blob_identity: string
  arc_file_name: string
  arc_blob_hash_alg: string
  arc_blob_hash_value: string
  mime_type: string
  [k: string]: any
}

export class Attachment extends Model<AttachmentInterface> {
  public getOne(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)

    return api.get(`${resourceUrl}/${strippedId}`)
  }

  public getOneInfo(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)

    return api.get(`${resourceUrl}/${strippedId}/info`)
  }

  public create(attachment: any) {
    return api.post(blobsResourceUrl, attachment)
  }

  public async getAttachmentDownloadStatus(urlLink: string): Promise<ScannedInfo> {
    const urlLinkFiltered = urlLink.replace('/session/archivist', '')
    const result: any = await api.get(`${urlLinkFiltered}/info`)
    return {
      scannedStatus: result.scanned_status,
      scannedBadReason: result.scanned_bad_reason,
      scannedTimeStamp: result.scanned_timestamp,
    }
  }

  /**
   * getAttachmentDownloadResourcePath
   *
   * generates API path and context to pass to attachment service
   *
   * @param id identity of attachment as found in the attributes (string)
   * @param owningResourceIdentity identity of the asset/event the attachment is associated with
   * @returns a list containing API prefix and a context to pass to attachments service
   **/
  public getAttachmentDownloadResourcePath(id: string, owningResourceIdentity?: string | null) {
    if (id?.startsWith(resource)) {
      // Legacy attachments
      const strippedId = api.stripResourceFromId(resource, id)
      return [`${legacyResourceUrl}/`, `${strippedId}`]
    } else if (id?.startsWith(blobsResource)) {
      // treat as blobs-style attachments
      const strippedId = api.stripResourceFromId(blobsResource, id)

      if (!owningResourceIdentity) {
        // Get the raw blob - only works for owner or root principal
        return [`${blobsResourceUrl}/`, `${strippedId}`]
      } else {
        // Get with access control context

        // if we're viewing public asset/event
        // we want to change the viewing context to a public one too
        if (isCurrentBrowserRoutePublic()) {
          owningResourceIdentity = ensurePublicIdentity(owningResourceIdentity)
        }

        return [`${resourceUrl}/`, `${owningResourceIdentity}/${strippedId}`]
      }
    } else {
      return [null, null]
    }
  }

  public getAttachmentDownloadUrl(id: string, owningResourceIdentity?: string | null) {
    const parts = this.getAttachmentDownloadResourcePath(id, owningResourceIdentity)
    if (parts[0] !== null) {
      return `${api.baseURL}${parts[0]}${parts[1]}`
    }

    return '/dist/assets/no-image.png'
  }

  /**
   * Check if a given event has an associated image. This is, if the associated primary image / document_document
   * has a mime type which can be considered an image.
   *
   * @param event [RkvstEvent] event to check if it has an associated image
   * @param asset [RkvstAsset] asset of the event to check if it has an associated image
   *                             the asset is been used as a fallback mechanism in case
   *                             that the event has no assocated image
   *
   * @return [boolean]
   */
  public hasImage(event?: RkvstEvent, asset?: RkvstAsset) {
    const { mimeType, imageIdentity } = this.getPrimaryImageIdentity(event, asset)
    return !!imageIdentity && !!mimeType && this.imageMimeType(mimeType)
  }
  public imageMimeType(mimeType?: string) {
    return mimeType && (mimeType.includes('image') || mimeType.includes('img'))
  }
  /**
   * Returns the identity of the event associated image
   * In case of document this might be the document_document of the event, falling back to the asset
   * In case of non document assets is the primary_image
   *
   * @param event [RkvstEvent] event to check if it has an associated image
   * @param asset [RkvstAsset] asset of the event to check if it has an associated image
   *                             the asset is been used as a fallback mechanism in case
   *                             that the event has no assocated image
   * @param eventFromDocumentAsset [boolean] override so that document_document can be used where
   *                               event is supplied but asset is not.
   *
   * @return [{imageIdentity, identity, mimeType}] imageIdentity as the attachment blob identity
   *                                                 identity as the asset or event identity in which the blob belongs
   *                                                 mimeType as the mime type of the attachment
   */
  public getPrimaryImageIdentity(event?: RkvstEvent, asset?: RkvstAsset, eventFromDocumentAsset?: boolean) {
    const { imageIdentity, mimeType } = this.getArcPrimaryImageAttachmentIdentity(event?.event_attributes)
    const identity = event?.identity

    if (imageIdentity) {
      return { imageIdentity, identity, mimeType }
    }

    if (!asset) {
      // This is a workaround to render event primary image without an otherwise unnecessary asset
      // object. This logic in this function should be teased out, but this isn't a priority right
      // now due to time pressures.
      if (eventFromDocumentAsset && event?.asset_attributes?.document_document) {
        return {
          imageIdentity: event?.asset_attributes?.document_document?.arc_blob_identity,
          identity: event?.identity,
          mimeType: event?.asset_attributes?.document_document?.mime_type,
        }
      }

      return { imageIdentity: null, identity: null, mimeType: '' }
    }

    if (Asset.isDocument(asset) && event?.asset_attributes?.document_document) {
      return {
        imageIdentity: event?.asset_attributes?.document_document?.arc_blob_identity,
        identity: event?.identity,
        mimeType: event?.asset_attributes?.document_document?.mime_type,
      }
    }

    const result = this.getArcPrimaryImageAttachmentIdentity(asset?.attributes)
    if (result.imageIdentity) {
      return {
        ...result,
        identity: asset?.identity,
      }
    }

    if (Asset.isDocument(asset) && !event?.asset_attributes?.document_document) {
      return {
        imageIdentity: asset?.attributes?.document_document?.arc_blob_identity,
        identity: asset?.identity,
        mimeType: asset?.attributes?.document_document?.mime_type,
      }
    }

    return { imageIdentity: null, identity: null, mimeType: '' }
  }

  public getArcPrimaryImageAttachmentIdentity(attributes: any) {
    if (!attributes) {
      return { imageIdentity: null }
    }

    // Top-level arc_primary_image is preferred
    if (attributes?.arc_primary_image?.arc_blob_identity) {
      return {
        imageIdentity: attributes.arc_primary_image.arc_blob_identity,
        mimeType: attributes.arc_primary_image.mime_type,
      }
    }

    // Fall back to V2 arc_attachments primary image
    if (attributes.arc_attachments) {
      for (const att of attributes.arc_attachments) {
        if (att.arc_display_name == 'arc_primary_image') {
          return {
            imageIdentity: att.arc_attachment_identity,
            mimeType: att.mime_type,
          }
        }
      }
    }

    // Fall back to V1 / V2 legacy primary image
    if (attributes?.arc_primary_image_identity) {
      return {
        imageIdentity: attributes.arc_primary_image_identity,
        mimeType: attributes.mime_type,
      }
    }

    return { imageIdentity: null }
  }
}

export default new Attachment()
