import { Reducer } from 'redux'

import { apiAction } from '../actionCreators'
import initialState, { ReduxState, RkvstEvent } from '../initialState'

const sortByTimestampDeclaredDesc = (a: RkvstEvent, b: RkvstEvent) => {
  if (a.timestamp_declared < b.timestamp_declared) {
    return 1
  }
  if (a.timestamp_declared > b.timestamp_declared) {
    return -1
  }
  return 0
}

const mergeEventIntoList = (event: RkvstEvent, list: Array<RkvstEvent>) => {
  const mergedList = []
  let assetAlreadyInList = false
  for (let i = list.length - 1; i >= 0; i--) {
    const item = list[i]

    if (item.identity === event.identity) {
      mergedList[i] = event
      assetAlreadyInList = true
    } else {
      mergedList[i] = item
    }
  }

  if (assetAlreadyInList) {
    return mergedList
  }

  mergedList.unshift(event)
  mergedList.sort(sortByTimestampDeclaredDesc)

  return mergedList
}

const reducer: Reducer<ReduxState['events']> = (state = initialState().events, action: any): any => {
  switch (action.type) {
    case apiAction.fulfilled('api/events/getList'): {
      return {
        ...state,
        list: action.payload.events,
      }
    }
    case apiAction.fulfilled('api/assets/createEvent'): {
      const event = action.payload
      const list = [event, ...state.list]

      // merge the event into the filtered list ONLY if no filters applied.
      // If filters are applied then the user will have to click APPLY FILTERS
      // again to see updates (they are informed of this in the UI when
      // filtered.expired is true)
      const mergedFilterList =
        state.filtered.currentFilterConditions.length === 0 ? [event, ...state.filtered.list] : state.filtered.list

      return {
        ...state,
        list,
        filtered: {
          ...state.filtered,
          list: mergedFilterList,
          expired: true,
        },
      }
    }
    case apiAction.fulfilled('api/events/getListFiltered'): {
      const newEvents = action.payload.events

      return {
        ...state,
        filtered: {
          ...state.filtered,
          // if this is a new page, append, otherwise if first page replace
          list: action.meta.nextPageToken ? [...state.filtered.list, ...newEvents] : newEvents,
          nextPageToken: action.payload.next_page_token || null,

          // fresh filtered data, so filters not expired
          expired: false,
        },
      }
    }
    case 'events/setCurrentFilterConditions': {
      return {
        ...state,
        filtered: {
          ...state.filtered,
          currentFilterConditions: action.currentFilterConditions,
        },
      }
    }
    case 'notifications/event_and_asset':
    case 'notifications/event': {
      // merge the event into the cache
      const mergedList = mergeEventIntoList(action.event, state.list)

      // merge the event into the filtered list ONLY if no filters applied.
      // If filters are applied then the user will have to click APPLY FILTERS
      // again to see updates (they are informed of this in the UI when
      // filtered.expired is true)
      const mergedFilterList =
        state.filtered.currentFilterConditions.length === 0
          ? mergeEventIntoList(action.event, state.filtered.list)
          : state.filtered.list

      return {
        ...state,
        list: mergedList,
        filtered: {
          ...state.filtered,
          list: mergedFilterList,
          // mark current filters expired, as the added asset may not match current filters
          expired: true,
        },
      }
    }
  }
  return state
}

export default reducer
