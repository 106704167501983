import api from '../core'

const group = null
const resource = 'dropbox'
const version = '1'
const resourceUrl = api.parseResourceUrl(group, resource, version)

export default {
  getConnection(): Promise<any> {
    return api.get(`${resourceUrl}/connection`)
  },

  patchConnection(data: any): Promise<any> {
    return api.patch(`${resourceUrl}/connection`, JSON.stringify(data), {
      headers: { 'content-type': 'application/json' },
    })
  },

  deleteConnection(): Promise<any> {
    return api.delete(`${resourceUrl}/connection`)
  },

  getDropboxFolders(folder: string): Promise<any> {
    return api.get(`${resourceUrl}/dropbox_folders?path=${folder}`)
  },

  fetchdata(): Promise<any> {
    return api.post(`${resourceUrl}/fetchdata`, {})
  },
}
