import { trackEvent } from '@app/utils/userStats'
import { DropResult, DropZone, RKVSTSwitch } from '@ui/components'
import { RKVSTHashes } from '@ui/utils'
import arrayMutators from 'final-form-arrays'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { CreateAsset } from '../../../api/resources/assets'
import suggestions from '../../../api/resources/suggestions'
import { withAlertsContext } from '../../../common/Alerts'
import { AlertData } from '../../../common/Alerts/Alerts.types'
import { WithAlertsContext } from '../../../common/Alerts/Alerts.types'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import i18n from '../../../i18n'
import actionCreators from '../../../state/actionCreators'
import { ReduxState, RkvstAsset } from '../../../state/initialState'
import { RootState } from '../../../state/store'
import ExtendedAttributes from '../../Form/ExtendedAttributes'
import InputSelect from '../../Form/InputSelect'
import InputText from '../../Form/InputText'
import InputTextArea from '../../Form/InputTextArea'
import Img from '../../Img'
import { withLoadingContext } from '../../Loading'
import { WithLoadingContext } from '../../Loading'
import { Container } from './AssetForm.style'
import UploadAttachmentsModal from './components/UploadAttachmentsModal'
import { AdvancedDetailsTabLayout, DocumentDetailsTabLayout } from './DocumentForm.style'

type DocumentFormProps = {
  createAsset?: any
  onClose: () => void
  getCaps?: any
  onSubmit?(): void
} & ReturnType<typeof mapState> &
  typeof mapDispatch &
  WithLoadingContext &
  WithAlertsContext

export const buildReqBodyCreate = (data: any, attachmentsData: any) => {
  const asset = new CreateAsset().setData({
    attributes: {
      arc_display_type: data.type || 'Document',
      arc_display_name: data.display_name,
      arc_description: data.description || '',
      arc_profile: 'Document',
      document_version: data.document_version,
      document_hash_value: data.document_hash_value,
      document_hash_alg: 'sha256',
    },
    chain_id: '',
    public: data.public,
    behaviours: ['RecordEvidence'],
    proof_mechanism: data.proof_mechanism || 'MERKLE_LOG',
  })

  if (data.extended_attributes) {
    data.extended_attributes.forEach(({ type, key, value }: { type: string; key: string; value: any }) => {
      if (type == 'keyvalue') {
        if (key?.length > 0 && value?.length > 0) {
          asset.addAttribute(key, value)
        }
      }
    })
  }

  // attachments added - loop through retrived attachments data
  asset.addAttachments(attachmentsData)
  return asset.getData()
}

const Presentation: React.FC<DocumentFormProps> = (props) => {
  const [uploadModalShow, setUploadModalShow] = useState<boolean>(false)
  const [formData, setFormData] = useState(null)
  const [attachmentsData, setAttachmentsData] = useState<{ key: string; file: any }[]>([])
  const [documentFile, setDocumentFile] = useState<File | null>(null)
  const [activeTab, setActiveTab] = useState(0)
  const dispatch = useAppDispatch()
  const simpleHashCapReached = useAppSelector((state: RootState) => state.assets.simpleHashCapReached)
  const merkleLogCapReached = useAppSelector((state: RootState) => state.assets.merkleLogCapReached)

  useEffect(() => {
    dispatch(actionCreators.assets.getCaps())
  }, [])

  const buildInitialValues = () => {
    const initialValues = {
      arc_description: '',
      public: false,
      upload: false,
      proof_mechanism: 'MERKLE_LOG',
    }

    return initialValues
  }

  const validate = (data: any) => {
    const errors: any = {}
    const allRequiredFields = ['display_name', 'document_hash_value']

    allRequiredFields.forEach((field) => {
      if (!Object.prototype.hasOwnProperty.call(data, field) || data[field] === '') {
        errors[field] = i18n.t('globals:form.this_field_is_required')
      }
    })
    return errors
  }

  const getListOfAttachments = (formData: any): { key: string; file: any }[] => {
    let result = []
    if (formData?.extended_attributes) {
      const filtered = formData.extended_attributes.filter((item: any) => {
        return item.type === 'attachment' && item.value && item.value !== ''
      })

      if (filtered.length && filtered[0].value) {
        result = filtered.map((item: any) => {
          return {
            key: item.key,
            file: item.value,
          }
        })
      }
    }
    if (formData.upload && documentFile) {
      result.push({ key: 'document_document', file: documentFile })
    }
    return result
  }

  const createAsset = (attachments: any, _formData?: any) => {
    const result = buildReqBodyCreate(_formData || formData, attachments)
    if (result !== null) {
      dispatch(actionCreators.assets.create(result, props.setAlert, props.setLoading))
    }
  }

  const closeModals = () => {
    setUploadModalShow(false)
    props.onClose()
  }

  const closeAttachmentsModal = () => {
    setUploadModalShow(false)
  }

  const errorUploadingAttachments = (errorMessage: string) => {
    props.setAlert({
      type: 'error',
      message: errorMessage,
      timeout: 6000,
    })
    setUploadModalShow(false)
  }

  const processDrop = (form: any, result: DropResult) => {
    setDocumentFile(result.file)
    form.mutators.setCustomAttributes(result.hashes)
  }

  const processClear = (form: any) => {
    setDocumentFile(null)
    form.mutators.setCustomAttributes()
  }

  return (
    <Form
      onSubmit={async (data: any) => {
        const files: { key: string; file: any }[] = getListOfAttachments(data)

        if (files.length) {
          // show upload modal
          setUploadModalShow(true)
          // pass attachments to attachments modal
          setAttachmentsData(files)
          setFormData(data)
        } else {
          trackEvent('DocumentRegistered', {})
          createAsset([], data)
          closeModals()
          props.onSubmit && props.onSubmit()
        }
      }}
      mutators={{
        ...arrayMutators,
        setCustomAttributes: ([hashes]: RKVSTHashes[], state, utils) => {
          utils.changeValue(state, 'document_hash_value', () => hashes.sha256)
        },
      }}
      validate={validate}
      initialValues={buildInitialValues()}
    >
      {({ form, handleSubmit, values }) => (
        <form
          onSubmit={(ev) => {
            if (form.getState()?.invalid && form.getState().errors?.display_name) {
              setActiveTab(0)
            }
            if (form.getState()?.invalid && form.getState().errors?.document_hash_value) {
              setActiveTab(0)
            }
            return handleSubmit(ev)
          }}
          noValidate
          autoComplete="off"
        >
          <Container className="asset-form">
            <Tabs selectedIndex={activeTab} onSelect={(index) => setActiveTab(index)}>
              <TabList>
                <Tab data-test="asset-info">{i18n.t('Document Information')}</Tab>
                <Tab data-test="advanced">{i18n.t('Advanced Options')}</Tab>
              </TabList>
              <TabPanel>
                <DocumentDetailsTabLayout>
                  <div className="basic">
                    <div>
                      <Field name="display_name">
                        {(fieldProps) => (
                          <InputText
                            id="display_name"
                            label={i18n.t('document:publish:document_name').toString()}
                            helpText={i18n.t('document:publish:document_name_help').toString()}
                            placeholder={i18n.t('assets:modal.name').toString()}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </div>
                    <div>
                      <Field name="document_version">
                        {(fieldProps) => (
                          <InputText
                            id="document_version"
                            label={i18n.t('document:publish:document_version').toString()}
                            helpText={i18n.t('The version of this document, according to your organization').toString()}
                            placeholder={i18n.t('document:publish:document_version').toString()}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </div>
                    <div>
                      <Field name="upload" type="checkbox">
                        {(fieldProps) => (
                          <RKVSTSwitch
                            id="upload"
                            labelText={i18n.t('document:publish:upload_on_creation').toString()}
                            helpText={i18n.t('document:publish:upload_on_creation_help').toString()}
                            {...fieldProps}
                          ></RKVSTSwitch>
                        )}
                      </Field>
                    </div>
                    <div>
                      <Field name="public" type="checkbox">
                        {(fieldProps) => (
                          <RKVSTSwitch
                            id="public"
                            labelText={i18n.t('document:publish:make_public').toString()}
                            helpText={i18n.t('document:publish:make_public_help').toString()}
                            {...fieldProps}
                          ></RKVSTSwitch>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="file">
                    <h3>{i18n.t('document:publish:auto_fill').toString()}</h3>
                    <span className="helpText long" data-test="auto-fill-help-text">
                      {i18n.t('document:publish:auto_fill_help_1').toString()}
                      {form.getState().values.upload && (
                        <strong>{i18n.t('document:publish:auto_fill_help_strong').toString()}</strong>
                      )}
                      {i18n.t('document:publish:auto_fill_help_2').toString()}
                    </span>
                    <div>
                      <Field name="hash" type="file">
                        {(fieldProps) => (
                          <DropZone
                            files={documentFile ? [documentFile] : []}
                            onDrop={(result: DropResult) => {
                              processDrop(form, result)
                            }}
                            onClear={() => {
                              processClear(form)
                            }}
                            {...fieldProps}
                          >
                            {form.getState().values.upload ? (
                              <p className="dropdown-text">{i18n.t('document:publish:drop_to_upload').toString()}</p>
                            ) : (
                              <p className="dropdown-text">{i18n.t('document:publish:drop_to_hash').toString()}</p>
                            )}
                          </DropZone>
                        )}
                      </Field>
                    </div>
                    <h4 className="lined">
                      <span>{i18n.t('document:publish:or').toString()}</span>
                    </h4>
                    <div>
                      <Field name="document_hash_value">
                        {(fieldProps) => (
                          <InputText
                            id="document_hash_value"
                            className="input text"
                            placeholder={i18n.t('document:publish:enter_sha').toString()}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </DocumentDetailsTabLayout>
              </TabPanel>
              <TabPanel>
                <AdvancedDetailsTabLayout>
                  <div>
                    <Field name="type">
                      {(fieldProps) => (
                        <InputText
                          id="type"
                          label={i18n.t('document:publish:document_type').toString()}
                          helpText={i18n.t('document:publish:document_type_help').toString()}
                          placeholder={i18n.t('document:publish:document_type').toString()}
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="proof_mechanism">
                      {(fieldProps) => (
                        <InputSelect
                          id="proof_mechanism"
                          label={i18n.t('assets:modal.proof_mechanism').toString()}
                          helpText={i18n.t('assets:modal.proof_mechanism.help').toString()}
                          options={[{ key: 'MERKLE_LOG', value: 'Merkle Log', disabled: merkleLogCapReached }]}
                          defaultValue={'MERKLE_LOG'}
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="attributes2">
                    <Field name="description">
                      {(fieldProps) => (
                        <InputTextArea
                          label={i18n.t('assets:modal.description').toString()}
                          id="description"
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="attributes">
                    <label>{i18n.t('assets:modal.extended_attributes').toString()}</label>
                    <span className="helpText">{i18n.t('document:publish:extended_attributes_help').toString()}</span>
                    <ExtendedAttributes
                      suggestionsGetter={suggestions.suggestAssetAttributes.bind(suggestions)}
                      keyHeading={i18n.t('form:extended_attributes.attributes').toString()}
                      type={'extended_attributes'}
                      threshold={1}
                    />
                  </div>
                </AdvancedDetailsTabLayout>
              </TabPanel>
            </Tabs>

            <div className="row">
              <div className="col-md-12 text-right buttons">
                <button className="button-action" id="create_asset_button" type="submit">
                  <Img name="Register-Document-Icon-White" alt="Asset" />
                  <span className="text-vertical-middle">
                    {i18n.t('document:publish:register_document').toString()}
                  </span>
                </button>
              </div>
            </div>
            {uploadModalShow && (
              <UploadAttachmentsModal
                attachmentsData={attachmentsData}
                create={createAsset}
                closeAttachmentsModal={closeAttachmentsModal}
                closeModals={closeModals}
                errorUploadingAttachments={errorUploadingAttachments}
                uploadAttachmentsModalVisible={uploadModalShow}
              />
            )}
          </Container>
        </form>
      )}
    </Form>
  )
}

export const mapState = (state: ReduxState) => {
  // until a refactor done, this method is needed in the types file.
  return {}
}
// TODO: Where you have "createAsset" execute dispatch
export const mapDispatch = (dispatch: Dispatch<any>) => ({
  createAsset: (data: RkvstAsset, setAlert: (p: AlertData) => void, setLoading: Dispatch<SetStateAction<boolean>>) =>
    dispatch(actionCreators.assets.create(data, setAlert, setLoading)),
  createEvent: (assetIdentity: string, data: any) => dispatch(actionCreators.assets.createEvent(assetIdentity, data)),
  getCaps: actionCreators.tenancies.getCaps,
})

const DocumentForm = connect(mapState, mapDispatch)(withLoadingContext(withAlertsContext(Presentation)))

export default DocumentForm
