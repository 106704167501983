import { Reducer } from 'redux'

import { apiAction } from '../actionCreators'
import initialState, { ReduxState } from '../initialState'

const reducer: Reducer<ReduxState['compliancePolicies']> = (state = initialState().locations, action: any) => {
  switch (action.type) {
    case apiAction.fulfilled('api/compliancePolicies/getCaps'): {
      const count = action.payload.caps.find(
        (element: { resource_type: string }) => element.resource_type == 'CompliancePolicies'
      ).resource_remaining

      return {
        ...state,
        capReached: count == '0',
      }
    }

    case apiAction.failed('api/compliancePolicies/getCaps'): {
      return {
        ...state,
        capReached: false,
      }
    }
  }

  return state
}

export default reducer
