import { TenancyCapsResponse } from '@ui/types'

import api from '../core'

const group = null
const resource = 'tenancies'
const version = '1'
const capsUrl = api.parseCapsUrl(resource)
const resourceUrl = api.parseResourceUrl(group, resource, version)

export type TenancyVerifiedDomain = {
  identity: string
  verified_domain: string
}

export type TenancyAdminResponse = {
  identity: string
  verified_domain: string
  display_name: string
  enterprise_sso_enabled: boolean
  enterprise_sso_config: {
    config_url: string
    issuer: string
    client_id: string
    client_secret: string
    policy_id: string
  }
}

export type UpdatableTenantRequest = {
  display_name?: string
  enterprise_sso_config?: {
    config_url: string
    issuer: string
    client_id: string
    client_secret: string
  }
}

export default {
  getCaps(): Promise<TenancyCapsResponse> {
    const result = api.get(resourceUrl + '/caps')
    return result
  },
  getUsers() {
    const result = api.get(resourceUrl + '/users')
    return result
  },
  deleteUser(id: string) {
    return api.delete(`${resourceUrl}/users/${id}`)
  },
  getUsersCaps() {
    const result = api.get(capsUrl + '/users')
    return result
  },
  /**
   * pass in the tenant identity to get verified domain
   * @param tenantIdentity string tenant UUID
   */
  getVerifiedDomain(tenantIdentity: string): Promise<TenancyVerifiedDomain> {
    const strippedId = api.stripResourceFromId('tenant', tenantIdentity)
    const result = api.get<TenancyVerifiedDomain>(`${resourceUrl}/${strippedId}:publicinfo`)

    return result
  },

  updateTenant(data: UpdatableTenantRequest): Promise<TenancyAdminResponse> {
    const result = api.patch(`${resourceUrl}/self`, data)

    return result as Promise<TenancyAdminResponse>
  },

  getTenant(): Promise<TenancyAdminResponse> {
    const result = api.get(`${resourceUrl}/self`)

    return result as Promise<TenancyAdminResponse>
  },
}
