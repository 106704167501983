import styled from 'styled-components'

import Badge from './LegacyBadge'

// inheriting styles from parent "Badge" component
const SuccessBadge = styled(Badge as any)`
  color: rgb(2, 122, 72);
  padding: 12px;
  font-weight: 500;
  border-radius: 12px;
  background-color: rgb(209, 250, 223);
  cursor: default;
  .public-icon {
    display: flex;
    align-items: center;
    vertical-align: middle;
    padding: 1px;
    padding-right: 5px;
  }
`

export default SuccessBadge
