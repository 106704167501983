import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

const WidgetLoadingOverlayContainer = styled.div<{ fade?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: rgba(0, 0, 0, ${(p) => p.fade || 0.1});

  & svg {
    height: 50px;
  }
`

// place within a container with the class 'widget'
// to show a loading overlay
export const WidgetLoadingOverlay: React.FC<{ fade?: number }> = (props) => (
  <WidgetLoadingOverlayContainer id="loading_overlay" fade={props.fade}>
    <ReactSVG src="/dist/assets/bars.svg" />
  </WidgetLoadingOverlayContainer>
)

// @ts-ignore
export const LoadingContext = React.createContext<WithLoadingContext>()

interface LoadingProviderProps {
  children?: React.ReactNode
  [k: string]: any
}
export const LoadingProvider: React.FC<LoadingProviderProps> = (props: LoadingProviderProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  const withLoader = async (...promises: Array<Promise<any>>) => {
    setLoading(true)
    let mounted = true
    if (mounted) await Promise.all(promises)
    setLoading(false)
    return () => (mounted = false)
  }

  const withStateChangeLoader = (isLoading: boolean): void => {
    setLoading(isLoading)
  }

  return (
    <LoadingContext.Provider
      value={{
        setLoading,
        withLoader,
        withStateChangeLoader,
        loading,
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  )
}

export type WithLoadingContext = {
  setLoading: (value: boolean) => void
  withLoader: (...promises: Array<Promise<any>>) => void
  withStateChangeLoader: (value: boolean) => void
  loading: boolean
}

export function withLoadingContext<T>(Wrapped: React.ComponentType<T & WithLoadingContext>) {
  return (wrappedComponentProps: T) => (
    <LoadingContext.Consumer>
      {(props: WithLoadingContext) => {
        if (!props) {
          return null
        }

        const { setLoading, withLoader, withStateChangeLoader, loading } = props
        return (
          <Wrapped
            setLoading={setLoading}
            withLoader={withLoader}
            withStateChangeLoader={withStateChangeLoader}
            loading={loading}
            {...wrappedComponentProps}
          />
        )
      }}
    </LoadingContext.Consumer>
  )
}

export const LoadingImage: React.FC = () => <ReactSVG className="svg" src="/dist/assets/bars.svg" />
