import { Suspense } from 'react'

import { ContentPlaceHolder, PlaceHolderLine } from './ContentLoading'
export interface BasicSuspenseProps {
  children?: React.ReactNode
  [k: string]: any
}
export const BasicSuspense = ({ children }: BasicSuspenseProps) => <></>
export const VisibleSuspense = ({ children }: BasicSuspenseProps) => (
  <ContentPlaceHolder>
    <PlaceHolderLine />
  </ContentPlaceHolder>
)

export const wrapSuspense = <T = any,>(Comp: any) =>
  ((props: any) => (
    <Suspense fallback={<BasicSuspense />}>
      <Comp {...props} />
    </Suspense>
  )) as T

export const wrapVisibleSuspense = <T = any,>(Comp: any) =>
  ((props: any) => (
    <Suspense fallback={<VisibleSuspense />}>
      <Comp {...props} />
    </Suspense>
  )) as T
