import { pickColor } from '@ui/themes'
import styled from 'styled-components'

import { theme } from '../../Css'

export const Container = styled.div<{ navVisible?: boolean }>`
  position: fixed;
  z-index: 20;
  right: 16px;
  left: ${(p) => (p.navVisible ? theme.layout.navWidth + 16 : 16)}px;
  top: ${theme.layout.headerHeight + 16}px;

  .alert {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: 5px;

    .row {
      margin-right: 0px;
      margin-left: 0px;
    }

    .message {
      display: flex;
      align-items: center;
      text-align: left;

      .contents {
        width: 100%;
      }

      .alertIcon {
        padding: 8px;
      }
    }
    .close {
      text-align: right;
      padding: 8px;
    }
  }
  .alert.info {
    color: ${pickColor(theme?._v1, 'primary', 'i700')};
    background-color: ${pickColor(theme?._v1, 'primary', 'i100')};
    border-color: ${pickColor(theme?._v1, 'primary', 'i300')};
    border-radius: 10px;
    border-width: medium;
    border-style: solid;
  }
  .alert.success {
    color: ${pickColor(theme?._v1, 'success', 'i700')};
    background-color: ${pickColor(theme?._v1, 'success', 'i100')};
    border-color: ${pickColor(theme?._v1, 'success', 'i300')};
    border-radius: 10px;
    border-width: medium;
    border-style: solid;
  }
  .alert.error {
    color: ${pickColor(theme?._v1, 'error', 'i700')};
    background-color: ${pickColor(theme?._v1, 'error', 'i100')};
    border-color: ${pickColor(theme?._v1, 'error', 'i300')};
    border-radius: 10px;
    border-width: medium;
    border-style: solid;
  }
`
