import styled from 'styled-components'

import Badge from './LegacyBadge'

export const ErrorBadge = styled(Badge)`
  padding: 12px;
  font-weight: 500;
  border-radius: 12px;
  background: rgba(235, 0, 0, 0.1);
  color: rgba(235, 0, 0, 0.9);
  cursor: default;
`

export default ErrorBadge
