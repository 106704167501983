import { lazily } from 'react-lazily'

import { wrapVisibleSuspense } from '../../atoms/placeholders/Suspense'

const { DropZone: RawDropZone } = lazily(() => import('./DropZone'))
const DropZone = wrapVisibleSuspense<typeof RawDropZone>(RawDropZone)
export { DropZone }

import { DropResult, DropZoneProps } from './DropZone.types'
export { DropResult, DropZoneProps }
