import { Reducer } from 'redux'

import { apiAction } from '../actionCreators'
import initialState, { ReduxState } from '../initialState'

const reducer: Reducer<ReduxState['rootPrincipals']> = (state = initialState().rootPrincipals, action: any) => {
  switch (action.type) {
    case 'root_principals_update': {
      const { rootPrincipals } = action.payload
      return {
        ...state,
        list: rootPrincipals,
      }
    }
    case apiAction.fulfilled('api/rootPrincipals/getList'): {
      return {
        ...state,
        list: action.payload.root_principals,
      }
    }
  }
  return state
}

export default reducer
