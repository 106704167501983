import React from 'react'

import Img from './Img'

type Props = {
  status: string
  confirmedIcon?: string
  confirmedAlt?: string
  tracked?: boolean
}

const DEFAULTS = {
  pending: { icon: 'Pending-Icon', alt: 'Pending' },
  confirmed: { icon: 'rkvsttickofverification', alt: 'Tick' },
  failed: { icon: 'Node-Asset-Error-Icon', alt: 'Cross' },
  untracked: { icon: 'Node-icon-Error', alt: 'Not Tracked' },
}

const Presentation: React.FC<Props> = (props) => {
  const tracked = props.tracked === undefined ? true : props.tracked

  switch (props.status) {
    case 'PENDING':
      return <Img name={DEFAULTS.pending.icon} alt={DEFAULTS.pending.alt} />
    case 'CONFIRMED':
    case 'COMMITTED':
      return tracked ? (
        <Img name={props.confirmedIcon || DEFAULTS.confirmed.icon} alt={props.confirmedAlt || DEFAULTS.confirmed.alt} />
      ) : (
        <Img name={DEFAULTS.untracked.icon} alt={DEFAULTS.untracked.alt} />
      )
    case 'FAILED':
      return <Img name={DEFAULTS.failed.icon} alt={DEFAULTS.failed.alt} />
  }

  return null
}

const AssetConfirmationStatusIcon = Presentation

export default AssetConfirmationStatusIcon
