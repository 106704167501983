import styled from 'styled-components'

import { theme } from '../../../Css'
import { combobox, comboboxSearch, inputStyleMinimalist } from '../../../styles/FormInputs'
import { headlessComboboxTable } from '../../../styles/ThirdPartyCss'

export const Container = styled.div`
  text-align: center;

  .button-action:disabled {
    background-color: #f2f2f2;
    color: #858585;
  }

  .combobox .input {
    padding-bottom: 0px;
  }
  .text .input {
    padding-bottom: 10px;
    padding-bottom: 10px;
  }

  padding-bottom: 20px;
  table {
    border: 0.5px solid ${theme.colors.brand.inputBorder};
    border-radius: 3px;
    thead {
      border-bottom: 0.5px solid ${theme.colors.brand.inputBorder};
      th {
        padding: 20px 10px;
      }
    }
    tr {
      border-left: none;
      border-bottom: none;
    }
    tr:nth-child(even) {
      background: #fcfcfc;
    }
    td.disabled {
      background-color: #f4f4f2;
    }
    td {
      p {
        font-size: 13px;
        font-family: 'Arial';
        color: #000000;
        opacity: 0.5;
        padding-left: 7px;
      }
      .button__delete {
        margin-top: 3px;
      }

      padding: 0;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      input {
        height: 100%;
        width: 90%;
        margin: 10px 5px;
        background: none;
        ${inputStyleMinimalist}
      }
    }
    td.key {
      border-right: 0.5px solid ${theme.colors.brand.inputBorder};
      padding-left: 0 !important;
      width: 30%;
    }
  }
  .button-action {
    margin-top: 20px;
  }

  .combobox {
    position: relative;
    .combobox-field.combobox-field::after {
      right: 27px;
      top: 20px;
      background-image: none;
    }
    ${combobox}
    ${comboboxSearch}
    ${headlessComboboxTable}
  }
`
