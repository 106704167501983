import { lazily } from 'react-lazily'

import { wrapVisibleSuspense } from '../../atoms/placeholders/Suspense'

export { ComboboxProps } from './Combobox.types'
export { TypeaheadProps } from './Typeahead.types'
export * from './TypeaheadCategories.types'

const { Typeahead: RawTypeahead } = lazily(() => import('./Typeahead'))
const Typeahead = wrapVisibleSuspense<typeof RawTypeahead>(RawTypeahead)

const {
  MultipickTypeahead: RawMultipickTypeahead,
  ChickletsInputWrapper: RawChickletsInputWrapper,
  Chicklets: RawChicklets,
} = lazily(() => import('./MultipickTypeahead'))
const MultipickTypeahead = wrapVisibleSuspense<typeof RawTypeahead>(RawMultipickTypeahead)
const ChickletsInputWrapper = wrapVisibleSuspense<typeof RawChickletsInputWrapper>(RawChickletsInputWrapper)
const Chicklets = wrapVisibleSuspense<typeof RawChicklets>(RawChicklets)

const { TypeaheadCategories: RawTypeaheadCategories } = lazily(() => import('./TypeaheadCategories'))
const TypeaheadCategories = wrapVisibleSuspense<typeof RawTypeaheadCategories>(RawTypeaheadCategories)

const { Combobox: RawCombobox } = lazily(() => import('./Combobox'))
const Combobox = wrapVisibleSuspense<typeof RawCombobox>(RawCombobox)

export { Chicklets, ChickletsInputWrapper, Combobox, MultipickTypeahead, Typeahead, TypeaheadCategories }
