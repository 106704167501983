import { lazily } from 'react-lazily'
const { InfoCircleIcon, CloseIcon } = lazily(() => import('@ui/components/src/svgs'))
import React, { ReactNode, Suspense } from 'react'
import { DefaultTheme, useTheme } from 'styled-components'
// Lazy imports because solving circular dependencies
const H = React.lazy(() => import('../../atoms/Typography/H').then((comp) => ({ default: comp.H })))
const BodyText = React.lazy(() => import('../../atoms/Typography/Text').then((comp) => ({ default: comp.BodyText })))
import { AlertContainer } from './Alert.styles'
import { AlertProps, AlertVariant } from './Alert.types'

export const selectIcon = (theme: DefaultTheme, variant: AlertVariant): React.ReactNode => {
  switch (variant) {
    case 'error':
    case 'info': {
      return <InfoCircleIcon width={24} height={24} />
    }
  }
}

export interface CloseButtonProps {
  onClick: () => void
}

export const CloseButton = (props: CloseButtonProps) => {
  const { onClick } = props
  return (
    <div role="button" onClick={onClick} className="inline-btn">
      <CloseIcon />
    </div>
  )
}

export const Alert = ({ variant = 'info', text, supportingText, children, ...props }: AlertProps) => {
  const theme = useTheme()

  return (
    <AlertContainer variant={variant} className={props.className} data-test={props.testTag}>
      <header className="alert-header">
        <i className="alert-icon">{selectIcon(theme, variant)}</i>
        <BodyText weight="bold" sz="md" className="alert-title">
          {text}
        </BodyText>
        {props.primaryButton && <span className="inline-button">{props.primaryButton}</span>}
        {props.onClose && <CloseButton onClick={props.onClose} />}
      </header>

      {supportingText !== undefined && (
        <section className="alert-support">
          <i className="alert-icon"></i>
          <BodyText className="support-text">{supportingText}</BodyText>
        </section>
      )}

      {children}
    </AlertContainer>
  )
}
