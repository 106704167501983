import React from 'react'
import { FieldRenderProps } from 'react-final-form'

import showError from './showError'

type InputTextProps = {
  id: string
  label?: string
  helpText?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  type?: string
  min?: string
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
} & FieldRenderProps<string, HTMLInputElement>

const Presentation: React.FC<InputTextProps> = ({ ...props }) => {
  props.input.type = props.type || 'text'

  return (
    <div
      className={
        (props.label ? 'input text' : '') +
        (props.meta.error ? ' input-error' : '') +
        (props.className ? ` ${props.className}` : '')
      }
    >
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}
      {props.helpText ? <span className="helpText">{props.helpText}</span> : null}

      <input
        min={props.min}
        disabled={props.disabled}
        id={props.id}
        data-test={`${props.id}`}
        placeholder={props.placeholder}
        onKeyPress={props.onKeyPress}
        {...props.input}
      />
      {showError(props.meta) && <span className="error-message">{props.meta.error}</span>}
    </div>
  )
}

const InputText = Presentation

export default InputText
