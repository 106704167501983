import { useState } from 'react'

import suggestions from '../api/resources/suggestions'
import { SuggestionsKey } from './hooks.types'

// TODO: This should use react-query

/**
 * useLoadSuggestedAssets
 */
const useLoadSuggestedAssets = (key?: SuggestionsKey) => {
  const [data, setData] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const loadSuggestedData = async (textSearch: string) => {
    try {
      setLoading(true)
      const res: any = await suggestions.get('asset', textSearch, true, 'attribute', 10, 'v', key)

      setData(() => [...(res as string[])])
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return {
    loadSuggestedData,
    data,
    loading,
    error,
  }
}

export default useLoadSuggestedAssets
