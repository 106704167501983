import { pickColor } from '@ui/themes'
import styled from 'styled-components'

export const FakeScrollContainer = styled.p`
  ${({ theme }) => `
  position: relative;
  overflow: hidden;
  max-width: 100%;
  margin-top: -8px;
  padding-top: 0px;

  .navigate-wrapper {
    top: 0px;
    position: absolute;
    background-color: white;
    width: 26px;
    height: 26px;
    z-index: 3;
  }

  .navigate {
    width: 26px;
    height: 26px;
    background-color: ${pickColor(theme?._v1, 'grey', 'i100')};
    color: ${pickColor(theme?._v1, 'grey', 'i500')};
    border-radius: 14px;
    transform: scale(0.7);
    cursor: pointer;
  }

  .navigate-left {
    left: 0px;
  }

  .navigate-right {
    right: 0px;
  }

  .list {
    white-space: nowrap
    padding-left: 26px;
    padding-right: 26px;
    /* important for computing scrolls bondaries */
    width: fit-content;
  }
  `}
`
