import suggestions from '@app/api/resources/suggestions'
import { SuggestionsKey } from '@app/hooks/hooks.types'
import { useQuery } from '@tanstack/react-query'
import { RkvstAsset } from '@ui/types'
import { useState } from 'react'

let clearFlag = 0

interface Params {
  key?: SuggestionsKey
  staleTime?: number
  force?: boolean
}

/**
 * Query to suggestions api for asset attributes
 */
export const useSuggestedAssets = (textSearch: string, params: Params) => {
  if (params.force) {
    clearFlag += 1
  }

  const result = useQuery<string[]>({
    queryKey: [textSearch, 'LoadSuggestedAssets', clearFlag, JSON.stringify(params)],
    queryFn: async () => {
      const res: any = await suggestions.get('asset', textSearch, true, 'attribute', 10, 'v', params.key)

      return [...(res as string[])]
    },
    keepPreviousData: true,
    staleTime: params.staleTime,
  })

  const loading = result.isLoading || result.isFetching || result.isRefetching
  return {
    ...result,
    loading,
  }
}
