import {
  BaseCardMixin,
  bodyFontStyle,
  Button,
  InfiniteScrollTable,
  SearchSvg,
  StyledTable,
  TypeaheadCategories,
} from '@ui/components'
import { pickColor } from '@ui/themes'
import { addSvgAttribute, changeSvgColor, replaceSvgAttribute } from '@ui/utils'
import styled from 'styled-components'

export const InfiniteScroll = styled(InfiniteScrollTable)`
  max-height: 50vh;
` as typeof InfiniteScrollTable

export const AssetListContainer = styled.article`
  padding: 15px;
  ${({ theme }) => bodyFontStyle('md', 'regular', theme)};
  min-height: auto;
  height: 100%;
`

export const AssetListHeader = styled.header`
  ${BaseCardMixin}
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 30px 0px 30px;
  .header {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 24px;
    .text {
      font-size: 2rem;
      line-height: 2rem;
    }
    button {
      float: right;
      height: 40px;
    }
  }
`

export const SubsectionWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => pickColor(theme?._v1, 'grey', 'i200')};
  .subsection-header.subsection-header {
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 60px;
    padding: 12px 8px 12px 16px;
    .subsection-title {
      padding-left: 8px;
      font-weight: 600;
      color: ${({ theme }) => pickColor(theme?._v1, 'grey', 'i900')};
    }
  }
  .map-widget {
    padding: 0px;
  }
  .categories {
    height: 40px;
  }

  @media (max-width: 640px) {
    .subsection-header.subsection-header {
      flex-wrap: wrap;
      height: auto;
    }
  }
`

export const SectionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  width: max-content;
`

export const SectionLi = styled.li`
  ${BaseCardMixin}
  width: 100%;
  margin-top: 4px;
  padding-bottom: 0px;
  box-shadow: none;

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 8px;
    .section-header-actions {
      width: min-content;
      justify-content: flex-end;
      .categories {
        height: 40px;
      }
    }
  }
  .section-header > span {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    width: min-content;
    gap: 8px;
  }

  .section-header-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    margin-left: 12px;
    button {
      height: 32px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 12px;
      font-weight: 700;
      font-family: 'Montserrat';
    }
  }
`

export const CreationButton = styled(Button)`
  &.creation-btn-primary.creation-btn-primary.creation-btn-primary {
    min-width: 200px;
    height: auto;
  }

  @media (max-width: 640px) {
    &.creation-btn-primary.creation-btn-primary.creation-btn-primary.creation-btn-primary {
      width: 100%;
    }
  }
`

export const FilterTypeaheadCategories = styled(TypeaheadCategories)`
  white-space: nowrap;
  input[type='text'],
  input[type='text']::placeholder {
    ${({ theme }) => bodyFontStyle('md', 'regular', theme)};
    color: ${({ theme }) => pickColor(theme?._v1, 'grey', 'i500')};
    font-size: 14px;
  }
  .category-filter {
    position: relative;
  }
  .placeholder-search {
    position: absolute;
    right: 9px;
    top: 6px;
  }
  .categories {
    min-width: 130px;
    height: 40px;
  }

  &.typeahead {
    height: auto;
  }

  @media (max-width: 640px) {
    &.typeahead {
      white-space: normal;
      height: auto;

      .categories.categories {
        margin-top: 16px;
        min-width: 130px;
        height: 40px;
        border-right: 1px solid ${({ theme }) => pickColor(theme?._v1, 'grey', 'i300')};
        border-bottom: 0px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0px;
      }

      & > div {
        width: 100%;
      }

      .category-filter.category-filter {
        width: auto;
      }
    }

    .category-filter,
    .category-filter-default {
      input[type='text'] {
        width: 100%;
        max-width: initial;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 8px;
      }
      & > div > div {
        padding-right: 0px;
        width: 100%;
      }
    }
  }
`

export const AssetListBody = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  margin-top: 0px;
  padding: 8px;
  ${BaseCardMixin}
  border-radius: 0px;

  .chevron {
    height: 1.8em;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .asset-icon {
    height: 2em;
  }
  .title {
    display: inline-block;
  }
  .datatrails {
    letter-spacing: 0px;
  }
  .body {
    clear: both;
    margin-left: -16px;
    margin-right: -16px;
  }
`

export const ScrollWrapper = styled.div`
  max-height: 400px;
  overflow-y: scroll;
  position: relative;
  display: block;

  .no-results,
  .load-more {
    width: 100%;
    padding: 16px;
    text-align: center;
    button {
      width: min-content;
      margin: auto;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }

  tbody {
    opacity: 1;
  }
  &.loading table tbody {
    animation: fadeIn 1.5s infinite alternate;
  }
`

export const SectionTableStyle = styled(StyledTable)`
  width: 100%;
  table-layout: fixed;

  .sortable {
    cursor: pointer;

    svg {
      vertical-align: middle;
      transform: scale(0.8);
    }
  }

  td,
  th {
    max-width: 50%;
  }

  th {
    position: sticky;
    z-index: 1;
    top: 0;
  }

  td {
    cursor: pointer;
  }

  .cell-action {
    display: flex;
    align-items: center;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    svg {
      color: ${({ theme }) => pickColor(theme?._v1, 'grey', 'i400')};
    }
  }

  .download-link span {
    background-color: #fff;
  }

  thead {
    tr {
      display: table-row;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  tbody > tr {
    @media (max-width: 768px) {
      max-width: initial;
      display: flex;
      flex-direction: column;
      border: none;

      padding: 8px 0 8px 0;

      border-bottom: 1px solid ${({ theme }) => pickColor(theme._v1, 'grey', 'i200')};

      &:nth-child(even):not(:hover) {
        background-color: ${({ theme }) => pickColor(theme._v1, 'grey', 'i50')};
      }
    }
  }

  tbody > tr > td {
    @media (max-width: 768px) {
      max-width: initial;
      padding: 8px 16px 8px 16px;
      border-bottom: none;
      width: 100%;

      .cell-action > span {
        max-width: 100%;
      }

      &:before {
        content: attr(data-content);
        max-width: 120px;
        width: 50%;
        float: left;
        display: block;
        word-wrap: normal;

        ${({ theme }) => `
          font-size: ${theme._v1.typography.body.size.md.px};
          line-height: ${theme._v1.typography.body.size.md.hpx};
          font-family: ${theme._v1.typography.body.family};
          font-weight: ${theme._v1.typography.weight['medium']};
        `}
      }
    }
  }
`

export const SearchInput = styled.input`
  ${({ theme }) => `
    background-image: url(${changeSvgColor(
      replaceSvgAttribute(
        addSvgAttribute(SearchSvg, 'style', 'transform:translate(-4px,0) scale(0.975);'),
        'width',
        '34'
      ),
      pickColor(theme?._v1, 'grey', 'i500')
    )});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    padding: 12px;
    border-radius: 8;
    width: 320px;
    height: 40px;
    padding: 10px 14px 10px 14px;
    padding-right: 46px;
    border: 1px solid ${pickColor(theme?._v1, 'grey', 'i300')};
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  `}
`

// Setction tables has some specialy negative padding. this is
// for wrapping an alternative content to be placed inside
// the containe of the table, instead of the table itself.
// e.g. error handling, etc...
export const SectionNonTableBody = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  .alert {
    margin-top: 16px;
    a {
      color: inherit;
    }
  }
`

export const DropzoneCateogry = styled.div`
  &.category-filter.category-filter.category-filter {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    & > section.inline {
      width: 250px;
      .drop-content {
        width: 100%;
      }
    }

    & > section.inline.selected {
      .drop-content {
        width: 50px;
      }
      .filename {
        max-width: 150px;
        display: inline-block;
        max-height: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`

export const StyledForm = styled.form`
  @media (max-width: 640px) {
    width: 100%;
  }
`
