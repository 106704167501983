import { RkvstResourceCaps } from '@ui/types'
import { AxiosRequestConfig } from 'axios'

import { RkvstAsset, RkvstCreateAsset, RkvstCreateEvent, RkvstEvent, TrackingStatus } from '../../state/initialState'
import api from '../core'
import { FilterQuery } from '../FilterQuery'
import { AttachmentInterface } from './attachments'
import { Model } from './Model'

const group = null
const resource = 'assets'
const version = '2'
const capsUrl = api.parseCapsUrl(resource)
const resourceUrl = api.parseResourceUrl(group, resource, version)

export type AssetsFilterParams = {
  name?: string
  page?: number
  page_size?: number
  document?: boolean
  dropbox?: boolean
  order?: string
  type?: string
  version?: string
  dropboxPath?: string
  attestation?: 'PRIVACY_UNSPECIFIED' | 'RESTRICTED' | 'PUBLIC'
  hash?: string
  tracked?: TrackingStatus | 'ANY'
  locationId?: string
  sampleTag?: string
  hasLocations?: boolean
}

export interface UiAssetsResponse {
  assets: RkvstAsset[]
  next_page_token?: string
}

export interface RkvstEventsResponse {
  events: RkvstEvent[]
  next_page_token: string
}

export class CreateAsset extends Model<RkvstCreateAsset> {
  public data: RkvstCreateAsset = {
    behaviours: [],
    chain_id: '',
    proof_mechanism: 'MERKLE_LOG',
    public: false,
    attributes: {},
  }

  public addAttribute(name: string, attribute: string) {
    if (!this.data.attributes) {
      this.data.attributes = {}
    }

    this.data.attributes[this.escapeKey(name)] = attribute
    return this
  }

  public addAttachments(attachments: AttachmentInterface[]) {
    if (!this.data.attributes) {
      this.data.attributes = {}
    }

    attachments.forEach((attachment: AttachmentInterface) => {
      if (this.data) {
        this.data.attributes[this.escapeKey(attachment.attachment_key)] = {
          arc_attribute_type: 'arc_attachment',
          arc_blob_identity: attachment.arc_blob_identity,
          arc_blob_hash_alg: attachment.arc_blob_hash_alg,
          arc_blob_hash_value: attachment.arc_blob_hash_value,
          arc_file_name: attachment.arc_file_name,
          mime_type: attachment.mime_type,
        }
      }
    })
    return this
  }
}

export class Asset extends Model<RkvstAsset> {
  public static DOCUMENT_STATUS_WITHDRAWN = 'Withdrawn'
  public static DOCUMENT = 'Document'

  public get(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)

    return api.get<Asset>(`${resourceUrl}/${strippedId}`)
  }

  public async getPublicAssetUrl(asset: RkvstAsset) {
    if (asset && asset.public) {
      try {
        const response: any = await this.getAssetPublicUrl(asset?.identity)
        if (response !== undefined && response !== '') {
          return response.publicurl
        }
      } catch (e) {
        // catch
      }
    }
  }

  public getList(params?: any) {
    return api.get<UiAssetsResponse>(resourceUrl, params)
  }

  public getListFiltered(params: AssetsFilterParams = {}, nextPageToken?: string, config?: AxiosRequestConfig) {
    const filterQuery = new FilterQuery({
      page_size: ((params.page_size || 50) * (params.page || 1)).toString(),
      order_by: params.order || 'DEFAULT',
    })

    if (params.name) {
      filterQuery.putAttribute('arc_display_name', params.name)
    }

    if (params.version) {
      filterQuery.putAttribute('document_version', params.version)
    }

    if (params.hash) {
      filterQuery.putAttribute('document_hash_value', params.hash)
    }

    if (params.type) {
      filterQuery.putAttribute('arc_display_type', params.type)
    }

    if (params.dropboxPath !== undefined) {
      filterQuery.putAttribute('dropbox_path', params.dropboxPath)
    }

    if (params.tracked) {
      filterQuery.put('tracked', params.tracked)
    }

    if (params.attestation !== undefined) {
      filterQuery.put('privacy', params.attestation)
    }

    if (params.document === true) {
      filterQuery.putAttribute('arc_profile', Asset.DOCUMENT)
    } else if (params.document === false) {
      filterQuery.putAttribute('arc_profile!', '*')
    }

    if (params.dropbox === true) {
      filterQuery.putAttribute('dropbox_id', '*')
    } else if (params.dropbox === false) {
      filterQuery.putAttribute('dropbox_id!', '*')
    }

    if (params.hasLocations === true) {
      filterQuery.putAttribute('arc_home_location_identity', '*')
    } else if (params.hasLocations === false) {
      filterQuery.putAttribute('arc_home_location_identity!', '*')
    }

    if (params.locationId) {
      filterQuery.putAttribute('arc_home_location_identity', params.locationId)
    }

    if (params.sampleTag) {
      filterQuery.putAttribute('OnboardingSampleID', params.sampleTag)
    }

    if (nextPageToken && !params.order) {
      filterQuery.put('page_token', nextPageToken)
    }

    const query = filterQuery.build()

    return api.get(resourceUrl + query, undefined, config)
  }

  public getWithoutLocation() {
    const filterQuery = new FilterQuery()

    // the syntax here is 'arc_home_location_identity!=*'
    // to query for assets without the  arc_home_location_identity field set
    filterQuery.putAttribute('arc_home_location_identity!', '*')

    const query = filterQuery.build()

    return api.get(resourceUrl + query)
  }

  public getOne(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)

    return api.get<RkvstAsset>(`${resourceUrl}/${strippedId}`)
  }

  public create(asset: RkvstCreateAsset) {
    return api.post(resourceUrl, asset)
  }

  public static isDocument(asset?: RkvstAsset) {
    return asset?.attributes?.arc_profile === Asset.DOCUMENT
  }

  public static isWithdrawn(asset?: RkvstAsset) {
    return asset?.attributes?.document_status?.toString() === Asset.DOCUMENT_STATUS_WITHDRAWN
  }

  public startTracking(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)

    return api.post(`${resourceUrl}/${strippedId}/events`, {
      operation: 'StartTracking',
      behaviour: 'Builtin',
    })
  }

  public stopTracking(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)

    return api.post(`${resourceUrl}/${strippedId}/events`, {
      operation: 'StopTracking',
      behaviour: 'Builtin',
    })
  }

  public getEventList(id: string, nextPageToken?: string) {
    const strippedId = api.stripResourceFromId(resource, id)

    const filterQuery = new FilterQuery({
      page_size: '50',
      order_by: 'DEFAULT',
    })

    if (nextPageToken) {
      filterQuery.put('page_token', nextPageToken)
    }

    const query = filterQuery.build()

    return api.get<RkvstEventsResponse>(`${resourceUrl}/${strippedId}/events` + query)
  }

  public createEvent(id: string, event: RkvstCreateEvent) {
    const strippedId = api.stripResourceFromId(resource, id)
    return api.post(`${resourceUrl}/${strippedId}/events`, event)
  }

  public getOneEvent(eventId: string) {
    const strippedId = api.stripResourceFromId(resource, eventId)
    return api.get(`${resourceUrl}/${strippedId}`)
  }

  public parseAssetId(id: string) {
    return Asset.parseAssetId(id)
  }
  public static parseAssetId(id: string) {
    return id.replace(/^publicassets\//g, '').replace(/^assets\//g, '')
  }

  public getTransactions(assetId: string, eventId: string, nextPageToken?: string) {
    // note, this differs from the standard routing for now, and I'm also not
    // confident this is the right place for this call to live, however for now
    // just 'hardcode' the path here.
    //
    // The way the API works with resource IDs etc
    // is due a genral refactor, the path shouldn't need to be built from
    // individual id components like this, it should just take the entire
    // event resource ID. But again, a concern for later when this API is solidified.
    //
    const path =
      `/v1alpha2/blockchain/assets/${assetId}/events/${eventId}` + (nextPageToken ? `?page_token=${nextPageToken}` : '')

    return api.get(path)
  }

  public getCaps(): Promise<RkvstResourceCaps> {
    return api.get(`${capsUrl}`)
  }

  public getAssetPublicUrl(id: string): Promise<any> {
    return api.get(`${resourceUrl}/${this.parseAssetId(id)}:publicurl`)
  }

  public stripResourceFromId(id: string) {
    return api.stripResourceFromId('assets', id)
  }
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export default new Asset()
