import React, { useEffect, useState } from 'react'

import Img from '../../components/Img'
import { WidgetLoadingOverlay } from '../../components/Loading'
import { Container } from './Modal.style'
import { ModalProps } from './Modal.types'

const getClassName = (props: ModalProps, active: boolean, open: boolean) => {
  let className = `modal modal-${props.size}`

  if (active) {
    className += ' active'
  }

  if (open) {
    className += ' open'
  }

  if (props.className) {
    className += ` ${props.className}`
  }

  return className
}

const ModalComponent: React.FC<ModalProps> = (props) => {
  const [active, setActive] = useState(false)
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setActive(true)

    setTimeout(() => {
      setOpen(true)
    }, 50)
  }

  const closeModal = () => {
    setOpen(false)

    setTimeout(() => {
      setActive(false)

      props.onClose && props.onClose()
    }, 500)
  }

  const keyDown = (e: KeyboardEvent) => {
    if (e.key == 'Escape') {
      closeModal()
    }
  }

  useEffect(() => {
    openModal()
    window.addEventListener('keydown', keyDown)
    return () => {
      window.removeEventListener('keydown', keyDown)
    }
  }, [])

  return (
    <Container className={getClassName(props, active, open)} css={props.css}>
      <div className="modal-wrapper">
        <div className="modal-container" data-test="modal-container">
          {props?.loading && <WidgetLoadingOverlay />}
          {props?.onClose && (
            <button
              className="button-transparent close"
              onClick={closeModal}
              id={`${typeof props.name === 'undefined' ? 'modal' : props.name}-close-button`}
              data-test="close-modal-button"
            >
              <Img name="close-icon" alt="X" />
            </button>
          )}

          <div className="modal-body">{props.children}</div>
        </div>
      </div>
    </Container>
  )
}

const Modal = ModalComponent

export default Modal
