import { lazily } from 'react-lazily'

import { wrapVisibleSuspense } from '../../atoms/placeholders/Suspense'

const { FormInput: RawFormInput } = lazily(() => import('./FormInput'))
const FormInput = wrapVisibleSuspense<typeof RawFormInput>(RawFormInput)
export { FormInput }

const { FormCheckBox: RawFormCheckBox } = lazily(() => import('./FormCheckBox'))
const FormCheckBox = wrapVisibleSuspense<typeof RawFormCheckBox>(RawFormCheckBox)
export { FormCheckBox }
