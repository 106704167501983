import useAlerts from '@app/hooks/useAlerts'
import withRoleBasedAccess from '@app/roleBasedAccess'
import actionCreators from '@app/state/actionCreators'
import { fetchAppRegistrations } from '@app/state/actions/appRegistrations'
import { queryClient } from '@app/state/queryClient'
import { SectionPlaceholder } from '@app/suspense'
import { withRouterParams } from '@app/withRouterParams'
import { Button, H, PlusIcon } from '@ui/components'
import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { useAppDispatch } from '../../hooks'
import { getDropboxConnection } from '../Integrations/Integtrations.slice'
import { AssetListBody, AssetListContainer, AssetListHeader } from './components/AssetList/AssetList.styles'
import { AssetSection } from './components/AssetList/AssetSection'
import { DropboxSection } from './components/AssetList/DropboxSection'
import { AssetListEmptyState } from './components/AssetList/EmptyState'
import { MapSection } from './components/AssetList/MapSection'
import { useHasAssets } from './hooks/useAssetList'
import { usePolling } from './hooks/usePolling'

interface AssetListProps {}

export const AssetList = (props: AssetListProps) => {
  const dispatch = useAppDispatch()
  const { setAlert } = useAlerts()
  const { t } = useTranslation()
  const [refetch, setFetch] = useState(new Date().getTime())
  const navigate = useNavigate()

  const { loading, hasAnyAssets, isPolling } = useHasAssets((message) => {
    if (!isPolling) {
      setAlert({ type: 'error', message })
    }
  }, usePolling({ attempts: 5, frequency: 1000, refetch }))

  useEffect(() => {
    dispatch(fetchAppRegistrations(() => undefined))
    dispatch(getDropboxConnection())
    dispatch(actionCreators.locations.getList())
  }, [])

  return (
    <AssetListContainer>
      <AssetListHeader>
        <H sz="md" weight="bold" className="header">
          <span data-test="page-title" className="text">
            {t('Assets Overview')}
          </span>
        </H>
      </AssetListHeader>

      {loading ? (
        <SectionPlaceholder height="100%" />
      ) : !hasAnyAssets ? (
        <AssetListEmptyState
          onUpdate={() => {
            queryClient.clear()
            setFetch(new Date().getTime())
          }}
        />
      ) : (
        <AssetListBody>
          <MapSection key="asset-list-map-section" />
          <AssetSection key="asset-list-asset-section" />
          <DropboxSection key="asset-list-dropbox-section" />
        </AssetListBody>
      )}
    </AssetListContainer>
  )
}

const AssetListRoute = withRoleBasedAccess(withRouterParams((props: any) => <AssetList />))

export default AssetListRoute
