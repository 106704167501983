import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import ReactTooltip from 'react-tooltip'

import { useAppDispatch, useAppSelector } from '../../../hooks'
import i18n from '../../../i18n'
import actionCreators from '../../../state/actionCreators'
import { RootState } from '../../../state/store'
import InputTypeAheadSelect from '../../Form/InputTypeAheadSelect'
import Img from '../../Img'
import Attachments from '../Attachments'
import InputText from '../InputText'
import showError from '../showError'
import { Container } from './ExtendedAttributes.style'
import { ExtendedAttributesProps } from './ExtendedAttributes.types'

export const ExtendedAttributes: React.FC<ExtendedAttributesProps> = (props) => {
  const dispatch = useAppDispatch()
  const [suggestions, setSuggestions] = useState<string[]>([])
  const blobsCapReached = useAppSelector((state: RootState) => state.blobs.capReached)

  useEffect(() => {
    dispatch(actionCreators.blobs.getCaps())
  }, [blobsCapReached])

  return (
    <FieldArray name={props.type}>
      {({ fields, meta }) => (
        <Container className={`attributes` + (meta.error ? ' input-error' : '')}>
          <table className="legacy-table">
            <thead>
              <tr>
                <th>{props.keyHeading ? props.keyHeading : i18n.t('form:extended_attributes.key')}</th>
                <th>{props.valueHeading ? props.valueHeading : i18n.t('form:extended_attributes.value')}</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((name, fieldIndex) => {
                return (
                  <tr key={`${props.type}-${fieldIndex}`}>
                    <td className={`attribute key` + (props.disabled ? ' disabled' : '')}>
                      <Field name={`${name}.key`}>
                        {(fieldProps: any) => {
                          if (
                            meta.initial &&
                            fieldProps.input.value === meta.initial[fieldIndex]?.key &&
                            props.disableExistingKeyValues
                          ) {
                            return <p>{fieldProps.input.value}</p>
                          }
                          if (fieldProps.input.value.length < 0) {
                            return
                          }
                          if (props.suggestionsGetter) {
                            return (
                              <InputTypeAheadSelect
                                id={`${props.type}_${fieldIndex}_key`}
                                className={'combobox'}
                                placeholder={i18n.t('form:extended_attributes.attribute_name_prompt')}
                                disabled={props.disabled}
                                options={suggestions}
                                threshold={props.threshold}
                                onFocus={async () => {
                                  if (props.suggestionsGetter) {
                                    const suggestions = await props.suggestionsGetter(fieldProps?.input?.value)
                                    setSuggestions(suggestions)
                                  }
                                }}
                                loadNewOptions={async () => {
                                  if (props.suggestionsGetter) {
                                    const suggestions = await props.suggestionsGetter(fieldProps?.input?.value)
                                    setSuggestions(suggestions)
                                  }
                                }}
                                clearOptions={() => {
                                  setSuggestions([])
                                }}
                                {...fieldProps}
                              />
                            )
                          } else {
                            return (
                              <InputText
                                id={`${props.type}_${fieldIndex}_key`}
                                className={'text'}
                                placeholder={i18n.t('form:extended_attributes.attribute_name_prompt')}
                                disabled={props.disabled}
                                {...fieldProps}
                              />
                            )
                          }
                        }}
                      </Field>
                    </td>
                    <td className={`value` + (props.disabled ? ' disabled' : '')}>
                      {fields.value[fieldIndex]?.type == 'keyvalue' && (
                        <Field name={`${name}.value`}>
                          {(fieldProps) => {
                            return (
                              <InputText
                                id={`${props.type}_${fieldIndex}_value`}
                                placeholder={i18n.t('form:extended_attributes.attribute_value_prompt').toString()}
                                disabled={props.disabled}
                                {...fieldProps}
                              />
                            )
                          }}
                        </Field>
                      )}
                      {fields.value[fieldIndex]?.type == 'attachment' && (
                        <Field name={`${name}.value`}>
                          {(fieldProps) => {
                            return (
                              <Attachments
                                id={`${props.type}_${fieldIndex}_value`}
                                allowmultiple={false}
                                attachments={fields.value[fieldIndex].value ? [fields.value[fieldIndex].value] : []}
                                {...fieldProps}
                              />
                            )
                          }}
                        </Field>
                      )}
                    </td>
                    <td className="icon delete">
                      <button
                        type="button"
                        data-test={`delete-attribute-row${fieldIndex}`}
                        className="button button__delete button--transparent"
                        onClick={() => fields.remove(fieldIndex)}
                      >
                        <Img name="Delete-Icon" alt="Delete" />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <button
            id="add-attribute-button"
            className="button button-action"
            disabled={!!props.disabled}
            onClick={() => {
              if (!props.disabled) {
                fields.push({
                  type: 'keyvalue',
                  key: '',
                  value: '',
                })
              }
            }}
            type="button"
            data-test="add-attribute"
          >
            {props.addButtonText ? props.addButtonText : i18n.t('form:extended_attributes.add_attribute')}
          </button>

          <button
            id="add-attachment-button"
            className="button button-action"
            disabled={props.disabled || blobsCapReached}
            data-tip
            data-for="blob-cap-reached-tooltip"
            onClick={() => {
              if (!props.disabled) {
                fields.push({
                  type: 'attachment',
                  key: '',
                  value: '',
                })
              }
            }}
            type="button"
          >
            {props.addButtonText ? props.addButtonText : i18n.t('form:extended_attributes.add_attachment')}
          </button>

          {blobsCapReached && (
            <ReactTooltip id="blob-cap-reached-tooltip" delayShow={200}>
              <span>{i18n.t('globals:cap_reached_description_msg')}</span>
            </ReactTooltip>
          )}

          {showError(meta) && <span className="error-message">{meta.error}</span>}
        </Container>
      )}
    </FieldArray>
  )
}

export default ExtendedAttributes
