import { ensurePublicIdentity } from '@ui/utils'

import api from '../core'
import { FilterQuery } from '../FilterQuery'

const group = null
const resource = 'publicassets'
const version = '2'
const resourceUrl = api.parseResourceUrl(group, resource, version)

export default {
  get(id: string) {
    const strippedId = api.stripResourceFromId('publicassets', ensurePublicIdentity(id))
    return api.get(`${resourceUrl}/${strippedId}`)
  },

  getEventList(id: string, nextPageToken?: string) {
    const parsedId = this.parseAssetId(id)
    const filterQuery = new FilterQuery({
      page_size: '50',
      order_by: 'DEFAULT',
    })

    if (nextPageToken) {
      filterQuery.put('page_token', nextPageToken)
    }

    const query = filterQuery.build()

    return api.get(`${resourceUrl}/${parsedId}/events` + query)
  },

  // XXX: This interface feels misguided. The event identity includes the asset.
  // Splitting it like this is awkward.
  getOneEvent(id: string, eventId: string) {
    const parsedId = this.parseAssetId(id)
    return api.get(`${resourceUrl}/${parsedId}/events/${eventId}`)
  },

  parseAssetId(id: string) {
    // XXX: Remove ensurePublicIdentity from the codebase
    return ensurePublicIdentity(id).replace(/^publicassets\//g, '')
  },

  stripResourceFromId(id: string) {
    return id.split(`${resource}/`)[1]
  },
}
