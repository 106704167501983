import { lazily } from 'react-lazily'

import { wrapVisibleSuspense } from '../../atoms/placeholders/Suspense'

const { CheckBox: RawCheckBox, CheckBoxInput: RawCheckBoxInput } = lazily(() => import('./CheckBox'))
const CheckBox = wrapVisibleSuspense<typeof RawCheckBox>(RawCheckBox)
const CheckBoxInput = wrapVisibleSuspense<typeof RawCheckBoxInput>(RawCheckBoxInput)
export { CheckBox, CheckBoxInput }

const { InputFocusOutline: RawInputFocusOutline, Input: RawInput } = lazily(() => import('./Input'))
const InputFocusOutline = wrapVisibleSuspense<typeof RawInputFocusOutline>(RawInputFocusOutline)
const Input = wrapVisibleSuspense<typeof RawInput>(RawInput)
export { Input, InputFocusOutline }
