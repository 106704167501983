import { lazily } from 'react-lazily'

import { wrapVisibleSuspense } from '../../atoms/placeholders/Suspense'

export { bodyFontStyle } from './Typography.styles'

const { H: RawH } = lazily(() => import('./H'))
const H = wrapVisibleSuspense<typeof RawH>(RawH)
export { H }

const { BodyText: RawBodyText } = lazily(() => import('./Text'))
const BodyText = wrapVisibleSuspense<typeof RawBodyText>(RawBodyText)
export { BodyText }
