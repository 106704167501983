import { css } from 'styled-components'

export const modalStyles = css`
  .asset-list {
    margin-top: 20px;

    /* hide these columns in the assets list */
    .location,
    .edit,
    .delete {
      display: none;
    }

    /* also hide all filters on column headers - they don't work on this list */
    th.with-filter > img {
      display: none;
    }
  }
`
