import { useEffect, useState } from 'react'

export const EMPTY_FILTER_VALUE = ''
export const EMPTY_FILTER_VALUE_LABEL = '[empty]'

export type FilterModel = {
  labels: Array<string>
  values: Array<string>
  filters: { [key: string]: boolean }
  setFilter: (key: string, value: boolean) => void
  update: (labels: Array<string>, values: Array<string>) => void
  selectAll: () => void
  unselectAll: () => void
  allSelected: boolean
}

const buildFilterModel = (
  labels: Array<string>,
  values: Array<string>,
  emptyOption: boolean,
  initiallyFalseValues: Array<string>
) => {
  const filters: any = {}

  if (emptyOption) {
    labels = [EMPTY_FILTER_VALUE_LABEL, ...labels]
    values = [EMPTY_FILTER_VALUE, ...values]
    filters[EMPTY_FILTER_VALUE] = true
  }

  values.forEach((value) => {
    if (value) {
      filters[value.trim()] = !initiallyFalseValues.includes(value)
    }
  })

  return {
    labels,
    values,
    filters,
    allSelected: initiallyFalseValues.length === 0,
  }
}

export default (
  labels: Array<string>,
  values: Array<string>,
  emptyOption: boolean,
  initiallyFalseValues: Array<string> = [],
  filterChangeCallback: () => void | undefined
): FilterModel => {
  const initialFilterModel = buildFilterModel(labels, values, emptyOption, initiallyFalseValues)
  const [state, setState] = useState(initialFilterModel)

  const setFilter = (key: string, value: boolean) => {
    setState({
      ...state,
      filters: {
        ...state.filters,
        [key]: value,
      },
    })
  }

  useEffect(() => {
    filterChangeCallback()
  }, [state])

  const update = (labels: Array<string>, values: Array<string>) => {
    const updatedFilterModelWithNewValues = buildFilterModel(labels, values, emptyOption, initiallyFalseValues)

    // IMPORTANT
    //
    // only set all new filter values true if 'select all' option is set
    //
    // if it's not set, any new filter values that load in from the API
    // should not be included in results - this is because we load results
    // unfiltered from the API and then filter them afterwards on the client.
    // If we load in new pages, we have to ensure that any new filter types
    // that are derived from the API results are not defaulted to be included
    // in filter results
    //
    // an easy way to do this is simply to set all udpated filter values false
    // if 'select all' is not selected, then merge the existing selected filter
    // values on top
    //
    // Note that this is quite messy and is a result of doing client-side filtering.
    // It'll get a lot simpler once we start using the filtering at the API level
    // at which point we can pretty much throw out this entire concept of client filterModels
    // and just have simple filter state which can be used to set filter params on the API call
    // when we load each new page
    let updatedFilters: any = {}

    // if 'select all' selected, set all filter types true unless in initiallyFalseValues array,
    // otherwise, set all false
    if (state.allSelected) {
      updatedFilters = {
        ...updatedFilterModelWithNewValues.filters,
      }
    } else {
      Object.keys(updatedFilterModelWithNewValues.filters).forEach((key) => {
        updatedFilters[key] = false
      })
    }

    setState({
      ...updatedFilterModelWithNewValues,

      // preserve existing filter values by merging on top of new ones
      filters: {
        ...updatedFilters,
        ...state.filters,
      },

      // preserve existing 'select all' value
      allSelected: state.allSelected,
    })
  }

  const selectAll = () => {
    const filtersAllSelected: any = {}

    state.values.forEach((value) => {
      if (value || value === '') {
        filtersAllSelected[value.trim()] = true
      }
    })

    setState({
      ...state,
      allSelected: true,
      filters: filtersAllSelected,
    })
  }

  const unselectAll = () => {
    const filtersAllUnselected: any = {}

    state.values.forEach((value) => {
      filtersAllUnselected[value.trim()] = false
    })

    setState({
      ...state,
      allSelected: false,
      filters: filtersAllUnselected,
    })
  }

  return {
    labels: state.labels,
    values: state.values,
    filters: state.filters,
    allSelected: state.allSelected,
    setFilter,
    update,
    selectAll,
    unselectAll,
  }
}
