export const showError = (meta: any): boolean => (!meta.dirtySinceLastSubmit || meta.error) && !!meta.submitFailed

export const getArrayFieldErrorText = (meta: any) => {
  const hasError = showError(meta)
  let errorText = ''

  if (hasError) {
    const errorHash = {}
    meta.error?.forEach((field: any) => {
      if (field?.error) {
        errorHash[field?.error] = true
      }
    })
    errorText = Object.keys(errorHash).join(', ')
  }

  return errorText
}

export default showError
