import { resolveMime } from 'friendly-mimes'
import React, { useEffect, useRef, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { Modal } from '../../../../../common/Modal'
import i18n from '../../../../../i18n'
import useGetAttachments from './hooks/useGetAttachments'
import { ProgressContainer } from './UploadAttachmentsModal.styles'
import { UploadAttachmentsModalProps } from './UploadAttachmentsModal.types'

const DEFAUL_MIME_TYPE = 'application/octet-stream'

const UploadAttachmentsModalComponent: React.FC<UploadAttachmentsModalProps> = ({ ...props }) => {
  const { attachmentsData } = props
  const { currentFileCount, filename, fileTypeName, getAttachments, progress, totalFileCount } = useGetAttachments()
  const [filenameResult, setFilenameResult] = useState<string>('')
  const [fileType, setFileType] = useState<string>('')
  const isMounted = useRef(true)

  useEffect(() => {
    const fileNameTest = (() => {
      if (filename?.length === 0) {
        return i18n.t('assets:unknown_filename')
      }
      if (filename?.length > 15) {
        const filesplit = filename.split('.')
        return `${filesplit[0].substring(0, 25)}...`
      }
      return filename
    })()
    setFilenameResult(fileNameTest)
  }, [filename])

  useEffect(() => {
    try {
      const { fileType }: any = fileTypeName ? resolveMime(fileTypeName) : { fileType: DEFAUL_MIME_TYPE }
      setFileType(fileType.replace('.', '').toUpperCase() || DEFAUL_MIME_TYPE)
    } catch (e) {
      setFileType(DEFAUL_MIME_TYPE)
    }
  }, [fileTypeName])

  useEffect(() => {
    // apply attachments data to state function passed in from parent
    if (isMounted.current) {
      ;(async () => {
        // get attachments data from uploaded attachments
        const attachments = await getAttachments(attachmentsData)
        // check for error, if all ok create the asset or event, close modals

        if ('errorFound' in attachments && attachments?.errorFound) {
          // show error message
          props.errorUploadingAttachments(i18n.t('assets:uploading_attachments_error_message'))
        } else {
          if (props.uploadAttachmentsModalVisible && 'result' in attachments) {
            // create asset or event
            props.create(attachments.result)
            // close both modals
            props.closeModals()
          }
        }
      })()
    }
    return () => {
      isMounted.current = false
    }
  }, [attachmentsData])

  /**
   * to add a cross to the top
   * apply the following to the Modal component
   * onClose={closeAttachmentsModal}
   */

  return (
    <Modal size="large">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>
              <span role="uploadheading" aria-label="file-upload-heading">
                {i18n.t('assets:uploading_attachments')}
              </span>
            </h3>
          </div>
        </div>
        <ProgressContainer>
          <div className="file-type" role="filetype">
            <span role="type" aria-label="file-type">
              {fileType}
            </span>
          </div>
          <div className="container">
            <h4 className="file-name" role="name" aria-label="file-name">
              {filenameResult}
            </h4>
            <div className="progress-container">
              <ProgressBar now={progress} />
              <div className="progress-totals">
                <div className="file-count" role="count" aria-label="file-count">
                  {currentFileCount} of {totalFileCount} uploading
                </div>
                <div className="progress-total" role="progress" aria-label="upload-progress">
                  {progress}% uploaded
                </div>
              </div>
            </div>
          </div>
        </ProgressContainer>
      </div>
    </Modal>
  )
}

const UploadAttachmentsModal = UploadAttachmentsModalComponent
export default UploadAttachmentsModal
