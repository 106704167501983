/*********************************************
 * Tree sturctures to be used in React
 *********************************************
 * Please do note that due to design dessions in react it does not handle well
 * recursively data structures such as trees. This impacts the design of this
 * data structures. React requires immutabilty. If a structure do not change
 * in a immutable way this will easly bring a component not rerendering or
 * even worst, wrongly cached. This is a React tehcnical limitations.
 *
 * In order to have immutabilty we need the tree to be serializable in everymoment.
 * those we should aboid cicular efernece shuch storing the refernece of a node
 * in any node which is not its direct parent.
 */

/**
 * Dto for ui tree strctures
 */
export interface TreeNodeDto<T = any> {
  // Array containing the children of a node
  children: Array<TreeNodeDto<T>>;
  // Path to th node from the parent. For instance the root will be []
  // and the first child of the root [0] and the first child of the first child [0, 0]
  path: number[];
  // Node id. Is which position ocupies as a child inside his parent
  nodeId: number;
  // Content of the node. The relevant / busniess data that the node contains
  content: T;
  // Indicates that the not is not selected but one of its children it is
  thirdState?: boolean;
  // Indicates if this node is open or close (in case of tree explorers)
  open?: boolean;
  // Indicate if the node has never been opened or
  // still it haven't (in case of tree explorers)
  opened?: boolean;
  // Indicates if the node is loading its children.
  // In case of lazy loaded trees where the tree is loaded as we go
  loading?: boolean;
  // Indicates if the node has already been loaded or not.
  // In case of lazy loaded trees where the tree is loaded as we go
  loaded?: boolean;
  // Indicates if the node is selected or not
  // In case of selectable trees
  selected: boolean;
  [k: string]: any;
}

// Check if a paricular node is the root ot a tree
export const isTreeRoot = (node: TreeNodeDto) => !node.path.length;

/**
 * Gets the node from a specific path. It return the undefined node
 * if any node is not found in thi path
 *
 * @param tree [TreeNodeDto<T>] tree where the node and the path belongs
 * @param path [number[]] path to the node
 */
export const getNodeFromPath = <T = any>(
  tree: TreeNodeDto<T>,
  path: number[]
) => {
  let currentNode: TreeNodeDto<T> | undefined = tree;

  // High performance for loop
  let c = 0;
  const N = path.length;
  for (; c < N; c += 1) {
    if (currentNode?.children.length) {
      currentNode = currentNode.children[path[c]];
    }
  }

  return currentNode;
};

/**
 * Gets the node from a specific path specified with the content instead with
 * the tree path
 *
 * @param tree [TreeNodeDto<T>] tree where the node and the path belongs
 * @param contentPath [T[]] path to the node specified by the content. So if T = string
 *                        then a content Path might be ["rootChild1Content", "Child4Content", ...]
 *
 * @return [{node: TreeNodeDto | undefined, depth: number}] Returns the node matching the content path,
 *          taking the first child matching the content at each level. If any node in the content path cannot
 *          be found, then { undefined, <last searched depth>} will be returned. depth is the last depth
 *          found where the path was possible to be followed which might not be the whole path
 *          but one parent of the node. Node is the found node which also is the last node
 *          that was possible to find folliwng the path. Useful for when we want to specify
 *          paths that still haven't been loaded. For instance in case of lazy tree manpulations
 *
 * @pre For a given parent each nodes have to has unique content
 */
export const getNodeFromContentPath = <T = any>(
  tree: TreeNodeDto<T>,
  contentPath: T[]
) => {
  let currentNode: TreeNodeDto<T> | undefined = tree;
  let depth = 0;

  // High performance for loop
  let i = 0;
  const N = contentPath.length;
  for (; i < N; i += 1) {
    if (currentNode?.children.length) {
      depth += 1;
      currentNode = currentNode.children.find(
        (node: TreeNodeDto<T>) => node.content === contentPath[i]
      );
    }
  }

  return { node: currentNode, depth };
};

/**
 * Get the content of a given tree path
 *
 * @usage
 * hovercraft - engines - eels o
 *                      - gears x
 *            - deposit o - fuel x
 *            - turbine x
 *            - cocpit  - seat x
 *                      - more eels o
 *
 * const content = getTreePathContent(tree, [0, 3, 1])
 * content = ['hovercraft', 'cocpit', 'more eels']
 */
export const getTreePathContent = <T = any>(
  tree: TreeNodeDto<T>,
  path: number[]
): T[] => {
  const content: T[] = [];
  let currentNode: TreeNodeDto<T> | undefined = tree;

  // High performance for loop
  let i = 0;
  const N = path.length;
  for (; i < N; i++) {
    if (currentNode?.children.length) {
      currentNode = currentNode.children[path[i]];
      if (currentNode) {
        content.push(currentNode.content);
      }
    }
  }

  return content;
};

/**
 * Sets a node inside the tree. It looks the node path
 * and status and set's the tree accordingly.
 * So if the tree has a possible path [0, 3, 1] and the
 * node has the set path [0, 3, 1] then the node inside the
 * tree with the [0, 3, 1] will be set. If it was not
 * previously a node with this path then it will be created
 *
 * @param tree [TreeNode<T>] tree where we want to set the node
 * @param node [TreeNode<T>] node which we wish to set inside the node.
 *                      The node path will be used in order to determine
 *                      the position of the node inside the tree
 *
 * @return [TreeNode<T>] the same tree handedl with the param tree which will
 *          have the node set
 *
 * @post the prvided tree will be updated
 */
export const setTreeChild = <T = any>(
  tree: TreeNodeDto<T>,
  node: TreeNodeDto<T>
) => {
  // Is seting the root node
  if (isTreeRoot(node)) {
    return node;
  }

  // Is not the root
  const nodeParent = getNodeFromPath(
    tree,
    node.path.slice(0, node.path.length - 1)
  );

  if (nodeParent) {
    nodeParent.children[node.nodeId] = node;
  }

  return tree;
};

/**
 * Select the selection status of a node and recursively all the decendents
 * of a specificy node
 */
const setSubtreeSelection = (node: TreeNodeDto, selection: boolean) => {
  node.thirdState = false;
  node.selected = selection;

  // High performance for loop
  let i = 0;
  const N = node.children.length;
  for (; i < N; i += 1) {
    setSubtreeSelection(node.children[i], selection);
  }
};

/**
 * Applies selection node for a TreeNodeDto in the context of another tree.
 * Is not just seting the selection flags for a specific node but it also
 * modify the tree status in ordr to represent the new selected node.
 *
 * For instance if a folder is unselected and it's subfolders are displayed. Then
 * if the user select it we need to set the state of its subfoldrs and displayed decendents
 * as selected.
 *
 * Also it requires to change the tree in the direction to the root because use case like
 * for instance if a folder has subfoldrs displayed and all are nselected then if the parent
 * select one of the subfolders the parent should be marked as third state as well all
 * the progenitors down to the root. Same thing if only one subfolder is selected and then
 * it gets unselected it requires to set the parent as unselected and the progenitors if
 * the new status is that also all the children of each progenitors are unselected.
 *
 * This function deals with all this use cases and mutations on the tree
 *
 * @param tree [TreNodeDto<T>] tree to select the nodes
 * @param node [TreeNodeDto<T>] node to be changed. this node should belongs to the tree and
 *                             it has to have set the selected or thirdState props to the next
 *                             state of selection
 *
 * @return TreeNode is the initial tree mutated in order to represent the new selection
 */
export const selectTreeNode = <T = any>(
  tree: TreeNodeDto<T>,
  node: TreeNodeDto<T>
) => {
  const selected = node.selected;

  // If the node is selected, all its children shall be selected
  // If the node is unselected, all its children shall be unselected
  if (!node.thirdState) {
    setSubtreeSelection(node, selected);
  }

  if (isTreeRoot(node)) {
    tree.thirdState = node.thirdState;
    tree.selected = node.selected;
    return tree;
  }

  let parentNode = getNodeFromPath(
    tree,
    node.path.slice(0, node.path.length - 1)
  );
  parentNode.children[node.nodeId].selected = node.selected;
  parentNode.children[node.nodeId].thirdState = node.thirdState;

  let onDescendentAtThirdState = node.thirdState;

  // Traverse the tree backward to set the parent to the correct state
  // High performance for loop
  let i = 1;
  const N = node.path.length;
  for (; i <= N; i++) {
    parentNode = getNodeFromPath(
      tree,
      node.path.slice(0, node.path.length - i)
    );

    if (parentNode) {
      // If at least one of the children is set as third state
      if (onDescendentAtThirdState) {
        parentNode.selected = false;
        parentNode.thirdState = true;
        continue;
      }

      const noChildrenSelected = parentNode.children.every(
        (ch) => !ch.selected
      );

      if (noChildrenSelected) {
        parentNode.selected = false;
        parentNode.thirdState = false;
      } else if (parentNode.children.length) {
        parentNode.selected = false;
        parentNode.thirdState = true;
        onDescendentAtThirdState = true;
      }
    }
  }

  return tree;
};

/**
 * Traverse the tree to look for the selected paths.
 *
 * @param tree [TreeNode] the tree to extract the selected paths
 * @param thresholdDepth [number] [optional] threshold depth from when we begin to select the nodes. Useful if you want
 *                          to do things like ignore the root node in terms of node selection
 *
 * @returns array[][] an array witht the path of all the selected
 *              nodes following the busniess logic
 *
 * @usage
 *
 * hovercraft - engines - eels o
 *                      - gears x
 *            - deposit o - fuel x
 *            - turbine x
 *            - cocpit  - seat x
 *                      - more eels o
 *
 * const selectedPaths = getSelectedPaths(tree)
 * selectedPaths = [
 *  [0, 0, 0],
 *  [0, 2],
 *  [0, 3, 1]
 * ]
 */
export const getTreeSelectedPaths = (tree: TreeNodeDto, thresholdDepth = 0) => {
  const selectedPaths: number[][] = [];
  let depth = 0;

  const dfsSearch = (node: TreeNodeDto) => {
    if (node.selected || node.thirdState) {
      if (!node.thirdState && node.selected && depth >= thresholdDepth) {
        selectedPaths.push(node.path);
        return;
      }

      depth += 1;
      node.children.forEach(dfsSearch);
    }
  };

  dfsSearch(tree);

  return selectedPaths;
};

/**
 *
 * @param tree [TreeNode] the tree to extract the selected paths
 *
 * @returns array[][] an array witht the path of all the selected
 *              nodes following the busniess logic
 *
 * @usage
 *
 * hovercraft - engines - eels o
 *                      - gears x
 *            - deposit o - fuel x
 *            - turbine x
 *            - cocpit  - seat x
 *                      - more eels o
 *
 * const selectedPaths = getSelectedPaths(tree)
 * selectedPaths = [
 *  ['hovercraft', 'engines', 'eels'],
 *  ['hovercraft', 'turbine'],
 *  ['hovercraft', 'cocpit', 'more eels']
 * ]
 */
export const getTreeNodeSelectedContent = <T = any>(tree: TreeNodeDto<T>) => {
  // Threshold depto in order to remove the root node of the tree
  // as in terms of content according the API the root node does not count
  // and is just a place holder to select the whole tree
  return getTreeSelectedPaths(tree).map((path) =>
    getTreePathContent(tree, path)
  );
};

export const setTreeSelection = <T = any>(
  tree: TreeNodeDto<T>,
  selection: T[][]
) => {
  const selectionsFound: number[] = [];

  selection.forEach((contentPath, selectionId) => {
    const { node, depth } = getNodeFromContentPath(tree, contentPath);
    if (node) {
      if (depth === contentPath.length) {
        selectionsFound.push(selectionId);
      }
      node.selected = depth === contentPath.length;
      node.thirdState = depth !== contentPath.length;
      selectTreeNode(tree, node);
    }
  });

  return selectionsFound;
};

const arraysAreTheSame = (array1: any[], array2: any[]) => {
  return array1.every((item, id) => array2[id] === item);
};

/**
 * Check if a paritcular selection is redundant according the current
 * selection of a tree. It returns the intersections between
 * the tree selection and the provided selection and get rid
 * of the redundant ones
 *
 * @param tree [TreeNodeDto<T>] tree where to compare the selection
 * @param selection [T[][]] set of selections to filter. The selections
 *                      are based with content paths. see setTreeSelection
 *
 * @return T[][] filtered selection
 */
export const filterRedundantSelections = <T = any>(
  tree: TreeNodeDto<T>,
  selection?: T[][]
) => {
  // If there is no selection we return the tree selection
  // othrwise if selection where empty array
  // it will mean that the root node is selected
  if (!selection?.length) {
    return getTreeNodeSelectedContent(tree);
  }

  const currentSelections = getTreeNodeSelectedContent(tree);
  const combinedSelections = [...selection, ...currentSelections];

  const filteredSelections: T[][] = [];

  combinedSelections.forEach((contentPath, selectionId) => {
    const detectedPath: T[] = [];
    let nodeParent: TreeNodeDto<T> | undefined = tree;
    let redundant = false;

    if (tree.selected) {
      // If the tree node is selected then is not worth to select
      // and also is not possible as the path for the root is []
      redundant = true;
    } else {
      redundant = contentPath.some((content) => {
        detectedPath.push(content);
        if (!nodeParent) {
          return false;
        }
        nodeParent = nodeParent?.children.find(
          (node: TreeNodeDto<T>) => node.content === content
        );
        if (nodeParent?.selected) {
          return true;
        }
        return false;
      });
    }

    if (redundant) {
      if (
        !filteredSelections.some((array1) =>
          arraysAreTheSame(array1, detectedPath)
        )
      ) {
        filteredSelections.push(detectedPath);
      }
    } else {
      filteredSelections.push(contentPath);
    }
  });

  return filteredSelections;
};
