export const getFileBuffer = (file: File): Promise<Int8Array> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (reader.result) {
        resolve(new Int8Array(reader.result as ArrayBuffer));
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

export type CryptoAlgorithm = AlgorithmIdentifier;
export interface RKVSTHashes {
  sha256?: string;
  dropbox?: string;
}

export interface RKVSTWatermarks {
  digimarc?: string | null;
}

export const hashToHex = (hash: ArrayBufferLike) => {
  const hashArray = Array.from(new Uint8Array(hash));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

export const hashFile = async (
  algorithm: AlgorithmIdentifier,
  file: File
): Promise<RKVSTHashes> => {
  const content = await getFileBuffer(file);

  if (!crypto?.subtle) {
    throw new Error(
      "Your browser does not allows to use crypto. Please try to use this page with a browser which does support subte crypto (https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto#browser_compatibility) and only over https"
    );
  }

  const hashBuffer = await crypto.subtle?.digest("SHA-256", content);
  const dboxHash = await hashDropbox(content);

  return {
    sha256: hashToHex(hashBuffer),
    dropbox: hashToHex(dboxHash),
  };
};

const BLOCK_SIZE = 4 * 1024 * 1024;

export const hashDropbox = async (data: Int8Array) => {
  const combinedHashes: Uint8Array[] = [];

  let offset = 0;
  let totalLen = 0;
  while (offset < data.length) {
    const end = Math.min(offset + BLOCK_SIZE, data.length);
    const h = await crypto.subtle.digest("SHA-256", data.slice(offset, end));
    const hArray = new Uint8Array(h);
    totalLen += hArray.length;
    combinedHashes.push(hArray);
    offset = end;
  }

  let ff = 0;
  const allParts = new Uint8Array(totalLen);
  combinedHashes.forEach((bff) => {
    allParts.set(bff, ff);
    ff += bff.length;
  });

  return crypto.subtle.digest("SHA-256", allParts.buffer);
};

const KB = 1e3;
const MB = 1e6;
const GB = 1e9;

/**
 * Format a number representing a size in bytes
 * in a format which is more human reable.
 *
 * e.g. 12345678 ->  12.34 Mb
 *
 * @patam size number size in bytes which one to be format
 *
 * @return string
 */
export const sizeString = (size: number) => {
  if (size < KB) {
    return size + " Bytes";
  } else if (size < MB) {
    return (size / KB).toFixed(2) + " kB";
  } else if (size < GB) {
    return (size / MB).toFixed(2) + " MB";
  } else {
    return (size / GB).toFixed(2) + " GB";
  }
};
