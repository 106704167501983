// this is temporal meanwhile both themes format coexists
// _v1 is in order to later be able to easly do a search
// and replace it once we can get rid of the old theme
import _v1 from './RkvstTheme'
import './importedFonts.css'

// FIXME: These colours mix the old and new design system. They remain here to 
// allow a smooth transition to the new design system but eventually any style that 
// uses these colours should move to use ones from the RkvstTheme module instead
export const brandColors = {
  black: '#000000',
  red: '#E74820',
  brandRed: '#ED241C',
  darkRed: '#7a0f0b',
  grey: '##344054',
  lightGrey: '#A7A7A7',
  underlineGrey: '#a39898',
  highlightGrey: '#dddddd',
  inputBorder: '#707070',
  body: '#667085',
  blue: '#0BA5EC',
  highlightBlue: '#E0F2FE',
  highlightLightBlue: '#EFF8FE',
  paleBlue: '#c7edfc',
  darkBlue: '#006BA3',
  mainBackground: '#ffffff',
  darkestGrey: '#303030',
  darkerGrey: '#101828',
  middleGrey: '#667085',
  bodyBackground: '#F7F7F9',
  strongBlue: 'rgb(224, 242, 254)',
}

const layout = {
  navWidth: 260,
  headerHeight: 64,
  fixedNavHeaderBreakpoint: 1025,
  grid: {
    min: {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    max: {
      sm: 576 - 1,
      md: 768 - 1,
      lg: 992 - 1,
      xl: 1200 - 1,
    },
  },
}

export const mainTheme = {
  _v1,
  colors: {
    default: brandColors.black,
    brand: brandColors,
    error: brandColors.red,
    emphasis: brandColors.black,
    high_emphasis: brandColors.red,
    link: brandColors.blue,
    visited_link: brandColors.darkBlue,
    underline: brandColors.blue,
    highlight: brandColors.highlightBlue,
    soft_highlight: brandColors.lightGrey,
    neutral_color: brandColors.inputBorder,
    brandBlue: brandColors.blue,
    dropBlue: brandColors.strongBlue,
    paleBrandBlue: brandColors.paleBlue,
    general: brandColors,
    mainBackground: brandColors.mainBackground,
    heading: brandColors.darkBlue,
    subheading: brandColors.darkerGrey,
    text: brandColors.middleGrey,
  },
  font: {
    bigger: '28pt',
    big: '16pt',
    normalSize: '12pt',
    bold: '600',
  },
  size: {
    small: '4px',
    normal: '8px',
    bigger: '32px',
  },
  typography: {
    heading: {
      fontFamily: "'Montserrat', Geometric, sans-serif",
      fontSize: '2rem',
      lineHeight: '2rem',
      color: brandColors.darkestGrey,
    },
    subheading: {
      fontFamily: "'Inter', Helvetica, sans-serif",
      fontSize: '1.125rem',
      lineHeight: '1.125rem',
      color: brandColors.darkerGrey,
    },
    text: {
      fontFamily: "'Inter', Helvetica, sans-serif",
      fontSize: '1rem',
      lineHeight: '1.25rem',
      color: brandColors.middleGrey,
    },
    helpText: {
      fontFamily: "'Inter', Helvetica, sans-serif",
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
      letterSpacing: '0.02em',
      color: brandColors.middleGrey,
    },
    code: {
      fontFamily: "'Roboto Mono', 'Courier New', monospace",
      fontSize: '1rem',
      lineHeight: '1.25rem',
      color: brandColors.darkerGrey,
    },
  },
  layout,
}

export default mainTheme
