import { LoadingCard, SectionCard } from '@ui/components'
import { pickGap } from '@ui/themes'
import React from 'react'
import styled from 'styled-components'

const instaproofBreakpoint1 = 1150
const instaproofBreakpoint2 = 1480

import { theme } from '../../Css'

interface LayoutProps {
  steps?: React.ReactNode
  dropZone?: React.ReactNode
  samples?: React.ReactNode
  className?: string
}
export const Layout = (props: LayoutProps) => {
  return (
    <InstaproofMainContainer className={props.className}>
      <section className="marketing-panel">
        <SectionCard className="steps-container">{props.steps}</SectionCard>
        <SectionCard className="drop-zone-container">{props.dropZone}</SectionCard>
      </section>
      <SectionCard className="sample-panel">{props.samples}</SectionCard>
    </InstaproofMainContainer>
  )
}

const PlaceHolderLayout = styled(Layout)`
  .steps-container.steps-container {
    padding: 0px;
    border: none;
    section {
      width: 100%;
      min-height: 250px;
      height: 100%;
    }
  }
  .drop-zone-container.drop-zone-container {
    border: none;
    padding: 0px;
    section {
      min-height: 250px;
      height: 100%;
    }
  }
  .sample-panel.sample-panel {
    border: none;
    padding: 0px;
    section {
      min-height: 200px;
      height: 100%;
    }
  }
`

export const DocumentSearchSuspense = () => {
  return (
    <PlaceHolderLayout
      steps={<LoadingCard height="100%" />}
      dropZone={<LoadingCard height="100%" />}
      samples={<LoadingCard height="100%" />}
    />
  )
}

export const SectionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  width: max-content;
`

export const InstaproofMainContainer = styled.section`
  width: 100%;
  max-width: 2560px;
  padding: ${pickGap(theme?._v1, 'size4').px};
  background: white;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${pickGap(theme?._v1, 'size4').px};

  .drop-zone-container {
    width: 100%;
    padding: ${pickGap(theme?._v1, 'size8').px};
    background: white;

    border-radius: 4px;
  }

  .marketing-panel {
    width: 100%;
    height: 40%;
    background: white;

    display: flex;
    flex-direction: column;
    gap: ${pickGap(theme?._v1, 'size2').px};

    @media (min-width: ${instaproofBreakpoint1}px) {
      flex-direction: row;
      height: 70%;
    }
  }

  .sample-panel {
    width: 100%;
    padding: ${pickGap(theme?._v1, 'size4').px};
    background: white;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    gap: ${pickGap(theme?._v1, 'size2').px};

    @media (min-width: ${instaproofBreakpoint1}px) {
      padding: ${pickGap(theme?._v1, 'size4').px};
    }
  }

  .steps-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px;

    @media (min-width: ${instaproofBreakpoint2}px) {
      justify-content: left;
      align-items: flex-start;
      padding: ${pickGap(theme?._v1, 'size4').px};
      width: 40%;
    }
  }

  @media (min-width: ${instaproofBreakpoint2}px) {
    height: 95vh;
    max-height: 1440px;
    justify-content: flex-start;
  }
`

export const ResultsContainer = styled.section`
  width: 100%;
  padding: ${pickGap(theme?._v1, 'size8').px};
  background: white;

  display: flex;
  flex-direction: column;
  gap: ${pickGap(theme?._v1, 'size8').px};
`

export const DropzoneText = styled.span`
  .sub-header {
    display: none;
    @media (min-width: ${instaproofBreakpoint1}px) {
      display: block;
    }
  }
`

export const ResultsTableSectionGroup = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${pickGap(theme?._v1, 'size16').px};
`

export const ResultsTableSection = styled.section`
  table {
    margin-top: 32px;
  }
`

export const MarketingStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${pickGap(theme?._v1, 'size4').px};
  gap: ${pickGap(theme?._v1, 'size4').px};

  height: 40%;

  @media (min-width: ${instaproofBreakpoint1}px) {
    height: 100%;
  }
`

export const MarketingStepContainer = styled.div`
  display: flex;
  margin: 0px;
  gap: ${pickGap(theme?._v1, 'size2').px};
  align-items: center;

  flex-direction: row;
  justify-content: left;
  @media (min-width: ${instaproofBreakpoint1}px) {
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
    margin: ${pickGap(theme?._v1, 'size2').px};

    .content-header {
      text-align: left;
      @media (min-width: ${instaproofBreakpoint1}px) {
        text-align: left;
      }
    }

    .content-detail {
      @media (min-width: ${instaproofBreakpoint1}px) {
      }
    }
  }

  .image-content {
    width: 64px;
    @media (min-width: ${instaproofBreakpoint1}px) {
      width: 64px;
    }
  }
`

export const HeroGraphic = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;

  padding-left: 0px;
  padding-bottom: 0px;
  @media (min-width: ${instaproofBreakpoint2}px) {
    padding-left: ${pickGap(theme?._v1, 'size2').px};
    padding-bottom: ${pickGap(theme?._v1, 'size2').px};

    .image {
      height: 64px;
    }
  }
`

export const MarketingSamplesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: ${pickGap(theme?._v1, 'size4').px};
  padding-bottom: ${pickGap(theme?._v1, 'size4').px};
  gap: ${pickGap(theme?._v1, 'size4').px};

  @media (min-width: ${instaproofBreakpoint1}px) {
    flex-direction: row;
  }
`

export const MarketingSampleContainer = styled(SectionCard)`
  display: inline-flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 0px;
  align-items: center;

  @media (min-width: ${instaproofBreakpoint1}px) {
    width: 33%;
  }

  .image-content {
    width: 92px;
    min-width: 92px;
    overflow-x: clip;

    .image {
      margin: -128px -128px;
      object-fit: cover;
    }

    @media (min-width: ${instaproofBreakpoint1}px) {
      overflow-x: clip;

      .image {
        object-fit: cover;
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    gap: ${pickGap(theme?._v1, 'size2').px};
    width: 100%;
    margin: 0px;
    padding: ${pickGap(theme?._v1, 'size4').px};

    .full-width {
      width: 100%;
    }
  }
`

export const MarketingPanel = styled.section`
  width: 100%;
  height: 25%;
  padding: ${pickGap(theme?._v1, 'size2').px};
  background: white;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  gap: ${pickGap(theme?._v1, 'size2').px};

  @media (min-width: ${instaproofBreakpoint1}px) {
    width: 100%;
    flex-direction: row;
  }
`

export const ResultsListContainer = styled(SectionCard)`
  padding: ${pickGap(theme?._v1, 'size4').px};

  .verified-badge {
    width: fit-content;
    padding-top: ${pickGap(theme?._v1, 'size4').px};
  }
`

export const ResultsDropzoneContainer = styled(SectionCard)`
  min-height: 264px;
  max-height: 264px;
  padding: ${pickGap(theme?._v1, 'size4').px};
`
