/* Tool abstraction wrapper for all user statistics gathering
 *
 * Deliberately swallows exceptions as much as possible because ad blockers
 * and such introduce lots of unpredictable ways in which these functions can
 * fail, and ultimately we don't _need_ these to work, but we do need them to
 * not screw up the main app. */
import { v4 as uuid } from 'uuid'

import { getFeatureFlag } from './getFeatureFlag'

const getSegmentUserID = (whoAmI: any) => {
  if (!whoAmI?.tenantid || !whoAmI?.principal?.issuer || !whoAmI?.principal?.subject) {
    // segment requires an explicit 'anonymous ID' if you can't actually identify
    // the user. Using a UID here allows us to merge anonymous activity with user
    // activity if they sign up/sign in later in this session
    return uuid()
  }

  return `${whoAmI.tenantid}_${whoAmI.principal.issuer}::${whoAmI.principal.subject}`
}

export const initialise = () => {
  // Nothing needs initialising explicitly - they are
  // taken care of by tags in the SPA <HEAD>
}

export const identifyUser = (tenantid: string, userid: string, email: string, display_name: string, role: string) => {
  if (typeof (window as any).analytics?.identify != 'function') return

  try {
    ;(window as any).analytics.identify(`${tenantid}_${userid}`, {
      tenantid: tenantid,
      userid: userid,
      email: email,
      name: display_name,
      role: role,
    })
  } catch (e) {
    // Deliberately swallow all errors.
    // Ad blockers and the like can cause this to fail and we don't want to
    // disrupt the primary functionality by throwing errors around
    return
  }
}

export const getUserAttribute = (attr: string) => {
  // There are no complex mappings: just get and set k,v pairs directly
  // TODO: not sure we can retrieve things direct from Segment
  return ''
}

export const setUserAttribute = (whoAmI: any, attr: string, value: any) => {
  // There are no complex mappings: just get and set k,v pairs directly

  if (typeof (window as any).analytics?.identify != 'function') return

  try {
    ;(window as any).analytics.identify(getSegmentUserID(whoAmI), {
      [attr]: value,
    })
  } catch (e) {
    // Deliberately swallow all errors.
    // Ad blockers and the like can cause this to fail and we don't want to
    // disrupt the primary functionality by throwing errors around
    return
  }
}

export const trackEvent = (ev: string, evProps: object | null) => {
  if (typeof (window as any).analytics?.track != 'function') return

  try {
    ;(window as any).analytics.track(ev, evProps)
  } catch (e) {
    // Deliberately swallow all errors.
    // Ad blockers and the like can cause this to fail and we don't want to
    // disrupt the primary functionality by throwing errors around
    return
  }
}
