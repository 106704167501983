import { lazily } from 'react-lazily'

import { wrapVisibleSuspense } from '../../atoms/placeholders/Suspense'

const { Badge: RawBadge } = lazily(() => import('./Badge'))
const Badge = wrapVisibleSuspense<typeof RawBadge>(RawBadge)
export { Badge }

export { default as ErrorLegacyBadge } from './ErrorLegacyBadge'
export { default as LegacyBadge } from './LegacyBadge'
export { default as SuccessLegacyBadge } from './SuccessLegacyBadge'
export { default as VerifiedLegacyBadge } from './VerifiedLegacyBadge'
