import { RkvstResourceCaps } from '@ui/types'

import api from '../core'

const group = null
const resource = 'blobs'
const version = '1'
const capsUrl = api.parseCapsUrl(resource)
const resourceUrl = api.parseResourceUrl(group, resource, version)

export default {
  getCaps(): Promise<RkvstResourceCaps> {
    return api.get(`${capsUrl}`)
  },

  upload(data: any, config: any) {
    return api.post(`${resourceUrl}`, data, config)
  },
}
