import { RkvstCreateAppRegistration, RkvstResourceCaps } from '@ui/types'

import { RkvstAppRegistration } from '../../state/initialState'
import api from '../core'
import { FilterQuery } from '../FilterQuery'

const displayNameFilterKey = 'display_name'
const group = null
const resource = 'applications'
const version = '1'
const capsUrl = api.parseCapsUrl(resource)
const resourceUrl = api.parseResourceUrl(group, resource, version)

// AppRegistrationsFilterParams acceptable filter
//  params for list app registrations
export type AppRegistrationsFilterParams = {
  displayName?: string
}

export default {
  getCaps(): Promise<RkvstResourceCaps> {
    return api.get(`${capsUrl}`)
  },

  getList(): Promise<any> {
    return api.get(`/iam${resourceUrl}`)
  },

  getListFiltered(filter: AppRegistrationsFilterParams = {}, nextPageToken?: string): Promise<any> {
    const filterQuery = new FilterQuery({
      page_size: '50',
    })

    if (filter.displayName) {
      filterQuery.put(displayNameFilterKey, filter.displayName)
    }

    if (nextPageToken) {
      filterQuery.put('page_token', nextPageToken)
    }

    const query = filterQuery.build()

    return api.get(`/iam${resourceUrl + query}`)
  },

  getOne(id: string): Promise<RkvstAppRegistration> {
    const strippedId = api.stripResourceFromId(resource, id)
    return api.get(`/iam${resourceUrl}/${strippedId}`)
  },

  create(appReg: RkvstCreateAppRegistration): Promise<RkvstAppRegistration> {
    return api.post(`/iam${resourceUrl}`, appReg)
  },

  update(id: string, appReg: RkvstAppRegistration): Promise<RkvstAppRegistration> {
    const strippedId = api.stripResourceFromId(resource, id)
    return api.patch(`/iam${resourceUrl}/${strippedId}`, appReg)
  },

  delete(id: string): Promise<any> {
    const strippedId = api.stripResourceFromId(resource, id)
    return api.delete(`/iam${resourceUrl}/${strippedId}`)
  },

  regenerateSecret(clientId: string): Promise<RkvstAppRegistration> {
    const strippedId = api.stripResourceFromId(resource, clientId)
    return api.post(`/iam${resourceUrl}/${strippedId}:regenerate-secret`, {})
  },
}
