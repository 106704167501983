/**
 * Gives safeguards to the err message in a redux thunk call
 * @param err passing in an error message of type string/object
 */
const errAsMessage = (err: any): string => {
  if (err?.message) {
    return err.message.length ? err.message : 'An Error occurred, please try later.'
  } else if (err && typeof err !== 'string') {
    return JSON.stringify(err)
  } else if (err && typeof err === 'string') {
    return err.length ? err : 'An Error occurred, please try later.'
  } else {
    return 'An Error occurred, please try later.'
  }
}

export default errAsMessage
