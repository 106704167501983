import React from 'react'
import { createRoot } from 'react-dom/client'
// typings in lib are wrong, this is a valid import but TS complains about it
// @ts-ignore
import { I18nextProvider } from 'react-i18next'

import App from './App'
import i18n from './i18n'
import { createStore } from './state/store'

const initialiseApp = async () => {
  const reduxStore = await createStore()

  // Notifications 'currently' disabled server side.
  // notifications.listen(reduxStore)

  const container = document.getElementById('app')
  const root = createRoot(container as Element)

  root.render(
    <I18nextProvider i18n={i18n}>
      <App store={reduxStore} />
    </I18nextProvider>
  )
}

initialiseApp()
