import { RkvstCreateAccessPolicy } from '@ui/types'

import { RkvstAccessPolicy } from '../../state/initialState'
import api from '../core'
import { FilterQuery } from '../FilterQuery'
import { PolicyVersion } from './types'

// AccessPoliciesFilterParams acceptable filter
//  params for list access policies
export type AccessPoliciesFilterParams = {
  displayName?: string
}

export const wildcard: string[] = ['*']

export default class Policy {
  static group: string | null
  static resource: string
  static version: PolicyVersion
  private static resourceUrl: string

  static setPolicyState(
    group: string,
    resource: string,
    version: any, //PolicyVersion,
    debug?: boolean
  ): void {
    this.group = group
    this.resource = resource
    this.version = version
    this.resourceUrl = api.parseResourceUrl(this.group, this.resource, this.version)

    if (debug) {
      console.log('group: ', this.group)
      console.log('resource: ', this.resource)
      console.log('version: ', this.version)
      console.log('resourceUrl: ', this.resourceUrl)
    }
  }

  static list<T>(nextPageToken?: string): Promise<T> {
    const policySResUrl: string = this.resourceUrl

    const filterQuery = new FilterQuery({
      page_size: '50',
      order_by: 'DEFAULT',
    })

    if (nextPageToken) {
      filterQuery.put('page_token', nextPageToken)
    }

    const query = filterQuery.build()

    return api.get<T>(policySResUrl + query)
  }

  static getListFiltered<T>(filter: AccessPoliciesFilterParams = {}, nextPageToken?: string): Promise<any> {
    const policySResUrl: string = this.resourceUrl

    const filterQuery = new FilterQuery({
      page_size: '50',
      order_by: 'DEFAULT',
    })

    if (nextPageToken) {
      filterQuery.put('page_token', nextPageToken)
    }

    if (filter.displayName) {
      filterQuery.put('display_name', filter.displayName)
    }

    const query = filterQuery.build()

    return api.get<T>(policySResUrl + query)
  }

  static create<T>(policy: T | RkvstCreateAccessPolicy): Promise<unknown> {
    const policySResUrl: string = this.resourceUrl
    return api.post(policySResUrl, policy)
  }

  static update<T>(id: string, policy: T | Omit<RkvstAccessPolicy, 'identity'>): Promise<unknown> {
    const policySResUrl: string = this.resourceUrl
    const strippedId = api.stripResourceFromId(this.resource, id)
    return api.patch(`${policySResUrl}/${strippedId}`, policy)
  }

  static getOne<T>(id: string): Promise<T | any> {
    const policySResUrl: string = this.resourceUrl
    const strippedId = api.stripResourceFromId(this.resource, id)
    return api.get(`${policySResUrl}/${strippedId}`)
  }

  static delete<T>(id: string): Promise<T | any> {
    const policySResUrl: string = this.resourceUrl
    const strippedId = api.stripResourceFromId(this.resource, id)
    return api.delete(`${policySResUrl}/${strippedId}`)
  }

  //NOTE: only works for Compliance Policy.
  //How to protect against use with Access Policies?
  static test<T>(assetid: string): Promise<T | any> {
    const policySResUrl: string = this.resourceUrl
    return api.get(`${policySResUrl}/${assetid}`)
  }
}
