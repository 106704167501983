import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle(
  ({ theme }) => `
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 16px;
  }

  body,
  a,
  button {
    font-family: ${theme._v1.typography.button.family};
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  ul {
    list-style: none;
  }

  small {
    font-size: 1.4rem;
  }

  a,
  button {
    cursor: pointer;
  }

  button {
    border: none;
    background: transparent;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #dceeb9;
    border-radius:  ${theme._v1.radius.big};
  }

  ::-webkit-scrollbar-thumb {
    background-color: #88cc14;
    border-radius: ${theme._v1.radius.big};
  }
`
)

export default GlobalStyles
