import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '../../../svgs'
import { FakeScrollContainer } from './FakeScroll.styles'

export interface FakeScrollProps extends RkvstBaseProps {
  children?: React.ReactNode
}

class Ticker {
  public tic = 0
  public start(cb: () => void, delay = 100) {
    this.stop()
    this.tic = window.setTimeout(() => {
      if (this.tic) {
        cb()
      }
    }, delay)
    return this
  }
  public stop() {
    if (this.tic) {
      window.clearTimeout(this.tic)
    }
    this.tic = 0
  }
}

export const FakeScroll = (props: FakeScrollProps) => {
  const [offsetIncrement, setOffsetIncrement] = useState(5)
  const [ticOffset, setTickOffset] = useState(0)
  const list = useRef<HTMLDivElement>(null)
  const wrapper = useRef<HTMLParagraphElement>(null)
  const [offset, setOffset] = useState(0)
  const styles: React.CSSProperties = { marginLeft: offset + 'px' }

  const tic = useMemo(() => new Ticker(), [])
  const boundryCrop = useCallback(
    ({ left, right }: { left?: number; right?: number }) => {
      if (list.current && wrapper.current) {
        const listWidth = list.current!.getBoundingClientRect().right - list.current!.getBoundingClientRect().left
        const wrapperWidth =
          wrapper.current!.getBoundingClientRect().right - wrapper.current!.getBoundingClientRect().left

        if (listWidth <= wrapperWidth) {
          return { left: 0, right: 0, listWidth, wrapperWidth, debug: true }
        }

        return {
          left: Math.max(wrapperWidth - 75 - listWidth, left || 0),
          right: Math.min(0, right || 0),
          listWidth,
          wrapperWidth,
        }
      }
      return { left: null as any, right: null as any }
    },
    [wrapper.current, list.current]
  )

  useLayoutEffect(() => {
    if (ticOffset !== 0 && boundryCrop({}).left !== null) {
      tic.start(() => {
        const { left, right } = boundryCrop({ left: offset + ticOffset, right: offset + ticOffset })
        if (left !== null) {
          if (ticOffset > 0) {
            setOffset(right)
          } else {
            setOffset(left)
          }
        }
      })
    } else {
      tic.stop()
    }
    return () => tic.stop()
  }, [ticOffset, offset, setOffset, tic, boundryCrop])

  return (
    <FakeScrollContainer className={props.className} ref={wrapper}>
      <div className="navigate-wrapper navigate-left">
        <ChevronLeftIcon
          className="navigate"
          onWheel={(ev) => {
            ev.stopPropagation()
            ev.preventDefault()
          }}
          onMouseOver={() => setTickOffset(offsetIncrement)}
          onMouseOut={() => {
            setTickOffset(0)
            setOffsetIncrement(5)
          }}
          onMouseDown={() => {
            setOffsetIncrement(30)
            setTickOffset(30)
          }}
          onMouseUp={() => {
            setOffsetIncrement(5)
            setTickOffset(5)
          }}
        />
      </div>
      <div
        className="list"
        style={styles}
        onWheel={(ev) => {
          ev.stopPropagation()
          ev.preventDefault()
          const deltaLeft = Math.abs(Math.max(-ev.deltaX, ev.deltaY))
          const deltaRight = Math.abs(Math.max(ev.deltaX, -ev.deltaY))
          const { left, right, listWidth, wrapperWidth, debug } = boundryCrop({
            left: offset - deltaLeft,
            right: offset + deltaRight,
          })
          if (deltaLeft === 0 && deltaRight === 0) {
            setTickOffset(0)
            setOffsetIncrement(5)
          }
          if (left !== null) {
            if (deltaLeft > deltaRight) {
              setOffset(left)
            } else if (deltaLeft < deltaRight) {
              setOffset(right)
            }
          }
        }}
      >
        <div ref={list}>{props.children}</div>
      </div>
      <div className="navigate-wrapper navigate-right">
        <ChevronRightIcon
          className="navigate"
          onWheel={(ev) => {
            ev.stopPropagation()
            ev.preventDefault()
          }}
          onMouseOver={() => setTickOffset(-offsetIncrement)}
          onMouseOut={() => {
            setTickOffset(0)
            setOffsetIncrement(5)
          }}
          onMouseDown={() => {
            setOffsetIncrement(30)
            setTickOffset(-30)
          }}
          onMouseUp={() => {
            setOffsetIncrement(5)
            setTickOffset(-5)
          }}
        />
      </div>
    </FakeScrollContainer>
  )
}
