let JSON_FLAGS = {}

export const initGlobalFlags = async () => {
  const data = await fetch('/flags/features.json')
  JSON_FLAGS = await data.json()
  ;(window as any).printFeatureFlag = () => {
    console.log(JSON_FLAGS)
  }
}

export const getFeatureFlag = (flag: string): boolean => {
  const flags = JSON.parse(localStorage.getItem('rkvst-flags') || '{}')
  let foundIt = !!(flags[flag] || flags[flag] === 'true' || flags[flag] === '1')

  if (!foundIt) {
    foundIt = JSON_FLAGS[flag]
  }

  return foundIt
}
