import LoadingGif from '@app/assets/Spinner-1s-351px.svg'
import { lazily } from 'react-lazily'
const { Trans } = lazily(() => import('react-i18next'))

import React from 'react'
import { RouteProps } from 'react-router'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'

import { useTenanciesDataContext } from './context/TenanciesDataContext'
import paths from './paths'
import { RkvstUserRole } from './state/initialState'
import { SectionPlaceholder } from './suspense'

export type WithRoleBasedAccessProps = {
  roles?: Array<RkvstUserRole>
  userRole?: RkvstUserRole
  redirect?: string
  publicRoute?: boolean
  loggedIn?: boolean
  authErr?: any
}

export enum ROLES {
  administrator = 'archivist_administrator',
  user = 'archivist_user',
}

const VerifyingRoles = styled.p`
  text-align: center;
  img {
    margin-top: 40px;
    width: 150px;
  }
`

// const withRoleBasedAccessState = (state: ReduxState) => ({
//   authErr: state.whoami.err,
//   userRoles: state.whoami.roles,
// })

export const isAccessAllowed = (userRoles: Array<RkvstUserRole>, allowedRoles: Array<RkvstUserRole>) =>
  allowedRoles && allowedRoles.find((role) => userRoles.indexOf(role) !== -1)

export const isCurrentBrowserRoutePublic = (): boolean => {
  return (
    location.pathname.startsWith('/archivist/publicassets') ||
    location.pathname.startsWith('/publictransaction') ||
    (location.pathname.startsWith('/merklelogentry') && location.search.includes('public'))
  )
}

interface WithRoleBasedAccessPresentationProps {
  children?: React.ReactNode
}

const WithRoleBasedAccessPresentation: React.FC<WithRoleBasedAccessProps & WithRoleBasedAccessPresentationProps> = (
  props: any
) => {
  const isAccessAllowed = props.publicRoute || (props.loggedIn && props.roles?.includes(props.userRole))

  // if there was an auth error getting the whomai data, deal with it globally here
  // by redirecting to the appropriate place so user can authenticate
  if (!isAccessAllowed) {
    switch (props.authErr) {
      case undefined:
        return <Navigate to={paths.assets} />
      case 403:
        return <Navigate to={paths.unauthorised} />
      case 401:
      default:
        return <Navigate to={paths.loginExpired + '?redirect=' + encodeURIComponent(window.location.pathname)} />
    }
  }

  // now that we're sure we have the whoami data we can check that
  const isRoleAllowed = props.publicRoute || props.roles?.includes(props.userRole)
  return isRoleAllowed ? <>{React.cloneElement(props.children)}</> : <Navigate to={props.redirect} />
}

const withRoleBasedAccess =
  (WrappedComponent: React.ComponentType<RouteProps>) => (props: RouteProps & WithRoleBasedAccessProps) => {
    const { redirect, ...passThrough } = props
    const { whoAmI, isLoadingTenancyData, resolved } = useTenanciesDataContext()

    return (
      <>
        {isLoadingTenancyData && (
          <SectionPlaceholder>
            <VerifyingRoles>
              <Trans i18nKey="Checking user access for this content...">Checking user access for this content...</Trans>
              <br />
              <img src={LoadingGif} />
            </VerifyingRoles>
          </SectionPlaceholder>
        )}
        {!isLoadingTenancyData && resolved && (
          <WithRoleBasedAccessPresentation
            loggedIn={!!whoAmI?.tenantid}
            roles={props.roles}
            redirect={props.redirect || paths.assets}
            userRole={whoAmI?.roles ? whoAmI?.roles[0] : undefined}
            publicRoute={props.publicRoute}
            authErr={whoAmI?.err}
          >
            <WrappedComponent {...passThrough} />
          </WithRoleBasedAccessPresentation>
        )}
      </>
    )
  }

export default withRoleBasedAccess
