import { RkvstSubject } from '@ui/types'
import { AxiosRequestConfig } from 'axios'

import api from '../core'
import { FilterQuery } from '../FilterQuery'

const group = 'iam'
const resource = 'subjects'
const version = '1' // note missing V as parseResourceUrl appends the v...
const resourceUrl = api.parseResourceUrl(group, resource, version)

export interface RkvstSubjectsResponse {
  subjects: RkvstSubject[]
  next_page_token: string
}

export default {
  getList(nextPageToken?: string, config?: AxiosRequestConfig) {
    const filterQuery = new FilterQuery({
      page_size: '50',
      order_by: 'DEFAULT',
    })

    if (nextPageToken) {
      filterQuery.put('page_token', nextPageToken)
    }

    const query = filterQuery.build()

    return api.get<RkvstSubjectsResponse>(resourceUrl + query, undefined, config)
  },

  getOne(id: string, config?: AxiosRequestConfig) {
    const strippedId = api.stripResourceFromId(resource, id)

    return api.get(`${resourceUrl}/${strippedId}`, undefined, config)
  },

  create(data: any) {
    return api.post(resourceUrl, data)
  },

  delete(id: string) {
    const strippedId = api.stripResourceFromId(resource, id)

    return api.delete(`${resourceUrl}/${strippedId}`)
  },
}
