import styled from 'styled-components'

import { theme } from '../../../Css'

export const Container = styled.div`
  display: flex;
  align-items: center;

  .filename {
    padding-left: 25px;
    font-size: 10pt;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;
  }

  input[type='file'] {
    display: none;
  }

  .drop-attachment {
    margin-right: 10px;
  }

  .upload-button.upload-button {
    padding: 0px;
    margin: 0px;
    width: 50px;
    height: 50px;
    border: none;
  }
`
