export type ScannedInfo = {
  scannedStatus: string
  scannedBadReason: string
  scannedTimeStamp: string
}

export enum ScannedStatus {
  'NOT_SCANNED' = 'NOT_SCANNED',
  'SCANNED_OK' = 'SCANNED_OK',
  'SCANNED_BAD' = 'SCANNED_BAD',
}
